import React, { useState } from 'react';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import {
  ButtonSize,
  ButtonStyle,
  InputTypes,
  CounterTypes,
  IconsTypes,
  SendIndexTableProps,
  MessageStyle,
  ErorFlag,
} from '../../utils/props';
import Icon from '../../components/Icon/Icon';
import tailwindClassNames from '../../utils/tailwindClassNames';
import Message from '../../components/Message/Message';
import { useTranslation } from 'react-i18next';

const SendIndexTableWeb: React.FC<SendIndexTableProps> = ({
  countersData,
  editCounterLocation,
  counterLocation,
  sendIndex,
  loading,
  saveCounterLocation,
  setEditCounterLocation,
  setCounterLocation,
  loadingLocation,
  invoice_period,
  updateIndexValue,
  validationError,
  errorIndex,
}) => {
  const { t } = useTranslation('ns');
  const [currentIndexInEdit, setCurrentIndexInEdit] = useState<number | undefined>();

  const webTableRow = 'even:bg-gray-50';
  const webTableRowcell = 'whitespace-nowrap px-3 py-4 text-sm text-black-500';
  const webTableHeaderCell = 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900 whitespace-nowrap';
  return (
    <div className="hidden xl:block w-full">
      <table className="w-full divide-y divide-gray-300 overflow-hidden table-fixed">
        <thead>
          <tr>
            <th scope="col" className={tailwindClassNames(webTableHeaderCell, 'w-[14%]')}>
              {t('consumer-series')}
            </th>
            <th scope="col" className={tailwindClassNames(webTableHeaderCell, 'w-[20%]')}>
              {t('consumer-location')}
            </th>
            <th scope="col" className={tailwindClassNames(webTableHeaderCell, 'w-[14%]')}>
              {t('index-old')}
            </th>
            <th scope="col" className={tailwindClassNames(webTableHeaderCell, 'w-[22%]')}>
              {t('index-new')}
            </th>
            <th scope="col" className={tailwindClassNames(webTableHeaderCell, 'w-[16%]')}>
              {t('consumer-total')}
            </th>
            <th scope="col" className={tailwindClassNames(webTableHeaderCell, 'w-[14%]')}>
              {t('last-update')}
            </th>
          </tr>
        </thead>
        <tbody>
          {countersData.map((counter: CounterTypes, index: number) => (
            <tr className={webTableRow} key={counter.counter_id}>
              <td className={webTableRowcell}>{counter.serial_number}</td>
              <td className={tailwindClassNames(webTableRowcell, 'select-none')}>
                <div className="flex w-full max-w-full">
                  {editCounterLocation !== index && (
                    <div>
                      {editCounterLocation !== index && (
                        <Icon
                          icon={IconsTypes.Edit}
                          size={16}
                          className="fill-blue-900 w-4 h-4 mr-2.5 mt-0.5 cursor-pointer"
                          onClick={() => {
                            setEditCounterLocation(index);
                            setCounterLocation(counter.label);
                          }}
                        />
                      )}
                    </div>
                  )}
                  {editCounterLocation !== index && (
                    <div className="max-w-full group flex relative">
                      <span
                        className="group-hover:opacity-100
                      transition-opacity bg-gray-100 px-1
                      text-sm text-black-100 rounded-md absolute
                      left-1/2 -translate-x-1/2 -translate-y-full
                      opacity-0 m-4 my-auto"
                      >
                        {counter.label}
                      </span>
                      <div className="truncate pr-6">{counter.label}</div>
                    </div>
                  )}
                  {editCounterLocation === index && (
                    <div className="flex content-center gap-3 relative">
                      <Input
                        type={InputTypes.Text}
                        onChange={(e) => {
                          setCounterLocation(e.target.value);
                        }}
                        wrapperClass="flex content-center mt-0"
                        className="lactionIputsize"
                        value={counterLocation || ''}
                      />
                      <Button
                        size={ButtonSize.Default}
                        variant={ButtonStyle.SafePrimary}
                        loading={loadingLocation}
                        onClick={() => {
                          saveCounterLocation(counter.id);
                        }}
                        className="py-[10px]"
                      >
                        <Icon icon={IconsTypes.Check} size={15} className="fill-white w-4 h-4 m-0.25 cursor-pointer" />
                      </Button>
                    </div>
                  )}
                </div>
              </td>
              <td className={webTableRowcell}>{counter.old_index} mc</td>
              <td className={webTableRowcell}>
                <div className="w-full flex content-center gap-3">
                  <Input
                    type={InputTypes.Number}
                    onChange={(e) => {
                      updateIndexValue(counter.id, Number(e.target.value));
                    }}
                    value={counter.new_index || ''}
                    unit="mc"
                    disabled={currentIndexInEdit !== counter.id || !invoice_period || invoice_period === 0}
                    hasError={errorIndex === counter.id ? ErorFlag.DontDisaplyErrorText : ''}
                    className="lactionIputsize"
                    wrapperClass="flex content-center mt-0"
                    unitPosition="top-[9px]"
                  />
                  <Button
                    size={ButtonSize.Default}
                    variant={currentIndexInEdit !== counter.id ? ButtonStyle.Secondary : ButtonStyle.SafePrimary}
                    loading={loading}
                    onClick={() => {
                      if (currentIndexInEdit === counter.id) {
                        sendIndex(counter.id);
                      } else {
                        setCurrentIndexInEdit(counter.id);
                      }
                    }}
                    disabled={!invoice_period || invoice_period === 0}
                    className="py-[10px] disabled:fill-gray-300 disabled:bg-gray-200 "
                  >
                    {currentIndexInEdit === counter.id && invoice_period !== 0 && (
                      <Icon icon={IconsTypes.Check} size={15} className="fill-white w-4 h-4 m-0.25 cursor-pointer" />
                    )}
                    {currentIndexInEdit !== counter.id && invoice_period !== 0 && (
                      <Icon icon={IconsTypes.Edit} size={15} className="fill-blue-900 w-4 h-4 m-0.25 cursor-pointer" />
                    )}
                    {currentIndexInEdit !== counter.id && !invoice_period && (
                      <Icon icon={IconsTypes.Edit} size={15} className="fill-gray-600 w-4 h-4 m-0.25 cursor-pointer" />
                    )}
                  </Button>
                </div>
              </td>
              <td className={webTableRowcell}>
                {counter.new_index
                  ? Math.round((Number(counter.new_index) - Number(counter.old_index)) * 100) / 100
                  : 0}{' '}
                {t('cubic-meters')}
              </td>
              <td className={webTableRowcell}>{counter.new_date ? counter.new_date.split(' ')[0] : ''}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {validationError && <Message type={MessageStyle.Error}>{validationError}</Message>}
    </div>
  );
};

export default SendIndexTableWeb;
