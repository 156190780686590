import React from 'react';
import Checkbox from '../../components/Checkbox/Checkbox';
import { InvoiceTableProps, IconsTypes } from '../../utils/props';
import tailwindClassNames from '../../utils/tailwindClassNames';
import Icon from '../../components/Icon/Icon';
import { useTranslation } from 'react-i18next';

const InvoiceTableMobile: React.FC<InvoiceTableProps> = ({
  invoiceList,
  selectedRows,
  selectItem,
  toggleSelectAll,
  viewInvoice,
}) => {
  const { t } = useTranslation('ns');
  return (
    <div className="hidden lg:block">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th scope="col" className="py-3.5 pl-4 text-left font-semibold text-sm sm:pl-3 w-px">
                  <Checkbox
                    value={
                      invoiceList.filter((item) => Number(item.remaining_amount) > 0).length ===
                        selectedRows.length && selectedRows.length !== 0
                    }
                    onChange={toggleSelectAll}
                    disabled={!invoiceList.filter((item) => Number(item.remaining_amount) > 0).length}
                    classNameWrapper="w-min"
                  />
                </th>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                  {t('invoice-number')}
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  {t('invoice-date')}
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  {t('invoice-total')}
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  {t('invoice-paid')}
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  {t('balance')}
                </th>
                <th
                  scope="col"
                  className=" py-3.5 pl-3 text-left text-sm pr-4 sm:pr-3 w-px font-semibold text-gray-900"
                >
                  {t('invoice')}
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {invoiceList.map((inv) => (
                <tr key={inv.ace_invoice_id} 
                    className="even:bg-gray-50">
                  <td className="whitespace-nowrap pl-3 py-4 text-sm text-gray-900 w-px">
                    <Checkbox
                      value={selectedRows.includes(inv.ace_invoice_id)}
                      onChange={(value) => {
                        selectItem(inv.ace_invoice_id, inv.id, value);
                      }}
                      disabled={Number(inv.remaining_amount) <= 0}
                      classNameWrapper="w-min"
                    />
                  </td>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3 flex content-center">
                    {inv.generation_number}
                    {Number(inv.remaining_amount) > 0 && (
                      <Icon
                        icon={IconsTypes.EyeFull}
                        onClick={() => {
                          viewInvoice(inv.ace_invoice_id);
                        }}
                        size="16"
                        className="fill-blue-900 ms-2.5 mt-0.5 cursor-pointer w-4 h-4"
                      />
                    )}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900">{inv.invoiced_at}</td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900">
                    {t('currency', { amount: inv.invoice_total })}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900">
                    {t('currency', { amount: inv.paid_amount })}
                  </td>
                  <td
                    className={tailwindClassNames(
                      'whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900',
                      Number(inv.remaining_amount) <= 0 ? 'text-green-600' : 'text-red-600'
                    )}
                  >
                    {Number(inv.remaining_amount) <= 0 ? (
                      t('invoice-paid')
                    ) : (
                      <span>{t('currency', { amount: inv.remaining_amount })}</span>
                    )}
                  </td>
                  <td
                    className={tailwindClassNames(
                      'relative whitespace-nowrap py-4 pl-3 pr-4 text-left text-sm font-medium sm:pr-3 cursor-pointer flex w-min',
                      Number(inv.remaining_amount) <= 0 ? 'text-blue-900' : 'text-red-600 '
                    )}
                  >
                    {Number(inv.remaining_amount) > 0 && (
                      <Icon
                        icon={IconsTypes.Pay}
                        size="16"
                        className="fill-red-600 mr-1 mt-0.5 cursor-pointer w-4 h-4"
                        onClick={() => {
                          selectItem(inv.ace_invoice_id, inv.id, true);
                        }}
                      />
                    )}
                    {Number(inv.remaining_amount) <= 0 && (
                      <Icon
                        icon={IconsTypes.Download}
                        onClick={() => {
                          viewInvoice(inv.ace_invoice_id);
                        }}
                        size="16"
                        className="fill-blue-900 mr-1 mt-0.5 cursor-pointer w-4 h-4"
                      />
                    )}
                    {Number(inv.remaining_amount) <= 0 ? (
                      <div
                        onClick={() => {
                          viewInvoice(inv.ace_invoice_id);
                        }}
                      >
                        {t('download')}
                      </div>
                    ) : (
                      <div
                        onClick={() => {
                          selectItem(inv.ace_invoice_id, inv.id, true);
                        }}
                      >
                        {t('pay')}
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default InvoiceTableMobile;
