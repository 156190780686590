import React, { useEffect, useState } from 'react';
import Login from '../../pages/Login/Login';
import { isAdmin, isAuthPage, isLogged, isRegularUser } from '../../utils/utils';
import { Navigate, Route, Routes } from 'react-router-dom';
import Menu from '../Menu/Menu';
import Error from '../../pages/Error/Error';
import ResetPassword from '../../pages/ResetPassword/ResetPassword';
import RecoverPassword from '../../pages/RecoverPassword/RecoverPassword';
import Register from '../../pages/Register/Register';
import SendIndex from '../../pages/SendIndex/SendIndex';
import ManageContracts from '../../pages/ManageContracts/ManageContracts';
import Settings from '../../pages/Settings/Settings';
import Invoices from '../../pages/Invoices/Invoices';
import DeactivateInvoice from '../../pages/DeactivateInvoice/DeactivateInvoice';
import useApi from '../../hooks/use-api';
import { AppRoutes } from '../../utils/props';
import Breadcrumb from '../BreadCrum/BreadCrum';
import tailwindClassNames from '../../utils/tailwindClassNames';
import ConsentTOS from '../../pages/Consent/ConsentTOS';
import ConsentGDPR from '../../pages/Consent/ConsentGDPR';
import Verify from '../../pages/Verify/Verify';
import DeleteConfirmation from '../../pages/DeleteConfirmation/DeleteConfirmation';
import RecoverAccount from '../../pages/RecoverAccount/RecoverAccount';
import ChangeEmail from '../../pages/ChangeEmail/ChangeEmail';
import Administration from '../../pages/Administration/Administration';
import Users from '../../pages/Administration/Consumers/Consumers';
import Indexes from '../../pages/Administration/Indexes/Indexes';
import Transactions from '../../pages/Administration/Transactions/Transactions';

export default function Router() {
  const [currentLocation, setCurrentLocation] = useState<string>('');
  const [userType, setUserType] = useState<string>(localStorage.getItem('user_type') || '');
  const [invoicePeriod, setInvoicePeriod] = useState<number>(0);
  const [isAdministrator, setisAdministrator] = useState<boolean>(false);

  const settings = useApi({
    path: '/settings',
  });

  const consumers = useApi({
    path: '/consumers',
    callback: (data) => {
      setCurrentLocation(data?.consumers[0]?.id);
    },
  });

  const checkIfRegularUser = () => {
    return isRegularUser(settings.data, localStorage.getItem('user_type') || '');
  };

  const checkIfAdministrator = () => {
    return isAdmin(settings.data, localStorage.getItem('user_type') || '');
  };

  useEffect(() => {
    setisAdministrator(isAdmin(settings.data || {}, userType));
  }, [userType, settings.data]);

  useEffect(() => {
    settings.get();
    if (!isAdmin(settings.data || {}, userType)) {
      consumers.get();
    }
  }, []);

  useEffect(() => {
    setInvoicePeriod(
      consumers?.data?.consumers?.filter((value: any) => value.id === parseInt(currentLocation))[0]?.invoice_period
    );
  }, [currentLocation]);

  const getSettingsAfterLogin = (userType: string) => {
    if (!isAdmin(settings.data || {}, userType)) {
      consumers.get();
    }
  };

  const isValidPage = () => {
    const location = window.location.href;
    return (
      location.toLocaleLowerCase().includes(AppRoutes.Invoices) ||
      location.toLocaleLowerCase().includes(AppRoutes.ManageContracts) ||
      location.toLocaleLowerCase().includes(AppRoutes.Settings) ||
      location.toLocaleLowerCase().includes(AppRoutes.Situation) ||
      location.toLocaleLowerCase().includes(AppRoutes.Administration) ||
      location.toLocaleLowerCase().includes(AppRoutes.AdministrationUsers) ||
      location.toLocaleLowerCase().includes(AppRoutes.SendIndex) ||
      location.toLocaleLowerCase().includes(AppRoutes.AdministrationIndexes) ||
      location.toLocaleLowerCase().includes(AppRoutes.AdministrationTransactions)
    );
  };

  const updateCurrentLocation = (value: string) => {
    setCurrentLocation(value);
  };

  const updateSettings = () => {
    settings.get();
  };

  return (
    <>
      {!isAuthPage() && isLogged() && (
        <Breadcrumb
          displayConsumers={
            !window.location.href.toLocaleLowerCase().includes(AppRoutes.Settings) &&
            !window.location.href.toLocaleLowerCase().includes(AppRoutes.ManageContracts) &&
            !window.location.href.toLocaleLowerCase().includes(AppRoutes.ConsentGDPR) &&
            !window.location.href.toLocaleLowerCase().includes(AppRoutes.ConsentTOS)
          }
          consumers={consumers.data}
          updateCurrentLocation={updateCurrentLocation}
          userType={userType}
          settings={settings.data || {}}
        />
      )}

      <div className="block lg:flex max-w-full w-330 app-wrapper xl:mx-auto overflow-hidden">
        {!isAuthPage() && isValidPage() && isLogged() && <Menu userType={userType} settings={settings.data || {}} />}
        <div className={tailwindClassNames('grow w-full min-h-screen overflow-x-hidden')}>
          <Routes>
            <Route path={AppRoutes.ResetPassword} element={<ResetPassword settings={settings.data || {}} />} />
            <Route path={AppRoutes.RecoverPassword} element={<RecoverPassword settings={settings.data || {}} />} />
            <Route path={AppRoutes.Verify} element={<Verify settings={settings.data || {}} />} />
            <Route
              path={`${AppRoutes.DeleteConfirmation}/*`}
              element={<DeleteConfirmation settings={settings.data || {}} />}
            />
            <Route
              path={`${AppRoutes.DeactivateInvoice}`}
              element={<DeactivateInvoice settings={settings.data || {}} />}
            />
            <Route path={`${AppRoutes.RecoverAccount}/*`} element={<RecoverAccount settings={settings.data || {}} />} />
            <Route path={`${AppRoutes.ChangeEmail}/*`} element={<ChangeEmail settings={settings.data || {}} />} />
            <Route
              path={AppRoutes.Login}
              element={
                (checkIfRegularUser() && <Navigate to={AppRoutes.Invoices} />) ||
                (checkIfAdministrator() && <Navigate to={AppRoutes.AdministrationUsers} />) || (
                  <Login
                    settings={settings.data || {}}
                    getSettingsAfterLogin={getSettingsAfterLogin}
                    updateUserType={(id) => {
                      setUserType(id);
                    }}
                  />
                )
              }
            />
            <Route path={AppRoutes.Register} element={<Register settings={settings.data || {}} />} />
            <Route
              path={AppRoutes.Invoices}
              element={<Invoices settings={settings.data || {}} currentLocation={currentLocation} />}
            />
            <Route path={AppRoutes.ManageContracts} element={<ManageContracts settings={settings.data || {}} />} />
            <Route path={AppRoutes.ConsentGDPR} element={<ConsentGDPR settings={settings.data || {}} getSettingsAfterUpdate={getSettingsAfterLogin} />} />
            <Route path={AppRoutes.ConsentTOS} element={<ConsentTOS settings={settings.data || {}} getSettingsAfterUpdate={getSettingsAfterLogin}/>} />
            <Route path={AppRoutes.Settings} element={<Settings />} />
            <Route
              path={AppRoutes.Administration}
              element={<Administration settings={settings.data || {}} updateSettings={updateSettings} />}
            />
            <Route path={AppRoutes.AdministrationUsers} element={<Users />} />
            <Route path={AppRoutes.AdministrationIndexes} element={<Indexes />} />
            <Route
              path={AppRoutes.AdministrationTransactions}
              element={<Transactions settings={settings.data || {}} />}
            />
            <Route
              path={AppRoutes.SendIndex}
              element={
                <SendIndex
                  currentLocation={currentLocation}
                  invoice_period={invoicePeriod}
                  settings={settings.data || {}}
                />
              }
            />
            <Route path={AppRoutes.Error} element={<Error />} />
            <Route path="/" element={<Navigate to={AppRoutes.Invoices} />} />
          </Routes>
        </div>
      </div>
    </>
  );
}
