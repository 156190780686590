import useApi from '../../../hooks/use-api';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import useTable from '../../../hooks/useTable';
import {
  ButtonSize,
  ButtonStyle,
  ChipVariants,
  DefaultPageProps,
  IconsTypes,
  MessageStyle,
  RowRenderProps,
  SORT_ORDER,
  SortSelectValue,
  Transaction,
  TransactionsTableFilters,
  TransactionStatuses,
  TransactionStatusLabels,
  UseAPIReturnType,
} from '../../../utils/props';
import PageContainer from '../../../components/PageContainer';
import useSearch from '../../../hooks/useSearch';
import PaginationPagesBar from '../../../components/Table/Pagination/PaginationPagesBar';
import pick from 'lodash/pick';
import Search from '../../../components/Table/Search';
import NumberOfResults from '../../../components/Table/Pagination/NumberOfResults';
import Button from '../../../components/Button/Button';
import Icon from '../../../components/Icon/Icon';
import { formatDisplayedValue, isLogged, viewInvoice, formatedDate, getFilename } from '../../../utils/utils';
import LoadingOverlay from '../../../components/Spinner/LoadingOverlay';
import Chip from '../../../components/Chip';
import Filters from './Filters';
import { useTranslation } from 'react-i18next';
import Message from '../../../components/Message/Message';

export default function Transactions({ settings }: DefaultPageProps) {
  const paymentsApi: UseAPIReturnType<Transaction> = useApi({
    path: `/admin/payments`,
  });

  const { t } = useTranslation('ns');
  const [invoicePdfError, setInvoicePdfError] = useState<string>('');

  const {
    search,
    setSearch,
    filters,
    setFilters,
    sort,
    setSort,
    page,
    setPage,
    numberOfResultsToDisplay,
    setNumberOfResultsToDisplay,
    exportToCsv,
    exporting,
  } = useSearch<Transaction, TransactionsTableFilters, SortSelectValue>({
    api: paymentsApi,
    defaultSort: {
      sortBy: 'joined',
      sortOrder: SORT_ORDER.ASC,
    },
  });

  const { resetSelected, TableComponent } = useTable({
    uniqueKeyField: 'payment_id',
    columns: [
      {
        name: 'btpay_order_id',
        label: t('order-id'),
        className: 'whitespace-nowrap min-w-[119px] max-w-[119px]',
      },
      {
        name: 'created_dt',
        label: t('payment-date'),
        className: 'whitespace-nowrap min-w-[119px] max-w-[119px]',
      },
      {
        name: 'mail',
        label: t('email'),
        className: 'whitespace-nowrap min-w-[119px] max-w-[119px]',
      },
      {
        name: 'name',
        label: t('account-owner'),
        className: 'whitespace-nowrap min-w-[119px] max-w-[119px]',
      },
      {
        name: 'invoicing_code',
        label: t('subscriber-code'),
        className: 'whitespace-nowrap min-w-[119px] max-w-[119px]',
      },
      {
        name: 'amount',
        label: t('sum'),
        className: 'whitespace-nowrap min-w-[119px] max-w-[119px]',
      },
      {
        name: 'ids',
        label: t('description'),
        className: 'whitespace-nowrap min-w-[119px] max-w-[119px]',
      },
      {
        name: 'status',
        label: t('status'),
        className: 'whitespace-nowrap min-w-[109px] max-w-[109px]',
      },
      {
        name: 'error',
        label: t('error'),
        className: 'whitespace-nowrap min-w-[119px] max-w-[119px]',
      },
      {
        name: 'rrn',
        label: t('RRN-received'),
        className: 'whitespace-nowrap min-w-[129px] max-w-[129px]',
      },
    ],
    items: paymentsApi?.data?.items?.map((transaction: Transaction) => ({
      data: transaction,
      render: ({ CellComponent }: RowRenderProps) => {
        const statusChip = getStatusChipProps(transaction.status);
        return (
          <Fragment>
            <CellComponent tooltipEnabled className="min-w-[119px] max-w-[119px]">
            {formatDisplayedValue(transaction.btpay_order_id, '-')}
            </CellComponent>
            <CellComponent tooltipEnabled className="min-w-[119px] max-w-[119px]">
              {formatedDate(transaction.created_dt, 'DD.MM.YYYY HH:mm:ss')}
            </CellComponent>
            <CellComponent tooltipEnabled className="min-w-[119px] max-w-[119px]">
              {formatDisplayedValue(transaction.mail, '-')}
            </CellComponent>
            <CellComponent tooltipEnabled className="min-w-[119px] max-w-[119px]">
              {formatDisplayedValue(transaction.name, '-')}
            </CellComponent>
            <CellComponent tooltipEnabled className="min-w-[119px] max-w-[119px]">
              {formatDisplayedValue(transaction.invoicing_code, '-')}
            </CellComponent>
            <CellComponent tooltipEnabled className="min-w-[119px] max-w-[119px]">
              {formatDisplayedValue(transaction.amount, '-')}
            </CellComponent>
            <CellComponent tooltipEnabled className="min-w-[119px] max-w-[119px]">
              {renderInvoiceLinks(transaction, '-')}
            </CellComponent>
            <CellComponent className="min-w-[109px] max-w-[109px]">
              <Chip variant={statusChip.variant}>{statusChip.label}</Chip>
            </CellComponent>
            <CellComponent tooltipEnabled className="min-w-[119px] max-w-[119px]">
              {formatDisplayedValue(transaction.error, '-')}
            </CellComponent>
            <CellComponent tooltipEnabled className="min-w-[129px] max-w-[129px]">
              {formatDisplayedValue(transaction.rrn, '-')}
            </CellComponent>
          </Fragment>
        );
      },
    })),
    sortSettings: {
      sort,
      setSort,
    },
  });

  const renderInvoiceLinks = (transaction: Transaction, emptyIndicator = '') => {
    if (!transaction.invoices?.length) {
      return emptyIndicator;
    }

    return transaction.invoices.map((invoice, index) => (
      <span key={invoice.id}>
        <button
          onClick={() => viewInvoice(invoice.ace_invoice_id, transaction.consumer_id.toString(), t, setInvoicePdfError)}
          className="cursor-pointer underline"
        >
          {invoice.generation_number}
        </button>
        {invoice.generation_number && index < transaction.invoices.length - 1 && ', '}
      </span>
    ));
  };

  const getStatusChipProps = (status: TransactionStatuses) => {
    switch (status) {
      case TransactionStatuses.CREATED: {
        return {
          variant: undefined,
          label: TransactionStatusLabels.CREATED,
        };
      }
      case TransactionStatuses.SENT_TO_BTPAY: {
        return {
          variant: ChipVariants.INFO,
          label: TransactionStatusLabels.SENT_TO_BTPAY,
        };
      }
      case TransactionStatuses.FINALIZED: {
        return {
          variant: ChipVariants.SUCCESS,
          label: TransactionStatusLabels.FINALIZED,
        };
      }
      case TransactionStatuses.ERROR: {
        return {
          variant: ChipVariants.ERROR,
          label: TransactionStatusLabels.ERROR,
        };
      }
      default: {
        return {
          variant: undefined,
          label: TransactionStatusLabels.NOT_APPLICABLE,
        };
      }
    }
  };

  const paginatedResponseMeta = useMemo(
    () => pick(paymentsApi?.data, ['items_no', 'current_page', 'items_per_page', 'total_pages']),
    [paymentsApi?.data]
  );

  // When search params have changed, reset the selected rows
  useEffect(() => {
    resetSelected();
  }, [numberOfResultsToDisplay, page, search, filters, sort]);

  const handleExport = () => {
    const filename = getFilename(t('export-transactions-sufix'), true, '.csv');
    exportToCsv(filename);
  };

  return (
    <>
      {isLogged() ? (
        <PageContainer className="flex flex-col gap-y-7">
          <div className="flex gap-5 items-center">
            <Search title={'Istoric tranzacții'} search={search} setSearch={setSearch} />
            <Button
              size={ButtonSize.Large}
              variant={ButtonStyle.Secondary}
              title={t('export-csv')}
              loading={exporting}
              onClick={handleExport}
            >
              <Icon
                icon={IconsTypes.Download2}
                size="16"
                className="h-5 w-5 text-blue-900 fill-blue-900"
                aria-hidden="true"
              />
              {t('export-csv')}
            </Button>
          </div>
          <div className="flex justify-between gap-4">
            <NumberOfResults
              numberOfResultsToDisplay={numberOfResultsToDisplay}
              setNumberOfResultsToDisplay={setNumberOfResultsToDisplay}
              meta={paginatedResponseMeta}
            />
            <Filters settings={settings} filters={filters} setFilters={setFilters} />
          </div>
          {invoicePdfError && (
            <Message
              type={MessageStyle.Error}
              className="my-6 "
              onCloseFunction={() => {
                setInvoicePdfError('');
              }}
            >
              {invoicePdfError}
            </Message>
          )}
          {TableComponent()}
          <PaginationPagesBar meta={paginatedResponseMeta} setPage={setPage} />
        </PageContainer>
      ) : (
        <LoadingOverlay />
      )}
    </>
  );
}
