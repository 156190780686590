import useApi from '../../../hooks/use-api';
import React, { Fragment, useEffect, useMemo } from 'react';
import useTable from '../../../hooks/useTable';
import {
  ButtonSize,
  ButtonStyle,
  ChipVariants,
  ClientTypes,
  Consumer,
  ConsumersTableFilters,
  IconsTypes,
  RowRenderProps,
  SORT_ORDER,
  SortSelectValue,
  UseAPIReturnType,
  User,
} from '../../../utils/props';
import PageContainer from '../../../components/PageContainer';
import Checkbox from '../../../components/Checkbox/Checkbox';
import useSearch from '../../../hooks/useSearch';
import PaginationPagesBar from '../../../components/Table/Pagination/PaginationPagesBar';
import pick from 'lodash/pick';
import Search from '../../../components/Table/Search';
import NumberOfResults from '../../../components/Table/Pagination/NumberOfResults';
import Filters from './Filters';
import Button from '../../../components/Button/Button';
import Icon from '../../../components/Icon/Icon';
import SelectedRowsActions from './SelectedRowsActions';
import Chip from '../../../components/Chip';
import LoadingOverlay from '../../../components/Spinner/LoadingOverlay';
import { formatDisplayedValue, formatedDate, getFilename, isLogged } from '../../../utils/utils';
import RowActions from './RowActions';
import ResetPasswordPopup from './ResetPasswordPopup';
import { useTranslation } from 'react-i18next';

export default function Consumers() {
  const [consumerToUpdate, setConsumerToUpdate] = React.useState<Consumer | undefined>(undefined);

  const consumersApi: UseAPIReturnType<Consumer> = useApi({
    path: `/admin/consumers`,
  });

  const usersApi: UseAPIReturnType<User> = useApi({
    path: `/admin/users`,
  });

  const { t } = useTranslation('ns');

  const {
    search,
    setSearch,
    filters,
    setFilters,
    sort,
    setSort,
    page,
    setPage,
    numberOfResultsToDisplay,
    setNumberOfResultsToDisplay,
    resetPage,
    deleteRecordCallback,
    exportToCsv,
    exporting,
  } = useSearch<Consumer, ConsumersTableFilters, SortSelectValue>({
    api: consumersApi,
    defaultSort: {
      sortBy: 'joined',
      sortOrder: SORT_ORDER.DESC,
    },
  });

  const {
    hasSelectedAllRowsOnPage,
    hasSelectedAllRowsOnAllPages,
    setHasSelectedAllRowsOnAllPages,
    selected: selectedUsers,
    resetSelected,
    TableComponent,
  } = useTable({
    canSelectAll: true,
    uniqueKeyField: 'consumer_id',
    fieldToSelect: 'user_id',
    columns: [
      {
        name: 'joined',
        label: t('creation-date'),
        className: 'whitespace-nowrap min-w-[95px] max-w-[95px]',
        sortable: true,
      },
      {
        name: 'ace_client_type',
        label: t('subscriber-type'),
      },
      {
        name: 'invoicing_code',
        label: t('subscriber-code'),
      },
      {
        name: 'name',
        label: t('subscriber-name'),
      },
      {
        className: 'min-w-[190px] max-w-[190px]',
        name: 'mail',
        label: t('email'),
      },
      {
        name: 'phone',
        label: t('phone-number'),
        className: 'whitespace-nowrap min-w-[120px] max-w-[120px]',
      },
      {
        name: 'online_invoice',
        label: t('green-invoice'),
      },
      {
        name: 'mail_verified',
        label: t('account-is-confirmed'),
        className: 'whitespace-nowrap',
        sortable: true,
      },
      {
        name: 'deleted',
        label: t('status'),
        className: 'whitespace-nowrap',
        sortable: true,
      },
      {
        name: 'online_invoice_dt',
        label: t('activation-date'),
        className: 'whitespace-nowrap',
      },
      {
        name: 'actions',
        label: '',
      },
    ],
    items: consumersApi?.data?.items?.map((consumer: Consumer) => ({
      data: consumer,
      render: ({ selected, onSelect, CellComponent }: RowRenderProps) => (
        <Fragment>
          <CellComponent className="px-px min-w-[30px] max-w-[30px]">
            <Checkbox value={selected} onChange={onSelect} classNameWrapper="w-min mx-auto" />
          </CellComponent>
          <CellComponent className="min-w-[95px] max-w-[95px]">{formatedDate(consumer.joined, 'DD.MM.YYYY')}</CellComponent>
          <CellComponent tooltipEnabled>
            <div className="whitespace-normal w-[90px] break-words">
              {formatDisplayedValue(ClientTypes[consumer.ace_client_type])}
            </div>
          </CellComponent>
          <CellComponent tooltipEnabled>{formatDisplayedValue(consumer.invoicing_code, '-')}</CellComponent>
          <CellComponent tooltipEnabled>{formatDisplayedValue(consumer.name, '-')}</CellComponent>
          <CellComponent tooltipEnabled className="min-w-[190px] max-w-[190px]">
            {formatDisplayedValue(consumer.mail, '-')}
          </CellComponent>
          <CellComponent tooltipEnabled className="min-w-[120px] max-w-[120px]">
            {formatDisplayedValue(consumer.phone, '-')}</CellComponent>
          <CellComponent className="h-full">
            {!!consumer.online_invoice && (
              <div className="w-6 h-6 flex justify-center items-center rounded-full bg-green-100 mx-auto">
                <Icon icon={IconsTypes.Check} size={16} className="fill-green-700 w-3 h-3 cursor-pointer" />
              </div>
            )}
          </CellComponent>
          <CellComponent>{consumer.mail_verified ? t('yes-first-upper') : t('no-first-upper')}</CellComponent>
          <CellComponent>
            <Chip variant={!consumer.deleted ? ChipVariants.SUCCESS : undefined}>
              {consumer.deleted === 0 && t('active') ||
               consumer.deleted === 1 && t('inactive') ||
               consumer.deleted === 2 && t('removed')}
            </Chip>
          </CellComponent>
          <CellComponent>{!!consumer.online_invoice_dt && formatedDate(consumer.online_invoice_dt, 'DD.MM.YYYY')}</CellComponent>
          <CellComponent>
            <RowActions
              usersApi={usersApi}
              consumer={consumer}
              resetSelected={resetSelected}
              deleteRecordCallback={deleteRecordCallback}
              setConsumerToUpdate={setConsumerToUpdate}
            />
          </CellComponent>
        </Fragment>
      ),
    })),
    sortSettings: {
      sort,
      setSort,
    },
  });

  const paginatedResponseMeta = useMemo(
    () => pick(consumersApi?.data, ['items_no', 'current_page', 'items_per_page', 'total_pages']),
    [consumersApi?.data]
  );

  // When search params have changed, reset the selected rows
  useEffect(() => {
    resetSelected();
  }, [numberOfResultsToDisplay, page, search, filters, sort]);

  const handleExport = () => {
    const filename = getFilename(t('export-subscribers-sufix'), true, '.csv');
    exportToCsv(filename);
  };

  return (
    <>
      {isLogged() ? (
        <PageContainer className="flex flex-col gap-y-7">
          <div className="flex gap-5 items-center">
            <Search title={t('subscriber-list')} search={search} setSearch={setSearch} />
            <Button
              size={ButtonSize.Large}
              variant={ButtonStyle.Secondary}
              title={t('export-csv')}
              loading={exporting}
              onClick={handleExport}
            >
              <Icon
                icon={IconsTypes.Download2}
                size="16"
                className="h-5 w-5 text-blue-900 fill-blue-900"
                aria-hidden="true"
              />
              {t('export-csv')}
            </Button>
          </div>
          <div className="flex justify-between gap-4">
            <NumberOfResults
              numberOfResultsToDisplay={numberOfResultsToDisplay}
              setNumberOfResultsToDisplay={setNumberOfResultsToDisplay}
              meta={paginatedResponseMeta}
            />
            <Filters filters={filters} setFilters={setFilters} />
          </div>
          <SelectedRowsActions
            usersApi={usersApi}
            selected={selectedUsers}
            resetPage={resetPage}
            hasSelectedAllRowsOnPage={hasSelectedAllRowsOnPage}
            hasSelectedAllRowsOnAllPages={hasSelectedAllRowsOnAllPages}
            setHasSelectedAllRowsOnAllPages={setHasSelectedAllRowsOnAllPages}
            resetSelected={resetSelected}
            meta={paginatedResponseMeta}
          />
          {TableComponent()}
          <PaginationPagesBar meta={paginatedResponseMeta} setPage={setPage} />
          <ResetPasswordPopup consumer={consumerToUpdate} onClose={() => setConsumerToUpdate(undefined)} />
        </PageContainer>
      ) : (
        <LoadingOverlay />
      )}
    </>
  );
}
