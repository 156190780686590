import React, { useEffect, useRef } from 'react';
import { ButtonSize, ButtonStyle, IconsTypes, PopupProps } from '../../utils/props';
import Icon from '../Icon/Icon';
import Button from '../Button/Button';
import { createPortal } from 'react-dom';

const Popup: React.FC<PopupProps> = ({
  onOk,
  onClose,
  cancelText = 'Cancel',
  okText = 'OK',
  hideButtons = false,
  title = ' ',
  children,
  loading,
  disableOk,
}) => {
  const popupRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
      onClose?.();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return createPortal(
    <div className="fixed top-0 left-0 w-full h-full z-[100]">
      <div className="relative w-full h-full ">
        <div className="bg-black absolute top-0 left-0 w-full h-full opacity-50"></div>
        <div
          ref={popupRef}
          className="max-w-[600px] absolute right-1/2 bottom-1/2 w-11/12 lg:w-full translate-x-1/2 translate-y-1/2 bg-white rounded-xl"
        >
          <div className="p-10 relative">
            <div className="flex justify-center items-center">
              {title && <h2 className=" font-semibold text-xl gray-900 text-center">{title}</h2>}
            </div>

            <button
              onClick={onClose}
              className="absolute left-full bottom-full border-1.5 bg-white rounded-full cursor-pointer translate-y-[50%] translate-x-[-50%] border-white "
            >
              <Icon icon={IconsTypes.exitCircle} size={28} className="fill-rose-600" />
            </button>
            <div className="py-2 text-center text-base">{children}</div>
            {!hideButtons && (
              <div className="pt-10 flex gap-4">
                <Button
                  size={ButtonSize.Large}
                  variant={ButtonStyle.Secondary}
                  title={cancelText}
                  className="my-0 grow"
                  onClick={onClose}
                >
                  {cancelText}
                </Button>
                {onOk && (
                  <Button
                    size={ButtonSize.Large}
                    variant={ButtonStyle.Primary}
                    title={okText}
                    className="my-0 grow"
                    onClick={onOk}
                    loading={loading}
                    disabled={disableOk}
                  >
                    {okText}
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default Popup;
