import React from 'react';
import { IconsTypes } from '../../utils/props';
import { useTranslation } from 'react-i18next';
import Checkbox from '../../components/Checkbox/Checkbox';
import Icon from '../../components/Icon/Icon';

interface PayObligationsProps {
  payValue: number;
  selectedIds: number[];
  invoiceId: number | null;
  selectItem: (id: number | null, value: boolean) => void;
}

const PayObligations: React.FC<PayObligationsProps> = ({ payValue, selectedIds, invoiceId, selectItem }) => {
  const { t } = useTranslation('ns');

  return (
    <div className="py-9 border-b-2 border-color-gray mb-14">
      <>
        <div>
          <p className="font-bold my-4">{t('pay-obligations')}</p>
        </div>
        <div className="p-5 bg-gray-50">
          <div className="block flex justify-between items-center">
            <div className="mb-6 lg:mb-0 grow w-1/6 mr-2">
              <div className="font-bold flex item-center">
                <Checkbox
                  value={selectedIds.includes(invoiceId ?? 0)}
                  onChange={(value) => {
                    selectItem(invoiceId, value);
                  }}
                  classNameWrapper="w-min"
                />
                <span className="text-red-600 pl-3">{t('currency', { amount: payValue })}</span>
              </div>
            </div>
            <div className="flex items-center justify-end">
              <div className="flex mb-6 lg:mb-0 gap-8 lg:auto items-center justify-around font-medium text-gray-900">
                <p className="lg:block hidden text-sm">{t('disclaimer-obligations')}</p>
                <div
                  className="relative whitespace-nowrap text-left text-sm font-medium cursor-pointer flex w-min text-red-600"
                  onClick={() => {
                    selectItem(invoiceId, true);
                  }}
                >
                  <Icon icon={IconsTypes.Pay} size="16" className="fill-red-600 mr-2 cursor-pointer w-4 h-4" />
                  <div>{t('pay')}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default PayObligations;
