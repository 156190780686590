import React, { useEffect, useState } from 'react';
import LogoCab from '../../assets/images/cab-logo-small.svg';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import { AppRoutes, ButtonSize, ButtonStyle, IconsTypes, MenuProps } from '../../utils/props';
import MenuItem from './MenuItem';
import tailwindClassNames from '../../utils/tailwindClassNames';
import { handleLogout, isAdmin } from '../../utils/utils';
import { useNavigate } from 'react-router-dom';
import CabIcon from '../../assets/images/cab-logo-white.svg';
import { useTranslation } from 'react-i18next';

const Menu = ({ userType, settings }: MenuProps) => {
  const [opened, setOpened] = useState(true);
  const [isAdministrator, setisAdministrator] = useState<boolean>(false);
  const navigate = useNavigate();
  const { t } = useTranslation('ns');

  useEffect(() => {
    setisAdministrator(isAdmin(settings, userType));
  }, [userType, settings]);

  return (
    <div
      className={tailwindClassNames(
        `bg-blue-900 h-auto min-h-full hidden lg:flex min-w-[80px] max-w-[80px] px-2.5 py-10 flex-col items-center`,
        opened ? 'max-w-[260px] min-w-[260px] px-5' : ''
      )}
    >
      {!opened && <img src={LogoCab} className={'w-10 h-10 object-fit'} />}
      {opened && <img src={CabIcon} className="w-[380px]" />}
      <Button
        size={ButtonSize.SmallFull}
        variant={ButtonStyle.Secondary}
        onClick={() => {
          setOpened(!opened);
        }}
        className={'mt-12 h-10 justify-start'}
        labelClass={tailwindClassNames('flex gap-x-0 py-0')}
      >
        <Icon icon={opened ? IconsTypes.MenuClose : IconsTypes.MenuOpen} size="24" className="fill-blue-900 w-5 h-5" />
        {opened && <span className="ps-2 text-sm">{t('close-menu')}</span>}
      </Button>
      <div className="w-full mt-8 flex flex-col gap-2">
        {!isAdministrator && (
          <>
            <MenuItem label={t('invoice-list')} icon={IconsTypes.Facturi} to={AppRoutes.Invoices} opened={opened} />
            <MenuItem label={t('send-index')} icon={IconsTypes.Pen} to={AppRoutes.SendIndex} opened={opened} />
            <MenuItem
              label={t('manage-contracts')}
              icon={IconsTypes.Folder}
              to={AppRoutes.ManageContracts}
              opened={opened}
            />
            <MenuItem
              label={t('account-settings')}
              icon={IconsTypes.Settings}
              to={AppRoutes.Settings}
              opened={opened}
            />
          </>
        )}

        {isAdministrator && (
          <>
            <MenuItem
              label={t('subscriber-list')}
              icon={IconsTypes.Clipboard}
              to={AppRoutes.AdministrationUsers}
              opened={opened}
              iconSize={20}
            />
            <MenuItem
              label={t('index-report')}
              icon={IconsTypes.Presentation}
              to={AppRoutes.AdministrationIndexes}
              opened={opened}
              iconSize={20}
            />
            <MenuItem
              label={t('transaction-history')}
              icon={IconsTypes.Tranzaction}
              to={AppRoutes.AdministrationTransactions}
              opened={opened}
              iconSize={18}
            />
            <MenuItem
              label={t('settings')}
              icon={IconsTypes.Settings}
              to={AppRoutes.Administration}
              opened={opened}
              iconSize={18}
            />
          </>
        )}
        <div className="pt-8 border-b border-b-blue-500" />
        <MenuItem
          label={t('logout')}
          icon={IconsTypes.Logout}
          onClick={() => {
            handleLogout(navigate);
          }}
          opened={opened}
        />
      </div>
    </div>
  );
};

export default Menu;
