import { IconsTypes, SORT_ORDER, SortProps } from '../../utils/props';
import Icon from '../Icon/Icon';
import React from 'react';
import tailwindClassNames from '../../utils/tailwindClassNames';

export default function Sort({ name, sort }: SortProps) {
  return (
    <div className="absolute right-2 top-1/2 transform -translate-y-1/2">
      <Icon
        icon={IconsTypes.Chevron}
        size="16"
        className={tailwindClassNames(
          'absolute -bottom-0.5 right-0 fill-gray-400 w-3 h-3 transform rotate-180 transition delay-100',
          sort?.sortOrder === SORT_ORDER.ASC && name === sort?.sortBy ? 'fill-gray-900' : ''
        )}
      />
      <Icon
        icon={IconsTypes.Chevron}
        size="16"
        className={tailwindClassNames(
          'absolute -top-0.5 right-0 fill-gray-400 w-3 h-3 transition delay-100',
          sort?.sortOrder === SORT_ORDER.DESC && name === sort?.sortBy ? 'fill-gray-900' : ''
        )}
      />
    </div>
  );
}
