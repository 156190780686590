import React from 'react';
import Checkbox from '../../components/Checkbox/Checkbox';
import { InvoiceTableProps, IconsTypes } from '../../utils/props';
import tailwindClassNames from '../../utils/tailwindClassNames';
import Icon from '../../components/Icon/Icon';
import { useTranslation } from 'react-i18next';

const InvoiceTableWeb: React.FC<InvoiceTableProps> = ({
  invoiceList,
  selectedRows,
  selectItem,
  toggleSelectAll,
  viewInvoice,
}) => {
  const { t } = useTranslation('ns');
  return (
    <div className="block lg:hidden">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="bg-gray-50 py-3.5 pl-4 ps-3 flex">
            <Checkbox
              value={
                invoiceList.filter((item) => Number(item.remaining_amount) > 0).length === selectedRows.length &&
                selectedRows.length !== 0
              }
              onChange={toggleSelectAll}
              disabled={!invoiceList.filter((item) => Number(item.remaining_amount) > 0).length}
              className="mr-5"
            />
            {t('invoice-pay-all')}
          </div>
          {invoiceList.map((inv) => (
            <table key={`mob-${inv.ace_invoice_id}`} className="min-w-full divide-y divide-gray-300 table-fixed">
              <thead></thead>
              <tbody className="bg-white full">
                <tr className="even:bg-gray-50">
                  <td className="whitespace-nowrap px-3 py-4">
                    <Checkbox
                      value={selectedRows.includes(inv.ace_invoice_id)}
                      onChange={(value) => {
                        selectItem(inv.ace_invoice_id, inv.id, value);
                      }}
                      disabled={inv.remaining_amount == '0'}
                    />
                  </td>
                  <td scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                    {t('invoice-number')}
                  </td>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3 flex">
                    {inv.generation_number}
                    {inv.remaining_amount != '0' && (
                      <Icon
                        icon={IconsTypes.EyeFull}
                        onClick={() => {
                          viewInvoice(inv.ace_invoice_id);
                        }}
                        size="16"
                        className="fill-blue-900 ms-1 mt-0.5 cursor-pointer w-4 h-4"
                      />
                    )}
                  </td>
                </tr>
                <tr className="even:bg-gray-50">
                  <td className="whitespace-nowrap px-3 py-4" />
                  <td scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    {t('invoice-date')}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{inv.invoiced_at}</td>
                </tr>
                <tr className="even:bg-gray-50">
                  <td className="whitespace-nowrap px-3 py-4" />
                  <td scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    {t('invoice-total')}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                    {t('currency', { amount: inv.invoice_total })}
                  </td>
                </tr>
                <tr className="even:bg-gray-50">
                  <td className="whitespace-nowrap px-3 py-4" />
                  <td scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    {t('invoice-paid')}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                    {t('currency', { amount: inv.paid_amount })}
                  </td>
                </tr>
                <tr className="even:bg-gray-50">
                  <td className="whitespace-nowrap px-3 py-4" />
                  <td scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    {t('balance')}
                  </td>
                  <td
                    className={tailwindClassNames(
                      'whitespace-nowrap px-3 py-4 text-sm text-gray-900',
                      inv.remaining_amount == '0' ? 'text-green-600' : 'text-red-600'
                    )}
                  >
                    {inv.remaining_amount == '0' ? (
                      t('invoice-paid')
                    ) : (
                      <span>{t('currency', { amount: inv.remaining_amount })}</span>
                    )}
                  </td>
                </tr>
                <tr className="even:bg-gray-50">
                  <td className="whitespace-nowrap px-3 py-4" />
                  <td scope="col" className="px-3 py-3.5 text-left text-sm text-gray-900">
                    {t('invoice')}
                  </td>
                  <td
                    className={tailwindClassNames(
                      'relative whitespace-nowrap py-4 pl-3 pr-4 text-left text-sm font-medium sm:pr-3 cursor-pointer flex',
                      inv.remaining_amount == '0' ? 'text-blue-900' : 'text-red-600 '
                    )}
                  >
                    {inv.remaining_amount != '0' && (
                      <Icon
                        icon={IconsTypes.Pay}
                        size="16"
                        className="fill-blue-900 mr-1 mt-0.5 cursor-pointer w-4 h-4"
                        onClick={() => {
                          selectItem(inv.ace_invoice_id, inv.id, true);
                        }}
                      />
                    )}
                    {inv.remaining_amount == '0' && (
                      <Icon
                        icon={IconsTypes.Download}
                        onClick={() => {
                          viewInvoice(inv.ace_invoice_id);
                        }}
                        size="16"
                        className="fill-blue-900 mr-1 mt-0.5 cursor-pointer w-4 h-4"
                      />
                    )}
                    {inv.remaining_amount == '0' ? (
                      <div
                        onClick={() => {
                          viewInvoice(inv.ace_invoice_id);
                        }}
                      >
                        {t('download')}
                      </div>
                    ) : (
                      <div
                        onClick={() => {
                          selectItem(inv.ace_invoice_id, inv.id, true);
                        }}
                      >
                        {t('pay')}
                      </div>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          ))}
        </div>
      </div>
    </div>
  );
};

export default InvoiceTableWeb;
