import React from 'react';

import { ConsumersTableDataProps, ClientTypes } from '../../utils/props';
import Icon from '../../components/Icon/Icon';
import { IconsTypes } from '../../utils/props';
import { useTranslation } from 'react-i18next';

const ConsumersTable: React.FC<ConsumersTableDataProps> = ({ consumer, deleteConsumer }) => {
  const { t } = useTranslation('ns');
  return (
    <div className="py-4 hidden lg:block">
      <h2 className="font-medium pb-4">{consumer.alias}</h2>
      <table className="w-full divide-y divide-gray-300 border-t border-t-gray-300 overflow-hidden table-fixed">
        <thead>
          <tr>
            <th scope="col" className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900 whitespace-nowrap w-[20%]'>
              {t('subscriber-type')}
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 whitespace-nowrap w-[15%]">
              {t('subscriber-code')}
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 whitespace-nowrap w-[50%]">
              {t('subscriber-address')}
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 whitespace-nowrap w-[15%]"></th>
          </tr>
        </thead>
        <tbody className="bg-white">
          <tr className="bg-gray-50">
            <td className="whitespace-nowrap px-3 py-4 text-sm text-black-500">
              {ClientTypes[consumer.ace_client_type]}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-black-500">{consumer.invoicing_code}</td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-black-500">
              <div className="max-w-full group flex relative">
                <span
                  className="group-hover:opacity-100
                  transition-opacity bg-gray-100 px-1
                  text-sm text-black-100 rounded-md absolute
                  left-1/2 -translate-x-1/2 -translate-y-full
                  opacity-0 m-4 my-auto"
                >
                  {consumer.address}
                </span>
                <div className = "truncate">
                  {consumer.address}
                </div>
              </div>
            </td>
            <td
              className="whitespace-nowrap px-3 py-4 text-sm text-red-600 cursor-pointer flex items-center font-medium"
              onClick={() => {
                deleteConsumer(consumer.id);
              }}
            >
              <Icon icon={IconsTypes.Exit} size={16} className="fill-red-600 w-4 h-4 mr-1" />
              {t('delete-contract')}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ConsumersTable;
