import React from 'react';
import { AdminGDPRProps, IconsTypes } from '../../utils/props';
import FileUploadComponent from './FileUploadComponent';
import useApi from '../../hooks/use-api';
import Icon from '../../components/Icon/Icon';
import Spinner from '../../components/Spinner/Spinner';
import { useTranslation } from 'react-i18next';

const EditDocuments: React.FC<AdminGDPRProps> = ({ settings, updateSettings }) => {
  const { t } = useTranslation('ns');
  const maxFileSize = 20;
  const successMessage = t('document-added-successfully');
  const tosApi = useApi({
    path: `/setting/${settings?.app_settings?.tos?.id}`,
    defaultSuccessMessage: successMessage,
    callback: (data) => { // eslint-disable-line
      updateSettings();
    },
  });
  const gdprApi = useApi({
    path: `/setting/${settings?.app_settings?.gdpr?.id}`,
    defaultSuccessMessage: successMessage,
    callback: (data) => { // eslint-disable-line
      updateSettings();
    },
  });
  const cookiesApi = useApi({
    path: `/setting/${settings?.app_settings?.cookies?.id}`,
    defaultSuccessMessage: successMessage,
    callback: (data) => { // eslint-disable-line
      updateSettings();
    },
  });

  const handleFileName = (filepath: string) => {
    const filename = filepath.split('/').pop();
    return filename;
  };

  return (
    <div className="w-full p-6 lg:p-6 block lg:flex bg-gray-100 my-2 rounded-xl flex-wrap">
      <div className="w-full">
        <div className="gap-2 flex">
          <div className="grow pr-16">
            <p className="text-base text-gray-900 font-semibold pb-5">{t('terms-and-conditions')}</p>
            <FileUploadComponent
              accept="application/pdf"
              warning={t('upload-file-max-mb', { filesize: maxFileSize })}
              endpointPut={tosApi.put}
              endpointErrors={tosApi.errors}
              endpointSuccessMessage={tosApi.successMessage}
              endpointCloseMessages={tosApi.closeMessages}
            />
          </div>
          <div className="max-w-[270px] w-full">
            <p className="text-base text-gray-900 font-semibold pb-5">{t('current-document')}</p>
            {tosApi.loading && <Spinner size="normal" color="dark" />}
            {!tosApi.loading && (
              <div className="overflow-hidden rounded-md pt-[10px] flex">
                <Icon icon={IconsTypes.Pdf} size="24" className="fill-blue-900 w-4 h-4" />
                <a
                  className="text-blue-900 max-w-[250px] text-ellipsis overflow-hidden ... hover:break-all"
                  target="_blank"
                  href={settings?.app_settings?.tos?.value}
                >
                  {handleFileName(settings?.app_settings?.tos?.value || '')}
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="w-full mt-[50px]">
        <div className="gap-2 flex">
          <div className="grow pr-16">
            <p className="text-base text-gray-900 font-semibold pb-5">{t('gdpr-data-protection')}</p>
            <FileUploadComponent
              accept="application/pdf"
              warning={t('upload-file-max-mb', { filesize: maxFileSize })}
              endpointPut={gdprApi.put}
              endpointErrors={gdprApi.errors}
              endpointSuccessMessage={gdprApi.successMessage}
              endpointCloseMessages={gdprApi.closeMessages}
            />
          </div>
          <div className="max-w-[270px] w-full">
            <p className="text-base text-gray-900 font-semibold pb-5">{t('current-document')}</p>
            {gdprApi.loading && <Spinner size="normal" color="dark" />}
            {!gdprApi.loading && (
              <div className="overflow-hidden rounded-md pt-[10px] flex">
                <Icon icon={IconsTypes.Pdf} size="24" className="fill-blue-900 w-4 h-4" />
                <a
                  className="text-blue-900 max-w-[250px] text-ellipsis overflow-hidden ... hover:break-all"
                  target="_blank"
                  href={settings?.app_settings?.gdpr?.value}
                >
                  {handleFileName(settings?.app_settings?.gdpr?.value || '')}
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="w-full mt-[50px]">
        <div className="gap-2 flex">
          <div className="grow pr-16">
            <p className="text-base text-gray-900 font-semibold pb-5">{t('cookie-policy')}</p>
            <FileUploadComponent
              accept="application/pdf"
              warning={t('upload-file-max-mb', { filesize: maxFileSize })}
              endpointPut={cookiesApi.put}
              endpointErrors={cookiesApi.errors}
              endpointSuccessMessage={cookiesApi.successMessage}
              endpointCloseMessages={cookiesApi.closeMessages}
            />
          </div>
          <div className="max-w-[270px] w-full">
            <p className="text-base text-gray-900 font-semibold pb-5">{t('current-document')}</p>
            {cookiesApi.loading && <Spinner size="normal" color="dark" />}
            {!cookiesApi.loading && (
              <div className="overflow-hidden rounded-md pt-[10px] flex">
                <Icon icon={IconsTypes.Pdf} size="24" className="fill-blue-900 w-4 h-4" />
                <a
                  className="text-blue-900 max-w-[250px] text-ellipsis overflow-hidden ... hover:break-all"
                  target="_blank"
                  href={settings?.app_settings?.cookies?.value}
                >
                  {handleFileName(settings?.app_settings?.cookies?.value || '')}
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditDocuments;
