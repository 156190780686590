import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import {
  AppRoutes,
  ButtonSize,
  ButtonStyle,
  ButtonTypes,
  DefaultPageProps,
  ErorFlag,
  MessageStyle,
} from '../../utils/props';
import useApi from '../../hooks/use-api';
import Message from '../../components/Message/Message';
import CustomImg from '../../components/CustomImg/CustomImg';
import PageTitle from '../../components/PageTitle';
import { useTranslation } from 'react-i18next';

const ResetPassword: React.FC<DefaultPageProps> = ({ settings }) => {
  const [password, setPassword] = useState<string>('');
  const [passwordRepeat, setPasswordRepeat] = useState<string>('');
  const [error, setError] = useState<string>('');
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const { t } = useTranslation('ns');
  const resetPasswordApi = useApi({
    path: `change_password/${params.get('token')}`,
    callback: () => {
      navigate(AppRoutes.Login);
    },
  });

  const onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    if (password.length < 6) {
      setError(t('password-length-error'));
    } else if (password !== passwordRepeat) {
      setError(t('password-match-error'));
    } else {
      resetPasswordApi.post({
        new_password: password,
      });
    }
  };

  return (
    <div className="lg:flex block">
      <CustomImg
        large={settings?.app_settings?.authentication_image?.large}
        mobile={settings?.app_settings?.authentication_image?.mobile}
        className="lg:w-3/5 lg:h-auto min-h-60 h-auto"
        alt="This is the main image"
      />
      <div className="flex justify-center w-full lg:w-2/5 p-4 lg:p-12 xl:p-16">
        <div className="w-full">
          <PageTitle
            title={t('password-reset')}
            description={settings?.app_settings?.reset_password_paragraph?.value}
          />
          {resetPasswordApi.errors && (
            <Message type={MessageStyle.Error} onCloseFunction={resetPasswordApi.closeMessages} className="mb-6">
              {resetPasswordApi.errors}
            </Message>
          )}
          {error && (
            <Message type={MessageStyle.Error} onCloseFunction={resetPasswordApi.closeMessages} className="mb-6">
              {error}
            </Message>
          )}
          <form onSubmit={onSubmit} className="flex flex-col gap-6">
            <Input
              type="password"
              value={password}
              placeholder={t('the-password')}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              title={t('the-password')}
              label={t('the-password')}
              hasError={error ? ErorFlag.DontDisaplyErrorText : ''}
            />
            <Input
              type="password"
              value={passwordRepeat}
              placeholder={t('password-repeat')}
              onChange={(e) => {
                setPasswordRepeat(e.target.value);
              }}
              title={t('password-repeat')}
              label={t('password-repeat')}
              hasError={error ? ErorFlag.DontDisaplyErrorText : ''}
            />

            <Button
              size={ButtonSize.LargeFull}
              variant={ButtonStyle.Primary}
              loading={resetPasswordApi.loading}
              title={t('account-enter')}
              type={ButtonTypes.Submit}
              className="mb-6"
            >
              {t('password-reset')}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
