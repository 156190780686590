import { Menu, Transition } from '@headlessui/react';
import React, { Fragment, SetStateAction, useMemo, useState } from 'react';
import moment from 'moment';
import {
  ButtonSize,
  ButtonStyle,
  ConsumersTableFilters,
  IconsTypes,
  Months,
  ReactSelectValue,
  TransactionsTableFilters,
} from '../../utils/props';
import { prepareNumberForData, convertToUTCstart, convertToUTCend } from '../../utils/utils';
import Chip from '../Chip';
import Icon from '../Icon/Icon';
import CustomSelect from '../CustomSelect';
import Button from '../Button/Button';
import { useTranslation } from 'react-i18next';

export default function DateSelection({
  name,
  label,
  filters,
  setFilters,
}: {
  name: string;
  label: string;
  filters?: ConsumersTableFilters | TransactionsTableFilters;
  setFilters:
    | React.Dispatch<SetStateAction<ConsumersTableFilters>>
    | React.Dispatch<SetStateAction<TransactionsTableFilters>>;
}) {
  const [day, setDay] = useState<{ label: string; value: number } | null | undefined>(undefined);
  const [month, setMonth] = useState<{ label: string; value: number } | null | undefined>(undefined);
  const [year, setYear] = useState<{ label: string; value: number } | null | undefined>(undefined);

  // years from 1970 to current year
  const years = useMemo(
    () =>
      Array.from({ length: new Date().getFullYear() - 1970 + 1 }, (_, i) => ({
        label: (1970 + i).toString(),
        value: 1970 + i,
      }))?.reverse(),
    []
  );

  // months from 1 to 12 (january to december)
  const months = useMemo(
    () =>
      !year
        ? []
        : Months?.map((month, i) => ({
            label: month,
            value: i + 1,
          })),
    [year]
  );

  const getDaysForMonth = (year?: number, month?: number) => {
    if (!month || !year) {
      return [];
    }

    const daysInMonth = new Date(year, month, 0).getDate();

    return Array.from({ length: daysInMonth }, (_, i) => ({
      label: (i + 1).toString(),
      value: i + 1,
    }));
  };

  const days = useMemo(() => {
    return getDaysForMonth(year?.value, month?.value);
  }, [month, year]);

  const onSelect = (name: string, value: ReactSelectValue<number>) => {
    switch (name) {
      case 'day':
        setDay(value);
        break;
      case 'month':
        setMonth(value);

        if (day) {
          const daysInMonth = getDaysForMonth(year?.value, value?.value);

          if (daysInMonth.length < day?.value) {
            setDay(daysInMonth[daysInMonth.length - 1]);
          }
        }

        break;
      case 'year':
        setYear(value);

        if (day) {
          const daysInMonth = getDaysForMonth(value?.value, month?.value);

          if (daysInMonth.length < day?.value) {
            setDay(daysInMonth[daysInMonth.length - 1]);
          }
        }
        break;
    }
  };

  const resetDateSelection = (e: { stopPropagation: () => void; preventDefault: () => void }) => {
    e.stopPropagation();
    e.preventDefault();

    setFilters((prev: any) => ({
      ...prev,
      joined: undefined,
      created_dt: undefined,
    }));
    setDay(null);
    setMonth(null);
    setYear(null);
  };

  const selectedDate = useMemo(() => {
    if (!day && !month) {
      return year?.value;
    }

    if (!day) {
      return `${months?.find((m) => m.value === month?.value)?.label} ${year?.value}`;
    }

    return moment(`${year?.value}-${month?.value}-${day?.value}`, 'YYYY-MM-DD').format('DD.MM.YYYY');
  }, [(filters as ConsumersTableFilters)?.joined, (filters as TransactionsTableFilters)?.created_dt, day, month, year]);

  const getSelectedDateRange = () => {
    const templateFormat = 'YYYY-MM-DD';
    if (!day && !month) {
      return {
        min: convertToUTCstart(`${year?.value}-01-01`, templateFormat),
        max: convertToUTCstart(`${year?.value}-12-31`, templateFormat),
      };
    }

    if (!day) {
      // get last day of the month
      const daysInMonth = getDaysForMonth(year?.value, month?.value);

      return {
        min: convertToUTCstart(`${year?.value}-${prepareNumberForData(month?.value)}-01`, templateFormat),
        max:  convertToUTCstart(`${year?.value}-${prepareNumberForData(month?.value)}-${prepareNumberForData(
          daysInMonth[daysInMonth.length - 1]?.value
        )}`, templateFormat),
      };
    }
    
    return {
      min: convertToUTCstart(`${year?.value}-${prepareNumberForData(month?.value)}-${prepareNumberForData(day?.value)}`, templateFormat),
      max: convertToUTCend(`${year?.value}-${prepareNumberForData(month?.value)}-${prepareNumberForData(day?.value)}`, templateFormat),
    };
  };

  const applyDateSelection = (callback: () => void) => () => {
    const { min, max } = getSelectedDateRange();    
    setFilters((prev: any) => ({
      ...prev,
      [name]: {
        label: t('date-is-selected'),
        value: {
          min,
          max
        },
      },
    }));
    callback();
  };

  const { t } = useTranslation('ns');

  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button className="flex items-center gap-2 rounded-md bg-white border border-gray-300 text-gray-700 px-4 text-sm h-[38px] w-[200px] justify-between focus:outline-none focus:ring-0">
        {(filters as ConsumersTableFilters)?.joined || (filters as TransactionsTableFilters)?.created_dt ? (
          <Chip>
            {selectedDate}
            <button type="button" onClick={resetDateSelection}>
              <Icon
                icon={IconsTypes.Exit}
                size="16"
                className="h-4 w-4 text-gray-400 fill-gray-400"
                aria-hidden="true"
              />
            </button>
          </Chip>
        ) : (
          label
        )}
        <Icon icon={IconsTypes.Chevron} size="16" className="h-4 w-4 text-gray-400 fill-gray-400" aria-hidden="true" />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ close }) => (
                <div
                  className="rounded-md bg-white p-4 text-sm flex gap-2 items-center w-full"
                  onClick={(e) => {
                    // Prevents the Menu from closing
                    e.preventDefault();
                  }}
                >
                  <CustomSelect
                    isClearable
                    noOptionsMessage={t('choose-month')}
                    placeholder={t('choose-day')}
                    className="w-[132px]"
                    hasBorder
                    name="day"
                    onSelect={(name, value) => {
                      onSelect(name, value as ReactSelectValue<number>);
                    }}
                    options={days}
                    value={day}
                  />
                  <CustomSelect
                    isClearable
                    noOptionsMessage={t('choose-year')}
                    placeholder={t('choose-month')}
                    className="w-[170px]"
                    hasBorder
                    name="month"
                    onSelect={(name, value) => {
                      onSelect(name, value as ReactSelectValue<number>);
                    }}
                    options={months}
                    value={month}
                  />
                  <CustomSelect
                    placeholder={t('choose-year')}
                    className="w-[132px]"
                    hasBorder
                    name="year"
                    onSelect={(name, value) => {
                      onSelect(name, value as ReactSelectValue<number>);
                    }}
                    options={years}
                    value={year}
                  />
                  <Button
                    className="h-[38px]"
                    size={ButtonSize.Small}
                    variant={ButtonStyle.Primary}
                    title={t('apply')}
                    onClick={applyDateSelection(close)}
                  >
                    {t('apply')}
                  </Button>
                </div>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
