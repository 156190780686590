import React, { useEffect, useState } from 'react';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import Message from '../../components/Message/Message';
import {
  apiCallbackFunction,
  AppRoutes,
  ButtonSize,
  ButtonStyle,
  ButtonTypes,
  ClientTypes,
  DefaultPageProps,
  DropDownOption,
  InputTypes,
  MessageStyle,
  IconsTypes,
  AceClientTypes
} from '../../utils/props';
import {
  validateEmail,
  validateField,
  validateGdpr,
  validatePass,
  validatePassConfirm,
  validateTos,
  getLabelName
} from '../../utils/utils';
import useValidation from '../../hooks/use-validation';
import useApi from '../../hooks/use-api';
import { useNavigate } from 'react-router-dom';
import CustomImg from '../../components/CustomImg/CustomImg';
import Dropdown from '../../components/Dropdown/Dropdown';
import Checkbox from '../../components/Checkbox/Checkbox';
import PageTitle from '../../components/PageTitle';
import Icon from '../../components/Icon/Icon';
import { useTranslation } from 'react-i18next';

const RegisterPage: React.FC<DefaultPageProps> = ({ settings }) => {
  const [clientTypeList, setClientTypeList] = useState<DropDownOption[]>([]);
  const [gdprValue, setGdprValue] = useState<boolean>(false);
  const [gdprError, setGdprError] = useState<string>('');
  const [tosValue, setTosValue] = useState<boolean>(false);
  const [tosError, setTosError] = useState<string>('');
  const { t } = useTranslation('ns');
  const createUser = useApi({
    path: '/users',
    defaultSuccessMessage: t('account-create-successful'),
    callback: () => {
      clearInputs();
    },
  });

  const navigate = useNavigate();

  const apiCall: apiCallbackFunction = () => {
    if (!gdprValue) {
      setGdprError(validateGdpr(gdprValue));
    }
    if (!tosValue) {
      setTosError(validateTos(tosValue));
    }

    if (!gdprValue || !tosValue) {
      return;
    }
    createUser.post({
      name: inputs.user,
      mail: inputs.email,
      key: inputs.pass,
      auth_method: 0,
      invoicing_code: Number(inputs.code),
      invoice_id: Number(inputs.invoice_id),
      ace_client_type: Number(inputs.clientType),
      gdpr_consent: 1,
      tos_consent: 1,
    });
  };

  const { inputs, errors, updateInputs, handleFormSubmit, clearInputs } = useValidation(
    {
      user: validateField,
      code: validateField,
      invoice_id: validateField,
      email: validateEmail,
      pass: validatePass,
      clientType: (value) => {
        if (value === '') {
          return t('register-account.mandatory-field');
        }
        return '';
      },
      passConfirm: validatePassConfirm,
    },
    apiCall
  );

  useEffect(() => {
    if (settings?.ace_client_types) {
      const object = settings.ace_client_types;
      const clientTypesArray: { label: string; value: string }[] = Object.keys(object).map((key: string) => {
        const label: string = ClientTypes[key];
        const value: string = object[key].toString();
        return {
          label,
          value,
        };
      });
      const dIndex = clientTypesArray.findIndex((ctype) => ctype.label === ClientTypes.CASE_PARTICULARE);
      if (dIndex !== 0) {
        const valueToAdd = clientTypesArray[dIndex];
        clientTypesArray.splice(dIndex, 1);
        clientTypesArray.unshift(valueToAdd);
      }
      setClientTypeList(clientTypesArray);
    }
  }, [settings]);

  return (
    <div className="lg:flex block">
      <CustomImg
        large={settings?.app_settings?.authentication_image?.large}
        mobile={settings?.app_settings?.authentication_image?.mobile}
        className="md:w-3/5 md:h-auto min-h-60 h-auto"
        alt="This is the main image"
      />
      <div className="flex justify-center w-full lg:w-2/5 p-4 lg:p-12 xl:p-16">
        <div className="w-full">
          <PageTitle
            title={t('register-account.create-account')}
            description={settings?.app_settings?.register_paragraph?.value}
          />
          <form className="grid gap-y-5" onSubmit={handleFormSubmit} noValidate>
            <Input
              type={InputTypes.Number}
              value={inputs.code}
              placeholder={t('register-account.subscriber-code-placeholder')}
              onChange={(e) => updateInputs('code', e.target.value)}
              title={t('subscriber-code')}
              label={t('subscriber-code')}
              hasError={errors.code}
              isMandatory
            />

            <Input
              type={InputTypes.Number}
              value={inputs.invoice_id}
              placeholder={t('invoice-code-placeholder')}
              onChange={(e) => updateInputs('invoice_id', e.target.value)}
              title={t('invoice-number')}
              label={t('invoice-number')}
              hasError={errors.invoice_id}
              isMandatory
            />
            <Dropdown
              options={clientTypeList}
              value={inputs.clientType}
              onChange={(value) => {
                updateInputs('clientType', value);
              }}
              wrapperClassName="block"
              labelClassName="text-left"
              label={t('subscriber-type')}
              noInitialValue
              placeholder={t('select-client-type')}
              hasError={errors.clientType}
              large
              isMandatory
            />
            <Input
              isMandatory
              type={InputTypes.Text}
              value={inputs.user}
              placeholder={getLabelName('placeholder', inputs.clientType)}
              onChange={(e) => updateInputs('user', e.target.value)}
              title={getLabelName('name', inputs.clientType)}
              label={getLabelName('name', inputs.clientType)}
              hasError={errors.user}
            />
            <Input
              type={InputTypes.Email}
              value={inputs.email}
              placeholder={t('email-placeholder')}
              onChange={(e) => updateInputs('email', e.target.value)}
              title={t('email-address')}
              label={t('email-address')}
              hasError={errors.email}
              isMandatory
            />
            <Input
              type={InputTypes.Password}
              value={inputs.pass}
              placeholder={t('the-password')}
              onChange={(e) => updateInputs('pass', e.target.value)}
              title={t('password')}
              label={t('password')}
              hasError={errors.pass}
              isMandatory
            />
            <Input
              type={InputTypes.Password}
              value={inputs.passConfirm}
              placeholder={t('the-password')}
              onChange={(e) => updateInputs('passConfirm', e.target.value)}
              title={t('password-confirm')}
              label={t('password-confirm')}
              hasError={errors.passConfirm}
              isMandatory
            />
            <div className="flex flex-wrap">
              <Checkbox
                value={tosValue}
                onChange={(newValue: boolean) => {
                  setTosValue(newValue);
                  setTosError('');
                }}
                label={t('agree-with')}
                hasError={tosError}
                className="whitespace-nowrap"
              />{' '}
              <span>
                {settings?.app_settings?.gdpr && (
                  <a
                    href={settings?.app_settings?.tos?.value}
                    className="px-1 font-bold text-blue-900 cursor-pointer whitespace-nowrap flex"
                  >
                    {t('the-terms-and-conditions')}
                  </a>
                )}
              </span>
              <span>
                <Icon icon={IconsTypes.Mandatory} size="6" className="fill-red-800 w-1.5 h-1.5" />
              </span>
            </div>
            <div className="flex flex-wrap">
              <Checkbox
                value={gdprValue}
                onChange={(newValue: boolean) => {
                  setGdprValue(newValue);
                  setGdprError('');
                }}
                label={t('agree-with')}
                hasError={gdprError}
                className="whitespace-nowrap"
              />{' '}
              {settings?.app_settings?.gdpr && (
                <a
                  href={settings?.app_settings?.gdpr?.value}
                  className="px-1 font-bold text-blue-900 cursor-pointer whitespace-nowrap"
                >
                  {t('gdpr-policy')}
                </a>
              )}
              <span>
                <Icon icon={IconsTypes.Mandatory} size="6" className="fill-red-800 w-1.5 h-1.5" />
              </span>
            </div>
            {tosError && (
              <Message
                type={MessageStyle.Error}
                onCloseFunction={() => {
                  setTosError('');
                }}
              >
                {tosError}
              </Message>
            )}
            {gdprError && (
              <Message
                type={MessageStyle.Error}
                onCloseFunction={() => {
                  setGdprError('');
                }}
              >
                {gdprError}
              </Message>
            )}
            {createUser.errors && (
              <Message type={MessageStyle.Error} onCloseFunction={createUser.closeMessages}>
                {createUser.errors}
              </Message>
            )}
            {createUser.successMessage && (
              <Message type={MessageStyle.Info} onCloseFunction={createUser.closeMessages}>
                {createUser.successMessage}
              </Message>
            )}
            <Button
              size={ButtonSize.LargeFull}
              variant={ButtonStyle.Primary}
              title={t('register-account.create-account')}
              className="text-lg"
              type={ButtonTypes.Submit}
              loading={createUser.loading}

            >
              {t('register-account.create-account')}
            </Button>
            <div className="flex items-center">
              <div className="h-0 grow border-b border-gray-300" />
              <div className="px-2 text-gray-500 text-base">{t('or')}</div>
              <div className="h-0 grow border-b border-gray-300" />
            </div>
            <Button
              size={ButtonSize.LargeFull}
              variant={ButtonStyle.Secondary}
              title={t('go-back-auth')}
              className="mb-6 text-lg"
              onClick={() => {
                navigate(AppRoutes.Login);
              }}
            >
              {t('go-back-auth')}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
