import Spinner from './Spinner';

const LoadingOverlay = () => {
  return (
    <div className='h-full flex-wrap flex justify-center content-center'>
        <Spinner size="normal" color="dark"/> 
    </div>
  );
};

export default LoadingOverlay;
