import React from 'react';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import {
  ButtonSize,
  ButtonStyle,
  InputTypes,
  CounterTypes,
  IconsTypes,
  SendIndexTableProps,
  MessageStyle,
  ErorFlag,
} from '../../utils/props';
import Icon from '../../components/Icon/Icon';
import tailwindClassNames from '../../utils/tailwindClassNames';
import Message from '../../components/Message/Message';
import { useTranslation } from 'react-i18next';

const SendIndexTableMobile: React.FC<SendIndexTableProps> = ({
  countersData,
  editCounterLocation,
  counterLocation,
  sendIndex,
  loading,
  saveCounterLocation,
  setEditCounterLocation,
  setCounterLocation,
  loadingLocation,
  invoice_period,
  updateIndexValue,
  errorIndex,
  validationError,
}) => {
  const { t } = useTranslation('ns');
  return (
    <div className="xl:hidden block">
      {countersData.map((counter: CounterTypes, index: number) => (
        <div key={`${counter.counter_id}_mobile`} className="border-t border-t-gray-300 my-4 w-full">
          <div className="w-full flex p-3">
            <div className="w-1/2">{t('consumer-series')}</div>
            <div className="w-1/2">{counter.serial_number}</div>
          </div>
          <div className={tailwindClassNames('w-full flex p-3 flex-wrap bg-gray-50 ')}>
            <div className="w-1/2">{t('consumer-location')}</div>
            <div className={tailwindClassNames('w-1/2 flex')}>
              <Icon
                icon={IconsTypes.Edit}
                size={16}
                className="fill-blue-900 w-4 h-4 mr-1 mt-0.5 cursor-pointer"
                onClick={() => {
                  setEditCounterLocation(index);
                  setCounterLocation(counter.label);
                }}
              />
              {counter.label}
            </div>
            <div className={tailwindClassNames('w-full ', editCounterLocation === index ? 'py-3' : 'py-0')}>
              {editCounterLocation === index && (
                <div className="">
                  <Input
                    type={InputTypes.Text}
                    onChange={(e) => {
                      setCounterLocation(e.target.value);
                    }}
                    wrapperClass="w-full"
                    value={counterLocation || ''}
                  />
                  <div className="my-3 border-t border-t-gray-300" />
                  <Button
                    size={ButtonSize.DefaultFull}
                    variant={ButtonStyle.SafePrimary}
                    loading={loadingLocation}
                    onClick={() => {
                      saveCounterLocation(counter.id);
                    }}
                  >
                    {t('save')}
                  </Button>
                </div>
              )}
            </div>
          </div>
          <div className="w-full flex p-3">
            <div className="w-1/2">{t('index-old')}</div>
            <div className="w-1/2">{counter.old_index}</div>
          </div>
          <div className="p-3 bg-gray-50">
            <div className="w-full pb-5">{t('index-new')}</div>
            <div className="w-full">
              <Input
                type={InputTypes.Number}
                onChange={(e) => {
                  updateIndexValue(counter.id, Number(e.target.value));
                }}
                value={counter.new_index || ''}
                unit="mc"
                disabled={!invoice_period || invoice_period === 0}
                hasError={errorIndex === counter.id ? ErorFlag.DontDisaplyErrorText : ''}
                unitPosition="top-[9px]"
              />
            </div>
            <Button
              size={ButtonSize.DefaultFull}
              variant={ButtonStyle.Primary}
              loading={loading}
              onClick={() => {
                sendIndex(counter.id);
              }}
              disabled={!invoice_period || invoice_period === 0}
            >
              {t('send')}
            </Button>
          </div>
          <div className="w-full flex p-3">
            <div className="w-1/2">{t('consumer-total')}</div>
            <div className="w-1/2">
              {counter.new_index ? Math.round((Number(counter.new_index) - Number(counter.old_index)) * 100) / 100 : 0}{' '}
              {t('cubic-meters')}
            </div>
          </div>
          <div className="w-full flex p-3 bg-gray-50">
            <div className="w-1/2">{t('last-update')}</div>
            <div className="w-1/2">{counter.new_date ? counter.new_date.split(' ')[0] : ''}</div>
          </div>
          {validationError && errorIndex == counter.id && (
            <Message type={MessageStyle.Error} className="my-3">
              {validationError}
            </Message>
          )}
        </div>
      ))}
    </div>
  );
};

export default SendIndexTableMobile;
