import { ComponentType, Dispatch, ReactNode, SetStateAction } from 'react';
import { SingleValue, StylesConfig } from 'react-select';
import i18n from 'i18next';

export type LoginPageProps = {
  settings: SettingsType;
  getSettingsAfterLogin: (userType: string) => void;
  updateUserType: (id: string) => void;
};

export type ConsentPageProps = {
  settings: SettingsType;
  getSettingsAfterUpdate: (userType: string) => void;
};

export enum ButtonSize {
  Default = 'default',
  DefaultFull = 'defaultFull',
  Large = 'large',
  LargeFull = 'largeFull',
  Small = 'small',
  SmallFixed = 'smallFixed',
  SmallFull = 'smallFull',
}

export enum ButtonStyle {
  Primary = 'primary',
  Secondary = 'secondary',
  SafePrimary = 'safePrimary',
  SafeSecundary = 'safeSecundary',
  DangerPrimary = 'dangerPrimary',
  DangerSecundary = 'dangerSecundary',
}

export enum ButtonTypes {
  Submit = 'submit',
  Button = 'button',
}

export type ButtonProps = {
  type?: 'button' | 'submit';
  className?: string;
  children?: ReactNode | string | null;
  href?: string;
  size: ButtonSize;
  variant: ButtonStyle;
  onClick?: () => void;
  title?: string;
  disabled?: boolean;
  loading?: boolean;
  labelClass?: string;
};

export type PopupProps = {
  onOk?: () => void;
  onClose?: () => void;
  cancelText?: string;
  okText?: string;
  hideButtons?: boolean;
  title?: string;
  children?: ReactNode | string | null;
  loading?: boolean;
  disableOk?: boolean;
};

export type SpinnerProps = {
  size: 'small' | 'normal';
  color: 'dark' | 'light';
};

export type InputProps = {
  name?: string;
  type?: string;
  className?: string;
  value?: string | number;
  placeholder?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  title?: string;
  label?: string;
  readOnly?: boolean;
  disabled?: boolean;
  hasError?: string;
  wrapperClass?: string;
  unit?: string | ReactNode;
  labelClass?: string;
  right?: boolean;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  unitPosition?: string;
  renderLeftIcon?: () => ReactNode;
  isMandatory?: boolean;
};

export enum MessageStyle {
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
  Info = 'info',
}

export type MessageProps = {
  type: MessageStyle;
  children?: React.ReactNode | string | null;
  className?: string;
  autoclose?: boolean;
  onCloseFunction?: () => void;
};

export enum InputTypes {
  Password = 'password',
  Text = 'text',
  Phone = 'tel',
  Email = 'email',
  Number = 'number',
}

export enum ConsentErrors {
  Tos = 'tos',
  Gdpr = 'gdpr',
}

export enum ApiStatus {
  Ok = 'ok',
}

export type apiCallbackFunction = (fn: clearInputsFunction) => void;
export type clearInputsFunction = () => void;
export type inputValidationFunction = (s1: string, s2?: string) => string;

export interface TokenPayload {
  name: string;
  exp: number;
  iat: number;
}

export type SettingsType = {
  app_settings: {
    gdpr: {
      value: string;
      id: number;
    };
    login_image: {
      mobile: string;
      large: string;
      id: number;
    };
    login_paragraph: {
      value: string;
      id: number;
    };
    authentication_image: {
      mobile: string;
      large: string;
      id: number;
    };
    register_paragraph: {
      value: string;
      id: number;
    };
    tos: {
      value: string;
      id: number;
    };
    cookies: {
      value: string;
      id: number;
    };
    recover_password_paragraph: {
      value: string;
      id: number;
    };
    invoices_paragraph: {
      value: string;
      id: number;
    };
    recover_account_paragraph: {
      value: string;
      id: number;
    };
    manage_contracts_paragraph: {
      value: string;
      id: number;
    };
    reset_password_paragraph: {
      value: string;
      id: number;
    };
    sent_invoices_paragraph: {
      value: string;
      id: number;
    };
    settings_paragraph: {
      value: string;
      id: number;
    };
    terms_and_conditions_paragraph: {
      value: string;
      id: number;
    };
    update_counter_paragraph: {
      value: string;
      id: number;
    };
    gdpr_update_paragraph: {
      value: string;
      id: number;
    };
    confirm_account_delete_message: {
      value: string;
      id: number;
    };
    counter_period_url: {
      value: string;
      id: number;
    };
  };
  ace_client_types?: { [key: string]: number };
  counter_period_url: string;
  user_types?: {
    ADMIN: number;
    NORMAL_USER: number;
  };
  payment_statuses: {
    CREATED: number;
    SENT_TO_BTPAY: number;
    FINALIZED: number;
  };
};

// TODO - Instead of { [key: string]: number } in app_settings.ace_client_types, this enum should be used...
export enum AceClientTypes {
  LOCATAR_BLOC = 0,
  AGENT_ECONOMIC = 1,
  ASOCIATIE_PROPRIETARI = 4,
  CASE_PARTICULARE = 5,
}

export type DropDownOption = {
  value: string | number;
  label: string;
  data?: string;
};

export type DropdownProps = {
  value: string;
  options?: DropDownOption[];
  onChange: (selectedValue: string) => void;
  className?: string;
  label?: string;
  hasError?: string;
  wrapperClassName?: string;
  labelClassName?: string;
  large?: boolean;
  noInitialValue?: boolean;
  placeholder?: string;
  onBlur?: () => void;
  isMandatory?: boolean;
};

export type CustomImgProps = {
  mobile?: string;
  tablet?: string;
  large: string;
  className?: string;
  mobileClassName?: string;
  tabletClassName?: string;
  largeClassName?: string;
  alt?: string;
  loading?: 'eager' | 'lazy';
};

export type CheckboxProps = {
  value: boolean;
  onChange: (newValue: boolean) => void;
  label?: string;
  className?: string;
  disabled?: boolean;
  hasError?: boolean | string;
  classNameWrapper?: string;
};

export enum ErorFlag {
  DontDisaplyErrorText = '_',
}

export enum AppRoutes {
  ResetPassword = '/contul-meu/change-password',
  RecoverPassword = '/contul-meu/recupereaza-parola',
  Login = '/contul-meu/login',
  Register = '/contul-meu/register',
  Invoices = '/contul-meu/lista-facturi',
  DeactivateInvoice = '/contul-meu/confirma-dezactivarea-facturii',
  ManageContracts = '/contul-meu/gestionare-contracte',
  Settings = '/contul-meu/setari',
  Situation = '/contul-meu/situatie-contracte',
  SendIndex = '/contul-meu/trimite-index',
  ConsentGDPR = '/contul-meu/acord-gdpr',
  ConsentTOS = '/contul-meu/acord-tos',
  Verify = '/contul-meu/verify',
  DeleteConfirmation = '/contul-meu/confirma-stergerea-contului',
  RecoverAccount = '/contul-meu/recover',
  ChangeEmail = '/contul-meu/change-email',
  Administration = '/contul-meu/administrare-cab-app/setari',
  AdministrationUsers = '/contul-meu/administrare-cab-app/utilizatori',
  AdministrationIndexes = '/contul-meu/administrare-cab-app/indecsi',
  AdministrationTransactions = '/contul-meu/administrare-cab-app/tranzactii',
  Error = '/contul-meu/*',
}

export type InvoiceTypes = {
  ace_invoice_id: string;
  generation_number: string;
  id: number;
  invoiced_at: string;
  invoice_total: string;
  paid_amount: string;
  remaining_amount: string;
  download_url: string;
};

export interface InvoiceTableProps {
  invoiceList: InvoiceTypes[];
  selectedRows: string[];
  selectItem: (item: string, id: number, value: boolean) => void;
  toggleSelectAll: () => void;
  viewInvoice: (id: string) => void;
}

export interface ContractType {
  label: string;
  value: string | number;
}

export interface SituationItem {
  new_index: string;
  old_index: string;
  new_date: string;
  old_date: string;
  value: string;
  type: string;
}

export interface SituationTable {
  date: number;
  values: SituationItem[];
}

export enum dates {
  StartDropdownDate = '01/01/2000',
}

export type InvoicePageProps = {
  currentLocation?: string;
  settings: SettingsType;
};

export enum IconsTypes {
  DashedEyeFull = 'dashedEye-full',
  EyeFull = 'eye-full',
  Danger = 'danger',
  Menu = 'menu',
  Facturi = 'facturi',
  Pen = 'pen',
  ChartPie = 'chartPie',
  Settings = 'settings',
  Folder = 'folder',
  Pay = 'pay',
  Download = 'download',
  Logout = 'logout',
  Edit = 'edit',
  Exit = 'exit',
  Admin = 'admin',
  MenuClose = 'menuClose',
  exitCircle = 'exitCircle',
  MenuOpen = 'menuOpen',
  inactiveOrder = 'inactiveOrder',
  Success = 'success',
  Check = 'Check',
  Home = 'home',
  Search = 'search-icon',
  Download2 = 'download-icon',
  Chevron = 'chevron-down-icon',
  MinusCircle = 'minus-circle-icon',
  Trash = 'trash-icon',
  Dots = 'dots-icon',
  PencilAlt = 'pencil-alt-icon',
  ArrowNarrowRight = 'arrow-narrow-right-icon',
  ArrowNarrowLeft = 'arrow-narrow-left-icon',
  Mandatory = 'mandatory',
  Presentation = 'Presentation-chart-bar',
  Clipboard = 'Clipboard-list',
  Tranzaction = 'Icon-Stroketranzactii',
  Pdf = 'pdf',
}

export type InvoicesApiResponseData = {
  invoices: InvoiceTypes[];
  message: string;
  status: string;
  total_payment: number;
  awaiting_payment: number;
  latest_unpaid_invoice_id: number | null;
};

export type ConsumerType = {
  ace_client_id: number;
  ace_client_type: string;
  address: string;
  address_extra?: string;
  contract_name: string;
  created_dt: string;
  deleted: number;
  id: number;
  invoicing_code: number;
  updated_dt: string;
  user_id: number;
  alias: string;
};

export type ConsumersApiType = {
  consumers: ConsumerType[];
  message: string;
  status: string;
};

export type BreadcrumbItemsProps = {
  consumers?: ConsumersApiType | null;
  updateCurrentLocation?: (value: string) => void;
  displayConsumers: boolean;
  userType: string;
  settings: SettingsType;
};

export type MenuItemPropertis = {
  className?: string;
  onClick?: () => void;
};
export type MenuProps = {
  userType?: string;
  settings: SettingsType;
};

export type SectionHeadersProps = {
  action: (value: boolean) => void;
  className?: string;
  isInEdit?: boolean;
  title?: string;
  buttonLabel: string;
  activTitle?: string;
  danger?: boolean;
  buttonIcon?: string;
  success?: boolean;
  children?: ReactNode | string | null;
  updateSettings: () => void;
};

export type SettingApiType = {
  birthdate?: string;
  created_dt?: string;
  first_name?: string;
  id?: number;
  joined?: string;
  landline?: string;
  mail?: string;
  mail_verified?: number;
  message?: string;
  phone?: string;
  photo?: string;
  status?: string;
  type?: number;
  updated_dt?: string;
  online_invoice: number;
  name?: string;
};

export type SettingsAccountDataProps = {
  editMode: boolean;
  data?: SettingApiType | null;
  user_id: string;
  openEditMode: () => void;
};

export type SettingsAccountPasswordProps = {
  editMode: boolean;
};

export const Months: string[] = [
  i18n.t('ns:months.january'),
  i18n.t('ns:months.february'),
  i18n.t('ns:months.march'),
  i18n.t('ns:months.april'),
  i18n.t('ns:months.may'),
  i18n.t('ns:months.june'),
  i18n.t('ns:months.july'),
  i18n.t('ns:months.august'),
  i18n.t('ns:months.september'),
  i18n.t('ns:months.october'),
  i18n.t('ns:months.november'),
  i18n.t('ns:months.december'),
];

export type ConsumersTableDataProps = {
  consumer: ConsumerType;
  deleteConsumer: (contract: number) => void;
};
export type AddContractProps = {
  close: () => void;
  updateSuccessAddMesage: Dispatch<SetStateAction<string>>;
} & DefaultPageProps;

export type OnlineInvoiceProps = {
  data?: SettingApiType | null;
  user_id: string;
  updateSettings: () => void;
  openConfirmation: boolean;
  success?: string;
  error?: string;
  setOpenConfirmation: (opened: boolean) => void;
  updateOnlineInvoice: (value: number) => void;
  closeMessage: () => void;
  activate: () => void;
};

export type SendIndexProps = {
  currentLocation: string;
  invoice_period: number;
  settings: SettingsType;
};

export type CounterTypes = {
  counter_id: number;
  location_address: string;
  location_id: number;
  location_name: string;
  new_date: string;
  new_index: number;
  old_date: string;
  old_index: string;
  serial_number: string;
  type: string;
  value: number;
  label: string;
  last_updated_by_user: string;
  id: number;
};

export type SendIndexTableProps = {
  countersData: CounterTypes[];
  validationError: string;
  editCounterLocation: number;
  counterLocation: string;
  loading?: boolean;
  loadingLocation?: boolean;
  sendIndex: (id: number) => void;
  saveCounterLocation: (id: number) => void;
  setEditCounterLocation: Dispatch<SetStateAction<number>>;
  setCounterLocation: Dispatch<SetStateAction<string>>;
  invoice_period: number;
  updateIndexValue: (id: number, value: number) => void;
  errorIndex?: number;
};

export const predifenedErrorMessageForGdpr = 'gdpr';
export const predifenedErrorMessageForTos = 'tos';

export type BradcrumbsStringstype = {
  [key: string]: string;
};

export const BradcrumbsStrings: BradcrumbsStringstype = {
  'change-password': i18n.t('ns:password-reset'),
  'recupereaza-parola': i18n.t('ns:password-recover'),
  login: i18n.t('ns:login'),
  register: i18n.t('ns:register'),
  'lista-facturi': i18n.t('ns:invoice-list'),
  'gestionare-contracte': i18n.t('ns:manage-contracts'),
  setari: i18n.t('ns:settings'),
  'situatie-contracte': i18n.t('ns:contracts-situation'),
  'trimite-index': i18n.t('ns:send-index-2'),
  gdpr: i18n.t('ns:gdpr'),
  verify: i18n.t('ns:verify'),
  'confirma-stergerea-contului': i18n.t('ns:account-removal-confirm'),
  recover: i18n.t('ns:account-recover'),
  'administrare-settings': i18n.t('ns:settings'),
  utilizatori: i18n.t('ns:subscriber-list'),
  indecsi: i18n.t('ns:index-report'),
  tranzactii: i18n.t('ns:transaction-history'),
};

export type ClientTypestype = {
  [key: string]: string;
};

// TODO - This should be phased out and used as an enum
export const ClientTypes: ClientTypestype = {
  AGENT_ECONOMIC: i18n.t('ns:subscriber-types.company'),
  ASOCIATIE_PROPRIETARI: i18n.t('ns:subscriber-types.hoa'),
  CASE_PARTICULARE: i18n.t('ns:subscriber-types.individual-home'),
  LOCATAR_BLOC: i18n.t('ns:subscriber-types.apartment'),
  CLIENTI_DIVERSI: i18n.t('ns:subscriber-types.various'),
};

// Cannot apply translations to this. Will need to apply translations to where the enum is used.
export enum AceClientTypesLabels {
  AGENT_ECONOMIC = 'Persoană Juridică',
  ASOCIATIE_PROPRIETARI = 'Asociație de proprietari',
  CASE_PARTICULARE = 'Utilizator casnic',
  LOCATAR_BLOC = 'Locatar blocuri',
  CLIENTI_DIVERSI = 'Clienți diverși',
}

export type PageTitleProps = {
  title?: string;
  description?: string;
  className?: string;
};

export type DefaultPageProps = {
  settings: SettingsType;
};

export type User = {
  id: number;
  mail_verified: number;
  created_dt: string;
  updated_dt: string;
  last_auth: string;
  mail: string;
  phone: string;
  online_invoice: number;
  joined: string;
  birthdate: string;
  type: number;
  name: string;
  gdpr_consent: number;
  tos_consent: number;
  online_invoice_dt: string;
  // TODO - these are consumer fields, not user fields, wait to clarify with Valentin
  invoicing_code: any;
};

export type Consumer = {
  user_id: number;
  ace_client_id: number;
  invoicing_code: number;
  unpaid_amount: number;
  alias: string;
  deleted: number;
  updated_dt: string;
  address: string;
  ace_client_type: string;
  contract_name: string;
  invoice_period: number;
  id: number;
  created_dt: string;
  counter_period: number;
  mail_verified: number;
  ip_address: string;
  phone: string;
  birthdate: string;
  online_invoice_dt: string;
  name: string;
  mail: string;
  user_agent: string;
  type: number;
  gdpr_consent_dt: string;
  tos_consent_dt: string;
  last_auth: string;
  joined: string;
  photo: string;
  gdpr_consent: number;
  tos_consent: number;
  online_invoice: number;
};

export type PageContainerProps = {
  children: ReactNode;
  className?: string;
};

export type RowRenderProps = {
  selected: boolean;
  onSelect: () => void;
  CellComponent: ComponentType<CellComponentProps>;
};

export type CellComponentProps = {
  children: ReactNode;
  className?: string;
  tooltipEnabled?: boolean;
};

export type PaginationProps = {
  items_no: number;
  current_page: number;
  items_per_page: number;
  total_pages: number;
};

export type PaginatedAPIResponse<T> = PaginationProps & {
  items: T[];
};

export type UseAPIReturnType<T> = {
  deleteApi: (id?: string | number, params?: any) => Promise<void>;
  fildsAffected: string | undefined;
  data: any | PaginatedAPIResponse<T>;
  post: (data: any) => void;
  get: (query?: string) => Promise<any>;
  closeMessages: () => void;
  loading: boolean;
  successMessage: string | undefined;
  errors: string | undefined;
  put: (data: any) => Promise<void>;
  patch: (id?: string | number, data?: any) => Promise<void>;
};

export type UseSearchReturnType<T, U> = {
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
  filters: T | undefined;
  setFilters: Dispatch<SetStateAction<T>>;
  sort: U | undefined;
  setSort: Dispatch<SetStateAction<U | undefined>>;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  numberOfResultsToDisplay: number;
  setNumberOfResultsToDisplay: Dispatch<SetStateAction<number>>;
  resetPage: () => void;
  deleteRecordCallback: () => void;
  exportToCsv: (filename?: string | null) => void;
  exporting: boolean;
};

export type PaginationPagesBarProps<T, U> = Pick<UseSearchReturnType<T, U>, 'setPage'> & {
  meta: PaginationProps;
};

export enum PaginationNavigationButtonActions {
  PREVIOUS = 'PREVIOUS',
  NEXT = 'NEXT',
}

export type SelectProps<V, U> = Omit<InputProps, 'value'> & {
  isMulti?: boolean;
  options: SingleValue<unknown>[];
  onSelect: (name: string, value: SingleValue<ReactSelectValue<V>> | U) => void;
  value?: SingleValue<ReactSelectValue<V>> | U;
  selectStyles?: StylesConfig;
  hasBorder?: boolean;
  isSearchable?: boolean;
  isClearable?: boolean;
  noOptionsMessage?: string;
};

export enum SORT_ORDER {
  ASC = 0,
  DESC = 1,
}

export type SortSelectValue = {
  sortBy: string;
  sortOrder: SORT_ORDER;
};

export type SearchComponentProps = {
  searchInputWrapperClassName?: string;
  title: string;
  search: string;
  setSearch: (search: string) => void;
};

export type ReactSelectValue<V> = {
  label: string;
  value: V;
};

export type ConsumersTableFilters = {
  ace_client_type?:
    | {
        label: AceClientTypesLabels;
        value: AceClientTypes;
      }[]
    | undefined;
  online_invoice?: {
    label: string;
    value: number;
  };
  joined?: {
    label: string;
    value: {
      min: string;
      max: string;
    };
  };
};

export enum ChipVariants {
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  INFO = 'INFO',
}

export type ChipProps = {
  variant?: ChipVariants;
  children: ReactNode;
};

export type AdministrationPageProps = {
  settings: SettingsType;
  updateSettings: () => void;
};

export interface MenuItemProps {
  label: string;
  icon: IconsTypes;
  to?: string;
  opened: boolean;
  onClick?: () => void; // Added onClick prop
  onCloseMenu?: () => void;
  iconSize?: number;
}

export type AdminImgProps = {
  large?: string;
  mobile?: string;
  largeId: number;
  mobileId: number;
  updateSettings: () => void;
};

export type AdminTosProps = {
  tos: string;
  editMode: boolean;
};

export type AdminRegisterTextProps = {
  registerText: string;
  editMode: boolean;
};

export type AdminLoginTextProps = {
  loginText: string;
  editMode: boolean;
};

export type AdminGDPRProps = {
  settings?: SettingsType;
  updateSettings: () => void;
};

export type SortProps = {
  name: string;
  sort?: SortSelectValue;
};

export type Counter = {
  location_name: string;
  location_id: number;
  new_date: string | null;
  old_date: string | null;
  type: number; // todo
  label: string;
  id: number;
  created_dt: string;
  counter_id: number;
  consumer_id: number;
  location_address: string;
  serial_number: string;
  new_index: number | null;
  old_index: number;
  last_updated_by_user: string | null;
  deleted: number;
  updated_dt: string;
  value: number;
  address: string;
  ace_client_id: number;
  invoicing_code: number;
  alias: string;
  user_id: number;
  ace_client_type: keyof ClientTypes;
  contract_name: string;
  invoice_period: number;
  counter_period_min: string;
  counter_period_max: string;
  counter_period: number;
  gdpr_consent_dt: string;
  phone: string | null;
  online_invoice_dt: string;
  mail_verified: number;
  last_auth: string;
  name: string;
  birthdate: string | null;
  photo: string | null;
  mail: string;
  joined: string;
  user_agent: string;
  ip_address: string;
  tos_consent_dt: string;
  gdpr_consent: number;
  tos_consent: number;
  online_invoice: number;
};

export type Transaction = {
  id: number;
  consumer_id: number;
  amount: number;
  order_number: string;
  rrn: string | null;
  error: string;
  btpay_order_status: string | null;
  deleted: number;
  updated_dt: string;
  currency: string;
  status: TransactionStatuses;
  btpay_order_id: string | null;
  created_dt: string;
  type: number; // todo
  address: string;
  ace_client_id: number;
  invoicing_code: number;
  alias: string;
  user_id: number;
  ace_client_type: keyof ClientTypes;
  contract_name: string;
  invoice_period: number;
  invoices: InvoiceTypes[];
  counter_period_min: string;
  counter_period_max: string;
  counter_period: number;
  gdpr_consent_dt: string;
  phone: string | null;
  online_invoice_dt: string;
  mail_verified: number;
  last_auth: string;
  name: string | null;
  birthdate: string | null;
  photo: string | null;
  mail: string;
  joined: string;
  user_agent: string;
  ip_address: string;
  tos_consent_dt: string;
  gdpr_consent: number;
  tos_consent: number;
  online_invoice: number;
};

export enum TransactionStatuses {
  CREATED = 'CREATED',
  SENT_TO_BTPAY = 'SENT_TO_BTPAY',
  FINALIZED = 'FINALIZED',
  ERROR = 'ERROR',
}

export enum TransactionStatusLabels {
  CREATED = 'Creat',
  SENT_TO_BTPAY = 'Trimis',
  FINALIZED = 'Finalizat',
  NOT_APPLICABLE = 'N/A',
  ERROR = 'Eșuat',
}

export type TransactionsTableFilters = {
  status?: {
    label: TransactionStatusLabels;
    value: TransactionStatuses;
  };
  created_dt?: {
    label: string;
    value: {
      min: string;
      max: string;
    };
  };
};
