import React, { useEffect, useReducer, useRef, useState } from 'react';
import Dropdown from '../../components/Dropdown/Dropdown';
import InvoiceTableWeb from './InvoiceTableWeb';
import InvoiceTableMobile from './InvoiceTableMobile';
import {
  dates,
  DropDownOption,
  InvoicePageProps,
  InvoicesApiResponseData,
  InvoiceTypes,
  MessageStyle,
} from '../../utils/props';
import { generatePeriodList, isLogged, viewInvoice } from '../../utils/utils';
import PayInvoices from './PayInvoices';
import PayObligations from './PayObligations';
import useApi from '../../hooks/use-api';
import Message from '../../components/Message/Message';
import Spinner from '../../components/Spinner/Spinner';
import LoadingOverlay from '../../components/Spinner/LoadingOverlay';
import PageTitle from '../../components/PageTitle';
import PageContainer from '../../components/PageContainer';
import { useTranslation } from 'react-i18next';

const InvoicesApiResponseInitialState: InvoicesApiResponseData = {
  total_payment: 0,
  awaiting_payment: 0,
  latest_unpaid_invoice_id: null,
  invoices: [],
  message: '',
  status: '',
};

const InvoicesApiResponseReducer = (state: InvoicesApiResponseData, action: any) => {
  switch (action.type) {
    case 'set':
      return { ...action.payload };
    default:
      return state;
  }
};

const Invoices: React.FC<InvoicePageProps> = ({ currentLocation, settings }) => {
  const [periodList, setPeriodList] = useState<DropDownOption[]>([]);
  const [selectedYear, setSelectedYear] = useState<string>('');
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [invoiceList, setInvoiceList] = useState<InvoiceTypes[]>([]);
  const [lastUnpaidInvoice, setLastUnpaidInvoice] = useState<number | null>(null);
  const [invoicePdfError, setInvoicePdfError] = useState<string>('');
  const [payValue, setPayValue] = useState<number>(0);
  const [data, setData] = useReducer(InvoicesApiResponseReducer, InvoicesApiResponseInitialState);
  const payInvoicesRef = useRef<HTMLDivElement>(null);
  const invoicesApi = useApi({
    path: `/invoices?year=${selectedYear}&&consumer_id=${currentLocation}`,
  });
  const { t } = useTranslation('ns');

  useEffect(() => {
    if (selectedYear && currentLocation) {
      invoicesApi.get();
    }
  }, [selectedYear, currentLocation]);

  useEffect(() => {
    if (invoicesApi?.data) {
      const data: InvoicesApiResponseData = invoicesApi.data;
      setInvoiceList(data?.invoices || []);
      setLastUnpaidInvoice(data?.latest_unpaid_invoice_id);
      setData({ type: 'set', payload: invoicesApi?.data });
    }
  }, [invoicesApi.data]);

  useEffect(() => {
    // check if this is redirection from payment process
    const params = new URLSearchParams(window.location.search);
    const payment_id: string = localStorage.getItem('payment_id') || '';

    if (params.get('approvalCode')) {
      // validate payment before getting the new list of invoices
      payValidate.post({ payment_id: JSON.parse(payment_id) });
    }

    const generatedPeriod = generatePeriodList(dates.StartDropdownDate);
    setPeriodList(generatedPeriod.yearList);
    setSelectedYear(generatedPeriod.selectedYear);
  }, []);

  const payValidate = useApi({
    path: `/payment/validate`,
    defaultSuccessMessage: t('pay-successful'),
  });

  const addObligations = (id: number | null, value: boolean) => {
    if (!id || (value && selectedIds.includes(id))) {
      // Avoid duplicated invoices
      return;
    }

    const amount = data.other_obligations;
    const updatedPayValue = parseFloat((payValue + (value ? amount : -amount)).toFixed(2));
    const updatedIds = value ? [...selectedIds, id] : selectedIds.filter((selectedId) => selectedId !== id);
    setSelectedIds(updatedIds);
    setPayValue(updatedPayValue);
  };

  const selectItem = (item: string, id: number, value: boolean) => {
    const selectedInvoice = invoiceList.find((invoice) => invoice.ace_invoice_id === item);

    if (!selectedInvoice || (value && selectedIds.includes(id))) {
      // Avoid duplicated invoices
      return;
    }

    const remainingAmount = parseFloat(selectedInvoice.remaining_amount || '0');
    const updatedPayValue = parseFloat((payValue + (value ? remainingAmount : -remainingAmount)).toFixed(2));

    const updatedRows = value 
      ? [...selectedRows, item]
      : selectedRows.filter((_, i) => i !== selectedRows.indexOf(item));
    const updatedIds = value ? [...selectedIds, id] : selectedIds.filter((selectedId) => selectedId !== id);
    const maxPayedValue = Math.max(0, Math.min(updatedPayValue, data.total_payment));

    setSelectedRows(updatedRows);
    setSelectedIds(updatedIds);
    setPayValue(maxPayedValue);
    scrollToPayInvoices();
  };

  const totalValue = () => {
    let total = 0;
    invoiceList.forEach((invoice) => {
      total += parseFloat(invoice.remaining_amount);
    });
    return parseFloat(total.toFixed(2));
  };

  const toggleSelectAll = () => {
    if (invoiceList.filter((item) => Number(item.remaining_amount) > 0).length === selectedRows.length) {
      setSelectedRows([]);
      setSelectedIds([]);
      setPayValue(0);
    } else {
      setSelectedRows(
        invoiceList.filter((item) => Number(item.remaining_amount) > 0).map((val) => val.ace_invoice_id)
      );
      setSelectedIds(
        invoiceList.filter((item) => Number(item.remaining_amount) > 0).map((val) => val.id)
      );
      setPayValue(totalValue());
      scrollToPayInvoices();
    }
  };

  const handleViewInvoice = (id: string) => {
    viewInvoice(id, currentLocation, t, setInvoicePdfError);
  };

  const setPayInputValue = (value: number) => {
    setPayValue(value);
  };

  const scrollToPayInvoices = () => {
    payInvoicesRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
    { isLogged() ? (
      <PageContainer>
        <PageTitle title={'Listă facturi'} description={settings?.app_settings?.sent_invoices_paragraph?.value} />
        <div className="flex w-full justify-between items-center mb-2 lg:mb-0">
          <h2 className="font-medium font3xl">
            {t('invoice-year')} <span className="font-bold">{selectedYear}</span>
          </h2>
          <Dropdown
            options={periodList}
            value={selectedYear}
            onChange={(value) => {
              setSelectedYear(value);
              setSelectedRows([]);
              setPayValue(0);
            }}
            label={t('year')}
          />
        </div>
        {invoicePdfError && (
          <Message
            type={MessageStyle.Error}
            className="my-6 "
            onCloseFunction={() => {
              setInvoicePdfError('');
            }}
          >
            {invoicePdfError}
          </Message>
        )}
        {invoicesApi.loading && (
          <div className="w-full py-20 flex items-center justify-center">
            <Spinner size="normal" color="dark" />
          </div>
        )}
        {!invoicesApi.loading && (
          <>
            {invoiceList?.length > 0 && (
              <>
                <InvoiceTableWeb
                  invoiceList={invoiceList}
                  selectedRows={selectedRows}
                  selectItem={selectItem}
                  toggleSelectAll={toggleSelectAll}
                  viewInvoice={handleViewInvoice}
                />
                <InvoiceTableMobile
                  invoiceList={invoiceList}
                  selectedRows={selectedRows}
                  selectItem={selectItem}
                  toggleSelectAll={toggleSelectAll}
                  viewInvoice={handleViewInvoice}
                />
              </>
            )}
            {!invoiceList?.length && <div> {t('invoice-none-generated', { selectedYear: selectedYear })}</div>}
            {data.other_obligations ? <div>
              <PayObligations
                payValue={data.other_obligations}
                selectedIds={selectedIds}
                invoiceId={lastUnpaidInvoice}
                selectItem={addObligations}/>
            </div> : null }
            <div ref={payInvoicesRef}>
              <PayInvoices
                payValue={payValue}
                invoiceIds={selectedIds}
                setPayValue={setPayInputValue}
                toggleSelectAll={toggleSelectAll}
                lastUnpaidInvoice={lastUnpaidInvoice}
                currentLocation={currentLocation}
                totalPayment={data.total_payment}
                awaitingPayment={data.awaiting_payment}
                payValidate={payValidate}
              />
            </div>
          </>
        )}
      </PageContainer>) :
      <LoadingOverlay />}
    </>
  );
};

export default Invoices;
