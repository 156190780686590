import { useEffect, useState } from 'react';
import { SortSelectValue, UseAPIReturnType, UseSearchReturnType } from '../utils/props';
import { useTranslation } from 'react-i18next';

export default function useSearch<T, U, V>({
  api,
  defaultSort,
}: {
  api: UseAPIReturnType<T>;
  defaultSort: V;
}): UseSearchReturnType<U, V> {
  const [search, setSearch] = useState<string>('');
  const [filters, setFilters] = useState<U>({} as U);
  const [sort, setSort] = useState<V | undefined>(defaultSort);
  const [page, setPage] = useState<number>(1);
  const [numberOfResultsToDisplay, setNumberOfResultsToDisplay] = useState<number>(50);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [exporting, setExporting] = useState<boolean>(false);
  const { t } = useTranslation('ns');

  // resetPage must trigger fetch if the user is already on page 1, as we assume there is a specific reason for calling it
  const resetPage = () => {
    if (page !== 1) {
      setPage(1);
    } else {
      fetch();
    }
  };

  // If there is a single record on the current page, and the user deletes it, we need to go back to the previous page
  const deleteRecordCallback = () => {
    if (api.data?.data?.length === 1 && page > 1) {
      setPage(page - 1);
    } else {
      fetch();
    }
  };

  useEffect(() => {
    fetch();
  }, [page, sort, filters]);

  useEffect(() => {
    if (!loaded) {
      return;
    }

    // if the user changes the number of results to display, we need to reset the page to 1
    // we don't need another effect for numberOfResultsToDisplay, as this effect is triggerred by it, then we trigger fetch by simply resetting the page number
    resetPage();
  }, [numberOfResultsToDisplay]);

  // For search, use a typing delay to prevent too many requests
  useEffect(() => {
    // Prevent fetching twice on the initial render
    if (!loaded) {
      return;
    }

    const timeout = setTimeout(() => {
      fetch();
    }, 500);

    return () => clearTimeout(timeout);
  }, [search]);

  const prepareQuery = () => {
    let query = '&include_disabled=1';

    if (search) {
      query += `&query=${encodeURIComponent(search)}`;
    }

    if (page) {
      query += `&current_page=${page}`;
    }

    if (sort) {
      query += `&order_by=${(sort as unknown as SortSelectValue)?.sortBy}&order_type=${
        (sort as unknown as SortSelectValue)?.sortOrder
      }`;
    }

    if (numberOfResultsToDisplay) {
      query += `&items_per_page=${numberOfResultsToDisplay}`;
    }

    if (Object.keys(filters || {}).length) {
      Object.entries(filters || {}).forEach(([key, value]) => {
        // value should always be { label: string; value: any type, but preferably defined in the function calling the hook }
        // value.value can be an array
        if (Array.isArray(value) && value?.length) {
          query += `&${key}=`;
          value?.forEach((v, i) => {
            query += `${v.value}${i === value.length - 1 ? '' : ','}`;
          });

          return;
        }

        // value.value can be an object, with properties that are strings or numbers
        if (!Array.isArray(value) && typeof (value as any)?.value === 'object') {
          Object.entries((value as any)?.value || {}).forEach(([k, v]) => {
            if (v) {
              query += `&${key}_${k}=${v}`;
            }
          });

          return;
        }

        // value.value can be a string or a number
        if (!Array.isArray(value) && value) {
          query += `&${key}=${(value as any).value}`;
        }
      });
    }
    return query;
  };

  const fetch = async () => {
    const query = prepareQuery();

    // The .replace here only replaces the first occurrence of the character
    await api.get(query?.replace('&', '?'));

    setLoaded(true);
  };

  const exportToCsv = async (filename?: null | string) => {
    setExporting(true);
    const query = prepareQuery();

    // The .replace here only replaces the first occurrence of the character
    const response = await api.get(`${query?.replace('&', '?')}&csv=1`);

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', t('export-data'));
    document.body.appendChild(link);

    if (filename) {
      link.download = filename;
    }

    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(url);

    setExporting(false);
  };

  return {
    search,
    setSearch,
    filters,
    setFilters,
    sort,
    setSort,
    page,
    setPage,
    numberOfResultsToDisplay,
    setNumberOfResultsToDisplay,
    resetPage,
    deleteRecordCallback,
    exportToCsv,
    exporting,
  };
}
