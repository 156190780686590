import React from 'react';
import { PageTitleProps } from '../utils/props';
import xss from 'xss'; // eslint-disable-line
import tailwindClassNames from '../utils/tailwindClassNames';

export default function PageTitle({ title, description, className = '' }: PageTitleProps) {
  return (
    <div className={tailwindClassNames('mb-14 lg:mb-16 flex flex-col gap-y-6 lg:gap-y-5', className)}>
      {!!title && <h1 className="text-2xl lg:text-3xl font-bold text-gray-900">{title}</h1>}
      {!!description && (
        <div
          className="text-base lg:text-lg text-black max-w-full"
          dangerouslySetInnerHTML={{ __html: xss(description) }}
        />
      )}
    </div>
  );
}
