import useApi from '../../../hooks/use-api';
import React, { Fragment, useEffect, useMemo } from 'react';
import useTable from '../../../hooks/useTable';
import {
  ButtonSize,
  ButtonStyle,
  Counter,
  IconsTypes,
  RowRenderProps,
  SORT_ORDER,
  SortSelectValue,
  UseAPIReturnType,
} from '../../../utils/props';
import PageContainer from '../../../components/PageContainer';
import useSearch from '../../../hooks/useSearch';
import PaginationPagesBar from '../../../components/Table/Pagination/PaginationPagesBar';
import pick from 'lodash/pick';
import Search from '../../../components/Table/Search';
import NumberOfResults from '../../../components/Table/Pagination/NumberOfResults';
import Button from '../../../components/Button/Button';
import Icon from '../../../components/Icon/Icon';
import { formatDisplayedValue, formatedDate, getFilename, isLogged } from '../../../utils/utils';
import LoadingOverlay from '../../../components/Spinner/LoadingOverlay';
import { useTranslation } from 'react-i18next';

export default function Indexes() {
  const countersApi: UseAPIReturnType<Counter> = useApi({
    path: `/admin/counters`,
  });

  const { t } = useTranslation('ns');

  const {
    search,
    setSearch,
    sort,
    setSort,
    page,
    setPage,
    numberOfResultsToDisplay,
    setNumberOfResultsToDisplay,
    exportToCsv,
    exporting,
  } = useSearch<Counter, undefined, SortSelectValue>({
    api: countersApi,
    defaultSort: {
      sortBy: 'joined',
      sortOrder: SORT_ORDER.ASC,
    },
  });

  const { resetSelected, TableComponent } = useTable({
    columns: [
      {
        name: 'created_dt',
        label: t('creation-date-2'),
        className: 'whitespace-nowrap min-w-[81px] max-w-[81px] min-h-[70px] max-h-[70px] p-3',
      },
      {
        name: 'contract_name',
        label: t('account-owner-2'),
        className: 'whitespace-nowrap min-w-[81px] max-w-[81px] min-h-[70px] max-h-[70px] p-3',
      },
      {
        name: 'updated_dt',
        label: t('changed-at'),
        className: 'whitespace-nowrap min-w-[81px] max-w-[81px] min-h-[70px] max-h-[70px] p-3',
      },
      {
        name: 'counter_id',
        label: t('consumer-id'),
        className: 'whitespace-nowrap min-w-[81px] max-w-[81px] min-h-[70px] max-h-[70px] p-3',
      },
      {
        name: 'serial_number',
        label: t('consumer-series-2'),
        className: 'whitespace-nowrap min-w-[81px] max-w-[81px] min-h-[70px] max-h-[70px] p-3',
      },
      {
        name: 'ace_client_id',
        label: t('subscriber-id'),
        className: 'whitespace-nowrap min-w-[81px] max-w-[81px] min-h-[70px] max-h-[70px]',
      },
      {
        name: 'location_id',
        label: t('location-id'),
        className: 'whitespace-nowrap min-w-[81px] max-w-[81px] min-h-[70px] max-h-[70px]',
      },
      {
        name: 'new-date',
        label: t('index-added-date'),
        className: 'whitespace-nowrap min-w-[81px] max-w-[81px] min-h-[70px] max-h-[70px]',
      },
      {
        name: 'new_index',
        label: t('index-new'),
        className: 'whitespace-nowrap min-w-[81px] max-w-[81px] min-h-[70px] max-h-[70px]',
      },
      {
        name: 'note', //todo
        label: t('mentions'),
        className: 'whitespace-nowrap min-w-[81px] max-w-[81px] min-h-[70px] max-h-[70px]',
      },
      {
        name: 'ip_address',
        label: t('ip-user'),
        className: 'whitespace-nowrap min-w-[81px] max-w-[81px] min-h-[70px] max-h-[70px]',
      },
      {
        name: 'user_agent',
        label: t('user-agent-string'),
        className: 'whitespace-nowrap min-w-[81px] max-w-[81px] min-h-[70px] max-h-[70px]',
      },
      {
        name: 'update_count', //todo
        label: t('update-count'),
        className: 'whitespace-nowrap min-w-[81px] max-w-[81px] min-h-[70px] max-h-[70px]',
      },
      {
        name: 'name',
        label: t('created-by'),
        className: 'whitespace-nowrap min-w-[81px] max-w-[81px] min-h-[70px] max-h-[70px]',
      },
      {
        name: 'name', //todo?
        label: t('modified-by'),
        className: 'whitespace-nowrap min-w-[81px] max-w-[81px] min-h-[70px] max-h-[70px]',
      },
    ],
    items: countersApi?.data?.items?.map((counter: Counter) => ({
      data: counter,
      render: ({ CellComponent }: RowRenderProps) => (
        <Fragment>
          <CellComponent tooltipEnabled className="min-w-[81px] max-w-[81px] min-h-[70px] max-h-[70px]">
            {formatedDate(counter.created_dt, 'DD.MM.YYYY')}
          </CellComponent>
          <CellComponent tooltipEnabled className="min-w-[81px] max-w-[81px] min-h-[70px] max-h-[70px]">
            {formatDisplayedValue(counter.contract_name, '-')}
          </CellComponent>
          <CellComponent tooltipEnabled className="min-w-[81px] max-w-[81px] min-h-[70px] max-h-[70px]">
            {formatedDate(counter.updated_dt, 'DD.MM.YYYY')}
          </CellComponent>
          <CellComponent tooltipEnabled className="min-w-[81px] max-w-[81px] min-h-[70px] max-h-[70px]">
            {formatDisplayedValue(counter.counter_id, '-')}
          </CellComponent>
          <CellComponent tooltipEnabled className="min-w-[81px] max-w-[81px] min-h-[70px] max-h-[70px]">
            {formatDisplayedValue(counter.serial_number, '-')}
          </CellComponent>
          <CellComponent tooltipEnabled className="min-w-[81px] max-w-[81px] min-h-[70px] max-h-[70px]">
            {formatDisplayedValue(counter.ace_client_id, '-')}
          </CellComponent>
          <CellComponent tooltipEnabled className="min-w-[81px] max-w-[81px] min-h-[70px] max-h-[70px]">
            {formatDisplayedValue(counter.location_id, '-')}
          </CellComponent>
          <CellComponent tooltipEnabled className="min-w-[81px] max-w-[81px] min-h-[70px] max-h-[70px]">
            {formatedDate(counter.new_date, 'DD.MM.YYYY')}
          </CellComponent>
          <CellComponent tooltipEnabled className="min-w-[81px] max-w-[81px] min-h-[70px] max-h-[70px]">
            {formatDisplayedValue(counter.new_index, '-')}
          </CellComponent>
          <CellComponent tooltipEnabled className="min-w-[81px] max-w-[81px] min-h-[70px] max-h-[70px]">
            -
          </CellComponent>
          <CellComponent tooltipEnabled className="min-w-[81px] max-w-[81px] min-h-[70px] max-h-[70px]">
            {formatDisplayedValue(counter.ip_address, '-')}
          </CellComponent>
          <CellComponent tooltipEnabled className="min-w-[81px] max-w-[81px] min-h-[70px] max-h-[70px]">
            {formatDisplayedValue(counter.user_agent, '-')}
          </CellComponent>
          <CellComponent tooltipEnabled className="min-w-[81px] max-w-[81px] min-h-[70px] max-h-[70px]">
            -
          </CellComponent>
          <CellComponent tooltipEnabled className="min-w-[81px] max-w-[81px] min-h-[70px] max-h-[70px]">
            {formatDisplayedValue(counter.name, '-')}
          </CellComponent>
          <CellComponent tooltipEnabled className="min-w-[81px] max-w-[81px] min-h-[70px] max-h-[70px]">
            -
          </CellComponent>
        </Fragment>
      ),
    })),
    sortSettings: {
      sort,
      setSort,
    },
  });

  const paginatedResponseMeta = useMemo(
    () => pick(countersApi?.data, ['items_no', 'current_page', 'items_per_page', 'total_pages']),
    [countersApi?.data]
  );

  // When search params have changed, reset the selected rows
  useEffect(() => {
    resetSelected();
  }, [numberOfResultsToDisplay, page, search, sort]);

  const handleExport = () => {
    const filename = getFilename(t('export-indexes-sufix'), true, '.csv');
    exportToCsv(filename);
  };

  return (
    <>
      {isLogged() ? (
        <PageContainer className="flex flex-col gap-y-7">
          <div className="flex gap-5 items-center">
            <Search title={t('index-report')} search={search} setSearch={setSearch} />
            <Button
              size={ButtonSize.Large}
              variant={ButtonStyle.Secondary}
              title={t('export-csv')}
              loading={exporting}
              onClick={handleExport}
            >
              <Icon
                icon={IconsTypes.Download2}
                size="16"
                className="h-5 w-5 text-blue-900 fill-blue-900"
                aria-hidden="true"
              />
              {t('export-csv')}
            </Button>
          </div>
          <div className="flex justify-between gap-4">
            <NumberOfResults
              numberOfResultsToDisplay={numberOfResultsToDisplay}
              setNumberOfResultsToDisplay={setNumberOfResultsToDisplay}
              meta={paginatedResponseMeta}
            />
          </div>
          {TableComponent()}
          <PaginationPagesBar meta={paginatedResponseMeta} setPage={setPage} />
        </PageContainer>
      ) : (
        <LoadingOverlay />
      )}
    </>
  );
}
