import React from 'react';
import { AdministrationPageProps } from '../../utils/props';
import AdminImg from './AdminImg';
import EditDescription from './EditDescription';
import EditDocuments from './EditDocuments';
import PageTitle from '../../components/PageTitle';
import { useTranslation } from 'react-i18next';
import LoadingOverlay from '../../components/Spinner/LoadingOverlay';
import Spinner from '../../components/Spinner/Spinner';
import { isLogged } from '../../utils/utils';

const Administration: React.FC<AdministrationPageProps> = ({ settings, updateSettings }) => {
  const { t } = useTranslation('ns');
  return (
    <>
    { isLogged() ? (
      <div className="w-full p-12 ">
        <PageTitle title={t('settings')} className="lg:mb-8" />
        <h1 className="text-xl font-bold text-gray-900">{t('edit-images')}</h1>
        <p className="mb-10 text-sm text-gray-600 max-w-[720px] pt-2">{t('upload-image-text')}</p>
        <AdminImg
          large={settings?.app_settings?.authentication_image?.large}
          mobile={settings?.app_settings?.authentication_image?.mobile}
          largeId={settings?.app_settings?.authentication_image?.id}
          mobileId={settings?.app_settings?.authentication_image?.id}
          updateSettings={updateSettings}
        />
        <h1 className="text-xl font-bold text-gray-900 py-4">{t('edit-documents')}</h1>
        <EditDocuments settings={settings} updateSettings={updateSettings} />

        <h1 className="text-xl font-bold text-gray-900 pt-4">{t('edit-page-texts')}</h1>
        <p className="mb-6 px-0 text-sm text-gray-600 max-w-[720px] pt-2">{t('modify-page-description')}</p>
        <EditDescription settings={settings} />
      </div>) :
      <LoadingOverlay/> }
    </>
  );
};

export default Administration;
