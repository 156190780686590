import React, { useEffect, useState } from 'react';
import { ContractType, ConsumerType, BreadcrumbItemsProps, BradcrumbsStrings } from '../../utils/props';
import Dropdown from '../Dropdown/Dropdown';
import MobileMenu from '../Menu/MobileMenu';
import tailwindClassNames from '../../utils/tailwindClassNames';
import { isAdmin } from '../../utils/utils';
import { useTranslation } from 'react-i18next';

const Breadcrumb: React.FC<BreadcrumbItemsProps> = ({
  consumers,
  updateCurrentLocation,
  displayConsumers,
  userType,
  settings,
}) => {
  const path = location.pathname.slice(1).split('/');
  const [contractList, setConstractList] = useState<ContractType[]>([]);
  const [selectedContract, setSelectedContract] = useState<string>('');
  const { t } = useTranslation('ns');

  useEffect(() => {
    if (consumers?.consumers) {
      setConstractList(
        consumers?.consumers?.map((consumer: ConsumerType) => {
          return {
            label: consumer.alias,
            value: consumer.id,
          };
        })
      );
    }
  }, [consumers]);

  useEffect(() => {
    if (contractList[0]) {
      setSelectedContract(`${contractList[0].value}`);
    }
  }, [contractList]);

  return (
    <div className="sticky lg:relative top-0 z-50">
      <div className={tailwindClassNames('w-full block lg:bg-blue-100 ')}>
        <div className="lg:max-w-[85rem]  lg:flex w-full justify-between items-center xl:mx-auto">
          <div className="px-5 text-gray-500 bg-white lg:bg-blue-100 py-2 lg:py-3 hidden lg:block ">
            <span className="text-blue-900 font-bold breadcrumbSlash">{t('my-account')}</span>
            {path.map((val, index) => (
              <span
                key={index}
                className={tailwindClassNames(index === path.length - 1 ? 'text-gray-500' : 'text-blue-900')}
              >
                {BradcrumbsStrings[val]}
              </span>
            ))}
          </div>
          <div className="relative py-2 flex px-5 items-center gap-3 bg-blue-100 lg:py-1 justify-between">
            <MobileMenu userType={userType} settings={settings} />
            {displayConsumers && !isAdmin(settings, userType) && (
              <div className="flex items-center ">
                <div className="text-base font-bold text-blue-900 grow mr-3">{t('choose-contract')}</div>
                {contractList?.length !== 0 && (
                  <Dropdown
                    options={contractList}
                    value={selectedContract}
                    onChange={(value) => {
                      setSelectedContract(value);
                      if (updateCurrentLocation) {
                        updateCurrentLocation(value);
                      }
                    }}
                  />
                )}
              </div>
            )}
            {!displayConsumers && <div className="grow h-1" />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Breadcrumb;
