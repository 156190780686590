import type { OptionProps, SingleValue } from 'react-select';
import ReactSelect from 'react-select';
import type { ConsumersTableFilters, ReactSelectValue, SelectProps } from '../utils/props';
import { IconsTypes, SortSelectValue } from '../utils/props';
import type { CSSObjectWithLabel } from 'react-select/dist/declarations/src/types';
import React, { useId, useMemo } from 'react';
import Icon from './Icon/Icon';

const customStyles = {
  control: (provided: CSSObjectWithLabel) => ({
    ...provided,
    border: 'none',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    cursor: 'text',
    color: '#374151',
  }),
  valueContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    color: '#374151',
  }),
  input: (provided: CSSObjectWithLabel) => ({
    ...provided,
    color: '#374151',
  }),
  option: (provided: CSSObjectWithLabel, state: OptionProps) => ({
    ...provided,
    cursor: 'pointer',
    backgroundColor: state.isFocused ? '#F9FAFB' : 'transparent',
    color: '#374151',
  }),
  placeholder: (provided: CSSObjectWithLabel) => ({
    ...provided,
    color: '#374151',
  }),
};

const withBorderStyles = {
  control: (provided: CSSObjectWithLabel) => ({
    ...provided,
    border: '1px solid #D1D5DB',
    padding: '0px',
    fontSize: '14px',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    cursor: 'text',
    color: '#374151',
  }),
};

const isMultiStyles = {
  valueContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    gap: '8px',
  }),
  multiValue: (provided: CSSObjectWithLabel) => ({
    ...provided,
    borderRadius: '999px',
    padding: '3px 9px 3px 6px',
    margin: '0',
  }),
  multiValueLabel: (provided: CSSObjectWithLabel) => ({
    ...provided,
    fontSize: '12px',
    color: 'var(--black)',
    lineHeight: '16px',
    fontWeight: 600,
  }),
  multiValueRemove: (provided: CSSObjectWithLabel) => ({
    ...provided,
    cursor: 'pointer',
  }),
};

export default function CustomSelect({
  className,
  name,
  onSelect,
  options,
  isMulti = false,
  value,
  placeholder,
  selectStyles,
  hasBorder,
  isSearchable = true,
  isClearable,
  noOptionsMessage,
}: SelectProps<
  SortSelectValue | string | number,
  ConsumersTableFilters['ace_client_type'] | ConsumersTableFilters['online_invoice'] | ReactSelectValue<number>
>) {
  const instanceId = useId();

  const handleSelectOption = (
    value:
      | SingleValue<ReactSelectValue<SortSelectValue | string | number>>
      | ConsumersTableFilters['ace_client_type']
      | ConsumersTableFilters['online_invoice']
      | ReactSelectValue<number>
  ) => {
    if (onSelect) {
      onSelect(name as string, value);
    }
  };

  const components = useMemo(() => {
    const Regular = {
      IndicatorSeparator: () => null,
      DropdownIndicator: () => (
        <Icon
          icon={IconsTypes.Chevron}
          size="16"
          className="mr-4 ml-2 h-4 w-4 text-gray-400 fill-gray-400"
          aria-hidden="true"
        />
      ),
    };

    if (!isClearable) {
      return {
        ...Regular,
        ClearIndicator: () => null,
      };
    }

    return Regular;
  }, [isClearable]);

  return (
    <div className={className}>
      <ReactSelect
        placeholder={placeholder}
        instanceId={instanceId}
        isMulti={isMulti}
        value={value}
        isSearchable={isSearchable}
        // @ts-ignore - Fix if you want to
        styles={{
          ...customStyles,
          ...(isMulti ? isMultiStyles : {}),
          ...(hasBorder ? withBorderStyles : {}),
          ...(selectStyles || {}),
        }}
        noOptionsMessage={() => noOptionsMessage}
        // @ts-ignore - Fix if you want to
        options={options}
        // @ts-ignore - Fix if you want to
        onChange={handleSelectOption}
        isClearable={isClearable}
        components={components}
      />
    </div>
  );
}
