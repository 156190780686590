import { AppRoutes, DropDownOption, inputValidationFunction, Months, SettingsType, User, AceClientTypes } from './props';
import i18n from 'i18next';
import { API_BASE_URL } from '../env';
import moment from 'moment';

export function getFilename(name: string, withDate: boolean, extension: string) {
  let prefix = '';

  if (withDate) {
    prefix = moment().format('YYYY-MM-DD_HH-mm');
  }

  return `${prefix}${name ? '_' : ''}${name}${extension}`;
}

export function didDateExpire(expirationDate: number) {
  const currentDate = new Date();
  return expirationDate ? currentDate.getTime() > expirationDate * 1000 : false;
}

export const validateField: inputValidationFunction = (value: string) => {
  return !value ? i18n.t('ns:mandatory-field-short') : '';
};

export const validateEmail: inputValidationFunction = (email: string) => {
  return !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) ? i18n.t('ns:email-not-valid') : '';
};

export const validatePass: inputValidationFunction = (pass: string) => {
  return pass.length < 6 ? i18n.t('ns:password-length-error') : '';
};

export const validatePassConfirm: inputValidationFunction = (pass: string, passConfirm?: string) => {
  return pass !== passConfirm ? i18n.t('ns:password-match-error2') : '';
};

export const validateGdpr = (value: boolean) => {
  return value ? '' : i18n.t('ns:gdpr-please-accept');
};

export const generatePeriodList = (startDate: string) => {
  const startPeriod = new Date(startDate);
  const currentDate = new Date();
  const endYear = currentDate.getFullYear();
  const yearDiff = endYear - startPeriod.getFullYear();
  const yearList: DropDownOption[] = [];
  for (let index = 0; index <= yearDiff; index++) {
    yearList.push({
      label: String(endYear - index),
      value: String(endYear - index),
    });
  }
  return { yearList, selectedYear: String(endYear) };
};

export const getAuthorizedHeaders = () => {
  const storedToken: string | null = localStorage.getItem('token') || '';

  const headers = new Headers();
  if (storedToken) {
    headers.append('Authorization', `Bearer ${JSON.parse(storedToken)}`);
  }
  return headers;
};

export const getFilenameFromHeaders = (headers: Headers) => {
  const contentDisposition = headers.get('Content-Disposition');
  if (contentDisposition) {
    const filenameMatch = contentDisposition.match(/filename="(.+)"/);
    if (filenameMatch) {
      return filenameMatch[1];
    }
  }
  return 'invoice.pdf';
};

export const handleLogout = (navigate: (path: string) => void) => {
  localStorage.removeItem('token');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('user_id');
  localStorage.removeItem('user_type');

  navigate(AppRoutes.Login);
};

export const isLogged = () => {
  return !!localStorage.getItem('token');
}

export const formatedDate = (dateToFormat: string | null, formatTemplate: string) => {
  if (dateToFormat === null){
    return '-';
  }

  const dateInLocalTime = moment.utc(dateToFormat).add(moment().utcOffset(), 'minutes');
  const formattedDate = dateInLocalTime.format(formatTemplate);

  return formattedDate;
};

export const convertToUTCstart = (dateString: string, formatTemplate: string) => {
  const date = moment.utc(dateString, formatTemplate);

  // subtract the current UTC offset from the parsed date
  date.subtract(moment().utcOffset(), 'minutes');

  return date.toISOString();
}

export const convertToUTCend = (dateString: string, formatTemplate: string) => {
  const date = moment.utc(dateString, formatTemplate);

  // subtract the current UTC offset from the parsed date
  date.subtract(moment().utcOffset(), 'minutes');

  // add the hours to cover a full day
  date.add({
    hours: 23,
    minutes: 59,
    seconds: 59
  })

  return date.toISOString();
}

export function deepClone<T>(obj: T): T {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  let clone: any // eslint-disable-line

  if (Array.isArray(obj)) {
    clone = [];
    for (let i = 0; i < obj.length; i++) {
      clone[i] = deepClone(obj[i]);
    }
  } else {
    clone = {};
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        clone[key] = deepClone(obj[key]);
      }
    }
  }

  return clone;
}

export const getNextMonth = () => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  return Months[currentMonth + 1];
};
export const validatePhoneNumber = (phoneNumber: string): string => {
  const phoneRegex = /^\d{10}$/;

  return phoneRegex.test(phoneNumber) ? '' : i18n.t('ns:phone-number-invalid');
};

export const validateTos = (value: boolean) => {
  return value ? '' : i18n.t('ns:terms-and-conditions-accept');
};

export const isAuthPage = () => {
  const location = window.location.href;
  return (
    location.toLocaleLowerCase().includes(AppRoutes.Login) ||
    location.toLocaleLowerCase().includes(AppRoutes.Register) ||
    location.toLocaleLowerCase().includes(AppRoutes.ResetPassword) ||
    location.toLocaleLowerCase().includes(AppRoutes.RecoverPassword) ||
    location.toLocaleLowerCase().includes(AppRoutes.Verify) ||
    location.toLocaleLowerCase().includes(AppRoutes.DeleteConfirmation) ||
    location.toLocaleLowerCase().includes(AppRoutes.RecoverAccount) ||
    location.toLocaleLowerCase().includes(AppRoutes.DeactivateInvoice) ||
    location.toLocaleLowerCase().includes(AppRoutes.ChangeEmail) 
  );
};
export const roundedValue = (value: string | number | undefined | null) => {
  let roundedValueresult = 0;
  let newNumber = 0;
  if (Number.isNaN(value) || typeof value === 'string' || !value) {
    if (typeof value === 'string') {
      newNumber = Number(value);
    }
    roundedValueresult = Number(newNumber.toFixed(2));
  } else if ((value === 0 || value) && typeof value === 'number') {
    roundedValueresult = Number(value.toFixed(2));
  }
  return roundedValueresult;
};

export const quickValidate = (
  value: string,
  fieldVisited: boolean | undefined,
  error: string | undefined,
  currentField = '',
  fildsAffected: string | undefined,
  onlyPositiveValues: boolean | undefined
) => {
  let errorToDisplay = '';
  if (!value && (error || fieldVisited)) {
    errorToDisplay = i18n.t('ns:mandatory-field');
  } else if (error && fildsAffected && fildsAffected.includes(currentField)) {
    errorToDisplay = i18n.t('ns:value-is-wrong');
  }
  // only for numbers
  if (value && onlyPositiveValues && !isNaN(Number(value)) && Number(value) < 0) {
    errorToDisplay = i18n.t('ns:value-cannot-be-negative');
  }

  return errorToDisplay;
};
/**
 * Format a displayed value
 * @param value
 * @param emptyIndicator
 */
export const formatDisplayedValue = (value: string | number | undefined | null, emptyIndicator = '') => {
  if (!value && isNaN(<number>value)) {
    return emptyIndicator;
  }

  return value ?? emptyIndicator;
};

export const isAdmin = (settings: SettingsType, usertype?: string) => {
  return settings?.user_types?.ADMIN == usertype;
};
export const isRegularUser = (settings: SettingsType, usertype?: string) => {
  return settings?.user_types?.NORMAL_USER == usertype;
};

export const isAuth = (user: User) => {
  return !!user;
};


export const prepareNumberForData = (value?: number) => {
  if (!value) {
    return '01';
  }

  return value < 10 ? `0${value}` : value;
};

export const viewInvoice = async (
  id: string, 
  currentLocation: string | undefined,
  t: (key: string) => string,
  setInvoicePdfError: (error: string) => void
) => {
  const headers = getAuthorizedHeaders();
  const pdfUrl = `${API_BASE_URL}invoice/${id}?consumer_id=${currentLocation}`;

  fetch(pdfUrl, { headers })
    .then((response) => {
      const filename = getFilenameFromHeaders(response.headers);
      if (response.status === 200) {
        return response.blob().then((blob) => ({ filename, blobData: blob }));
      } else {
        throw new Error(t('view-invoice-pdf-failed'));
      }
    })
    .then(({ filename, blobData }) => {
      const blob = new Blob([blobData], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);

      const pdfWindow = window.open();
      if (pdfWindow) {
        pdfWindow.location.href = url;
        pdfWindow.document.title = filename;
      }
    })
    .catch((error) => {
      setInvoicePdfError(`${error}`);
    });
};

export const getLabelName = (name: string, clientType: string) => {
  if (
    clientType === AceClientTypes.ASOCIATIE_PROPRIETARI.toString() ||
    clientType === AceClientTypes.AGENT_ECONOMIC.toString()
    ) {
    return i18n.t(`ns:register-account.customer-company-${name}`);
  } else {
    return i18n.t(`ns:register-account.customer-${name}`);
  }
};