import React, { useMemo } from 'react';
import { ButtonProps, ButtonSize, ButtonStyle, ButtonTypes } from '../../utils/props';
import tailwindClassNames from '../../utils/tailwindClassNames';
import Spinner from '../Spinner/Spinner';

export default function Button(props: ButtonProps) {
  const {
    type = ButtonTypes.Button,
    className,
    children,
    size,
    variant,
    href,
    onClick,
    title,
    disabled,
    loading,
    labelClass = '',
  } = props;

  const classNames = useMemo(() => {
    const defaultButtonStyle = 'flex justify-center items-center';

    const buttonSize = {
      [ButtonSize.Default as string]: 'font-medium px-3 py-2 text-sm',
      [ButtonSize.DefaultFull as string]: 'font-medium px-3 py-2 text-sm min-w-full',
      [ButtonSize.Large as string]: 'font-medium px-4 py-2.5 text-sm',
      [ButtonSize.LargeFull as string]: 'font-medium px-4 py-2.5 text-sm min-w-full',
      [ButtonSize.Small as string]: 'font-medium px-2 py-2 text-xs rounded-md',
      [ButtonSize.SmallFixed as string]: 'font-medium px-2 py-2 text-xs rounded-md w-[130px]',
      [ButtonSize.SmallFull as string]: 'font-medium px-4 py-1.5 text-xs min-w-full rounded-md',
    };

    const buttonVariant = {
      [ButtonStyle.Primary as string]:
        'border-blue border border-solid rounded-lg  text-phrimaryIndigo bg-blue-900 text-white focus:ring-2 focus:ring-blue-900',
      [ButtonStyle.Secondary as string]:
        'border-white border border-solid rounded-lg text-blue-900 bg-blue-100 focus:ring-2 focus:ring-blue-200 focus:border-transparent',
      [ButtonStyle.SafePrimary as string]:
        'border-white border border-solid rounded-lg text-white bg-green-600 focus:ring-2 focus:ring-green-600',
      [ButtonStyle.SafeSecundary as string]:
        'border-green-100 border border-solid rounded-lg text-green-900 bg-green-100 focus:ring-2 focus:ring-green-900',
      [ButtonStyle.DangerPrimary as string]:
        'border-white border border-solid rounded-lg text-white bg-rose-600 focus:ring-2 focus:ring-rose-600',
      [ButtonStyle.DangerSecundary as string]:
        'border-white border border-solid rounded-lg text-white bg-red-400 focus:ring-2 focus:ring-red-700',
    };

    const disabledVariant = 'border-blue border border-solid rounded-lg text-phrimaryIndigo bg-gray-100 text-gray-500';
    return tailwindClassNames(
      defaultButtonStyle,
      disabled ? disabledVariant : buttonVariant[variant],
      buttonSize[size],
      className || ''
    );
  }, [className, size, variant, disabled]);

  const Component = useMemo(() => {
    if (!href) {
      return 'button';
    }

    return 'a';
  }, [href]);

  const componentProps = useMemo(() => {
    let props;

    if (!href) {
      props = {
        type,
        className: classNames,
        onClick: loading ? () => {} : onClick, // eslint-disable-line
        title,
        role: 'button',
        'aria-label': title || '',
        disabled,
      };
    } else {
      props = {
        href,
        className: classNames,
        onClick: loading ? () => {} : onClick, // eslint-disable-line
        title,
        disabled,
      };
    }

    return props;
  }, [href, type, classNames, onClick, title]);

  return (
    <Component {...componentProps}>
      <span
        className={tailwindClassNames(
          'flex items-center justify-center gap-x-2',
          `${loading ? 'pe-1' : ''}`,
          labelClass
        )}
      >
        {children}
      </span>
      {loading && variant === ButtonStyle.Primary && <Spinner size="small" color="light" />}
      {loading && variant === ButtonStyle.Secondary && <Spinner size="small" color="dark" />}
    </Component>
  );
}
