import { AceClientTypes, AceClientTypesLabels, ConsumersTableFilters } from '../../../utils/props';
import CustomSelect from '../../../components/CustomSelect';
import React, { SetStateAction, useMemo } from 'react';
import DateSelection from '../../../components/Table/DateSelection';
import { useTranslation } from 'react-i18next';

export default function Filters({
  filters,
  setFilters,
}: {
  filters?: ConsumersTableFilters;
  setFilters: React.Dispatch<SetStateAction<ConsumersTableFilters>>;
}) {
  const updateFilters = (name: string, value: unknown) => {
    setFilters((filters: ConsumersTableFilters) => ({
      ...filters,
      [name]: value,
    }));
  };

  const { t } = useTranslation('ns');

  const clientTypeOptions = useMemo(
    () => [
      {
        label: AceClientTypesLabels.CASE_PARTICULARE,
        value: AceClientTypes.CASE_PARTICULARE,
      },
      {
        label: AceClientTypesLabels.LOCATAR_BLOC,
        value: AceClientTypes.LOCATAR_BLOC,
      },
      {
        label: AceClientTypesLabels.AGENT_ECONOMIC,
        value: AceClientTypes.AGENT_ECONOMIC,
      },
      {
        label: AceClientTypesLabels.ASOCIATIE_PROPRIETARI,
        value: AceClientTypes.ASOCIATIE_PROPRIETARI,
      },
    ],
    []
  );

  const greenInvoiceOptions = useMemo(
    () => [
      {
        label: t('active'),
        value: 1,
      },
      {
        label: t('inactive'),
        value: 0,
      },
    ],
    []
  );

  return (
    <div className="flex gap-5">
      <DateSelection name={'joined'} label={t('creation-date-account')} filters={filters} setFilters={setFilters} />
      <CustomSelect
        isClearable
        placeholder={t('subscriber-type')}
        className="min-w-[200px] max-w-[360px]"
        hasBorder
        name="ace_client_type"
        onSelect={updateFilters}
        options={clientTypeOptions}
        value={filters?.ace_client_type}
      />
      <CustomSelect
        isClearable
        placeholder={t('green-invoice')}
        className="min-w-[100px]"
        hasBorder
        name="online_invoice"
        onSelect={updateFilters}
        options={greenInvoiceOptions}
        value={filters?.['online_invoice']}
      />
    </div>
  );
}
