// Use translation files
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import roNs from './locales/ro/translation.json';

export const defaultNS = 'translation';

i18next.use(initReactI18next).init({
  debug: true,
  fallbackLng: 'ro',
  defaultNS,
  resources: {
    ro: {
      ns: roNs,
    },
  },
});

export default i18next;
