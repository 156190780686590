import React, { useEffect, useState } from 'react';
import Popup from '../../components/Popup/Popup';
import Button from '../../components/Button/Button';
import { 
  AppRoutes,
  ButtonSize,
  ButtonStyle,
  ConsentPageProps,
  MessageStyle,
  IconsTypes,
  ConsentErrors
} from '../../utils/props';
import {
  handleLogout,
} from '../../utils/utils';
import Icon from '../../components/Icon/Icon';
import useApi from '../../hooks/use-api';
import { useNavigate } from 'react-router-dom';
import Message from '../../components/Message/Message';
import CustomImg from '../../components/CustomImg/CustomImg';
import { useTranslation } from 'react-i18next';

const ConsentTOS: React.FC<ConsentPageProps> = ({ settings, getSettingsAfterUpdate }) => {
  const { t } = useTranslation('ns');
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const navigate = useNavigate();
  const user = useApi({
    path: `/user`,
  });

  const RefuseApi = useApi({
    path: `/user`,
    callback: (data) => { // eslint-disable-line
      handleLogout(navigate);
    },
  });
  
  const AcceptApi = useApi({
    path: `/user`,
    callback: (data) => { // eslint-disable-line
      getSettingsAfterUpdate(data.type);
      navigate(AppRoutes.Invoices);
    },
  });

  const user_id: string = localStorage.getItem('user_id') || '';
  const termsConsent = user.fildsAffected?.includes(ConsentErrors.Tos) ? t('terms-and-conditions') : undefined;

  const AcceptConsent = () => {
    if (!user) {
      return;
    }

    const data = {
      ...(!termsConsent ? {} : { tos_consent: 1 }),
    };

    AcceptApi.patch(user_id, data);
  };

  const RefuseConsent = () => {
    const data = {
      ...(!termsConsent ? {} : { tos_consent: 0 }),
    };

    RefuseApi.patch(user_id, data);
  };

  useEffect(() => {
    user.get();
  }, []);

  return (
    <div className="md:flex block max-w-full">
      <CustomImg
        large={settings?.app_settings?.authentication_image?.large}
        mobile={settings?.app_settings?.authentication_image?.mobile}
        className="lg:w-3/5 w-full md:h-auto min-h-60 h-auto"
        alt="this is the main img "
        loading="eager"
      />
      <div className="flex flex-col content-center justify-center lg:w-2/5 w-full p-10 lg:p-12 xl:p-16">
        <h1 className="mb-2 text-2xl font-bold">
          {settings?.app_settings?.tos && (
            <a href={settings?.app_settings?.tos?.value} className="text-black-900">
              {' '}
              {t('terms-and-conditions')}
            </a>
          )}
        </h1>
        <p className="mb-2 text-black-900 mt-10">
        {settings?.app_settings?.terms_and_conditions_paragraph?.value}
        </p>
        { termsConsent && (
          <div className="flex flex-wrap mb-2">
              <Icon icon={IconsTypes.Pdf} size="24" className="fill-blue-900 w-4 h-4 mr-2" />
              <a
                className="text-blue-900 underline"
                target="_blank"
                href={settings?.app_settings?.tos?.value}
              >
                {termsConsent}.pdf
              </a>
          </div>
        )}

        <span></span>
        {AcceptApi.errors && (
          <Message type={MessageStyle.Error} className="mb-6">
            {AcceptApi.errors}
          </Message>
        )}
        {RefuseApi.errors && (
          <Message type={MessageStyle.Error} className="mb-6">
            {RefuseApi.errors}
          </Message>
        )}
        <div className="flex mt-10">
          <Button
            size={ButtonSize.Large}
            variant={ButtonStyle.Secondary}
            className="my-0 grow lg:grow-0 mr-5 w-1/2"
            onClick={() => {
              setOpenConfirmation(true);
            }}
          >
            {t('reject')}
          </Button>
          <Button
            size={ButtonSize.Large}
            variant={ButtonStyle.Primary}
            className="my-0 grow lg:grow-0 w-1/2"
            onClick={AcceptConsent}
          >
            {t('accept')}
          </Button>
          {openConfirmation && (
            <Popup
              onOk={RefuseConsent}
              onClose={() => {
                setOpenConfirmation(false);
              }}
              cancelText={t('no')}
              okText={t('yes')}
              title={t('confirm')}
            >
              {t('refusal-warning-TOS')}
            </Popup>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConsentTOS;
