import React, { useRef } from 'react';
import { MessageStyle } from '../../utils/props';
import Message from '../../components/Message/Message';
import { useTranslation } from 'react-i18next';

type FileUploadComponentProps = {
  accept: string;
  onSuccess?: (data: string) => void;
  onError?: (error: string) => void;
  warning?: string;
  endpointPut: (data: any) => void; //
  endpointErrors?: string;
  endpointSuccessMessage?: string;
  endpointCloseMessages: () => void;
};

const FileUploadComponent: React.FC<FileUploadComponentProps> = ({
  accept,
  onSuccess,
  onError,
  warning,
  endpointPut,
  endpointErrors,
  endpointSuccessMessage,
  endpointCloseMessages,
}) => {
  const fileUploadeid = useRef(`fileUploadeid-${Math.random().toString(36).substring(7)}`);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      handleUpload(file);
    }
  };

  const handleUpload = (file: File) => {
    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      endpointPut(formData);
    }
  };

  // Handle API response
  React.useEffect(() => {
    if (endpointSuccessMessage && onSuccess) {
      onSuccess(endpointSuccessMessage);
    }
    if (endpointErrors && onError) {
      onError(endpointErrors);
    }
  }, [endpointSuccessMessage, endpointErrors, onSuccess, onError]);

  const { t } = useTranslation('ns');

  return (
    <div className="">
      <div className="border-2 border-gray-200 rounded-lg bg-white">
        <input type="file" accept={accept} onChange={handleFileChange} className="hidden" id={fileUploadeid.current} />
        <label htmlFor={fileUploadeid.current} className="cursor-pointer">
          <div className="w-full h-[44px] bg flex justify-between items-center px-1">
            <p className="text-sm text-gray-400 ps-2">{t('upload-file')}</p>
            <div className="bg-blue-200 text-blue-900 rounded-md p-1.5">{t('choose-file')}</div>
          </div>
        </label>
      </div>
      <p className="text-sm text-gray-400 pt-1">{warning}</p>
      {endpointErrors && (
        <Message type={MessageStyle.Error} onCloseFunction={endpointCloseMessages}>
          {endpointErrors}
        </Message>
      )}
      {endpointSuccessMessage && (
        <Message type={MessageStyle.Success} onCloseFunction={endpointCloseMessages}>
          {endpointSuccessMessage}
        </Message>
      )}
    </div>
  );
};

export default FileUploadComponent;
