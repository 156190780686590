import React, { useState, useEffect, useMemo } from 'react';
import Input from '../../components/Input/Input';
import {
  ButtonSize,
  ButtonStyle,
  InputTypes,
  AddContractProps,
  MessageStyle,
  AceClientTypesLabels,
  AceClientTypes,
  IconsTypes,
} from '../../utils/props';
import { quickValidate, getLabelName } from '../../utils/utils';
import useApi from '../../hooks/use-api';
import Dropdown from '../../components/Dropdown/Dropdown';
import Button from '../../components/Button/Button';
import Message from '../../components/Message/Message';
import Icon from '../../components/Icon/Icon';
import { useTranslation } from 'react-i18next';
const AddContract: React.FC<AddContractProps> = ({ close, settings, updateSuccessAddMesage }) => {
  const [alias, setAlias] = useState('');
  const [code, setcode] = useState('');
  const [invoiceId, setInvoiceId] = useState('');
  const [clientType, setClientType] = useState('');
  const [codeVisited, setCodeVisited] = useState<boolean>(false);
  const [aliasVisited, setAliasVisited] = useState<boolean>(false);
  const [invoiceIdVisited, setInvoiceIdVisited] = useState<boolean>(false);
  const [clientTypeVisited, setClientTypeVisited] = useState<boolean>(false);
  const { t } = useTranslation('ns');

  const clientTypeList = useMemo(
    () => [
      {
        label: AceClientTypesLabels.CASE_PARTICULARE,
        value: AceClientTypes.CASE_PARTICULARE,
      },
      {
        label: AceClientTypesLabels.LOCATAR_BLOC,
        value: AceClientTypes.LOCATAR_BLOC,
      },
      {
        label: AceClientTypesLabels.AGENT_ECONOMIC,
        value: AceClientTypes.AGENT_ECONOMIC,
      },
      {
        label: AceClientTypesLabels.ASOCIATIE_PROPRIETARI,
        value: AceClientTypes.ASOCIATIE_PROPRIETARI,
      },
    ],
    []
  );

  const consumers = useApi({
    path: `/consumers`,
    callback: () => {
      updateSuccessAddMesage(t('add-consumption-point-success'));
      close();
    },
  });

  const addConsumer = () => {
    const errorsInvoiceID = quickValidate(
      invoiceId,
      invoiceIdVisited,
      consumers.errors,
      'invoice_id',
      consumers.fildsAffected,
      true
    );
    const errorsCode = quickValidate(
      code,
      codeVisited,
      consumers.errors,
      'invoicing_code',
      consumers.fildsAffected,
      true
    );
    if (alias && clientType && !errorsInvoiceID && !errorsCode) {
      consumers.post({
        ace_client_type: clientType,
        invoice_id: invoiceId,
        invoicing_code: code,
        alias: alias,
      });
    } else {
      setCodeVisited(true);
      setAliasVisited(true);
      setInvoiceIdVisited(true);
      setClientTypeVisited(true);
    }
  };

  const itemRow = 'flex flex-col lg:flex-row ml-5 mb-5 items-start';

  return (
    <div className="py-4">
      <h2 className="font-medium pb-4">{t('add-new-consumption-point')}</h2>
      <div className="border rounded-xl border-blue-900 bg-gray-50 p-4">
        <div className="block w-full gap-1 lg:flex">
          <div className="w-full lg:w-1/2">
            <div className={itemRow}>
              <div className="w-full flex justify-start lg:w-1/3 lg:pt-2 min-w-[150px]">
                {t('subscriber-code')}
                <span className="ml-1">
                  <Icon icon={IconsTypes.Mandatory} size="6" className="fill-red-800 w-1.5 h-1.5" />
                </span>
              </div>
              <Input
                type={InputTypes.Number}
                value={code}
                placeholder={t('subscriber-code-placeholder')}
                onChange={(e) => setcode(e.target.value)}
                title={t('subscriber-code')}
                wrapperClass="w-full lg:w-2/3 mr-2"
                className="w-48"
                hasError={quickValidate(
                  code,
                  codeVisited,
                  consumers.errors,
                  'invoicing_code',
                  consumers.fildsAffected,
                  true
                )}
                onBlur={() => {
                  setCodeVisited(true);
                }}
                isMandatory
              />
            </div>
            <div className={itemRow}>
              <div className="w-full flex justify-start lg:w-1/3 lg:pt-2 min-w-[150px]">
                {t('invoice-number')}
                <span className="ml-1">
                  <Icon icon={IconsTypes.Mandatory} size="6" className="fill-red-800 w-1.5 h-1.5" />
                </span>
              </div>
              <Input
                type={InputTypes.Number}
                value={invoiceId}
                placeholder={t('invoice-code-placeholder')}
                onChange={(e) => setInvoiceId(e.target.value)}
                title={t('invoice-number')}
                wrapperClass="w-full lg:w-2/3 mr-2"
                className="w-48"
                hasError={quickValidate(
                  invoiceId,
                  invoiceIdVisited,
                  consumers.errors,
                  'invoice_id',
                  consumers.fildsAffected,
                  true
                )}
                onBlur={() => {
                  setInvoiceIdVisited(true);
                }}
                isMandatory
              />
            </div>
          </div>

          <div className="w-full lg:w-1/2">
            <div className={itemRow}>
              <div className="w-full flex justify-start lg:w-1/3 lg:pt-2 min-w-[150px] mr-1">
                {t('subscriber-type')}
                <span className="ml-1">
                  <Icon icon={IconsTypes.Mandatory} size="6" className="fill-red-800 w-1.5 h-1.5" />
                </span>
              </div>
              <Dropdown
                options={clientTypeList}
                value={clientType}
                onChange={(value) => {
                  setClientType(value);
                }}
                wrapperClassName="block w-full lg:w-2/3 mr-0"
                large
                className="w-full"
                noInitialValue
                placeholder={t('subscriber-type-choose')}
                onBlur={() => {
                  setClientTypeVisited(true);
                }}
                hasError={!clientType && (consumers.errors || clientTypeVisited) ? t('mandatory-field') : ''}
                isMandatory
              />
            </div>
            <div className={itemRow}>
              <div className="w-full flex justify-start lg:w-1/3 lg:pt-2 min-w-[150px] mr-1">
                {getLabelName('name', clientType)}
                <span className="ml-1">
                  <Icon icon={IconsTypes.Mandatory} size="6" className="fill-red-800 w-1.5 h-1.5" />
                </span>
              </div>
              <Input
                type={InputTypes.Text}
                value={alias}
                placeholder={getLabelName('placeholder', clientType)}
                onChange={(e) => setAlias(e.target.value)}
                title={getLabelName('name', clientType)}
                wrapperClass="w-full lg:w-2/3 mr-0"
                hasError={!alias && (consumers.errors || aliasVisited) ? t('mandatory-field') : ''}
                onBlur={() => {
                  setAliasVisited(true);
                }}
                isMandatory
              />
            </div>
          </div>
        </div>
        <div className="w-full flex justify-end">
          <Button
            size={ButtonSize.Large}
            variant={ButtonStyle.Secondary}
            title="Adaugă punct de consum"
            className="my-0 grow lg:grow-0"
            onClick={addConsumer}
          >
            {t('add')}
          </Button>
        </div>

        {consumers.errors && (
          <Message type={MessageStyle.Error} className="my-6" onCloseFunction={consumers.closeMessages}>
            {consumers.errors}
          </Message>
        )}
      </div>
    </div>
  );
};

export default AddContract;
