import React, { useEffect, useRef, useState } from 'react';
import { SettingsAccountDataProps, InputTypes, ButtonStyle, ButtonSize, MessageStyle } from '../../utils/props';
import tailwindClassNames from '../../utils/tailwindClassNames';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import useApi from '../../hooks/use-api';
import { validatePhoneNumber, validateEmail } from '../../utils/utils';
import Message from '../../components/Message/Message';
import { useTranslation } from 'react-i18next';

const AccountData: React.FC<SettingsAccountDataProps> = ({ editMode, data, user_id, openEditMode }) => {
  const { t } = useTranslation('ns');
  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [mail, setMail] = useState('');
  const [initialMail, setInitialMail] = useState('');
  const [mailError, setMailError] = useState('');

  const emailChanged = useRef(false);

  const updateFormData = useApi({
    path: `/user`,
    defaultSuccessMessage: t('account-data-change-success'),
    defaultErrorMessage: t('account-data-change-failure'),
  });

  useEffect(() => {
    setPhone(data?.phone || '');
    setMail(data?.mail || '');
    setInitialMail(data?.mail || '');
  }, [data]);

  const saveAccount = () => {
    emailChanged.current = initialMail !== mail
    if (validatePhoneNumber(phone)) {
      setPhoneError(validatePhoneNumber(phone));
      return;
    } else {
      setPhoneError('');
    }
    if (validateEmail(mail)) {
      setMailError(validateEmail(mail));
      return;
    } else {
      setMailError('');
    }
    const dataToUpload = {
      status: undefined,
      phone,
      mail
    }
    
    updateFormData.patch(user_id, dataToUpload);
  };

  return (
    <div
      className={tailwindClassNames(
        'w-full p-6 lg:p-6 block lg:flex bg-gray-100 my-2 rounded-xl flex-wrap overflow-hidden',
        editMode ? 'border-2 border-blue-900' : ''
      )}
    >
      <div className="w-full">
        <div className="py-2 block lg:flex">
          <span className="whitespace-nowrap w-full lg:w-1/4 lg:max-w-[180px]">{t('subscriber-name')}:</span>
          <div className="w-full lg:w-3/4 lg:max-w-[280px]">
            <div className="whitespace-nowrap py-2 lg:py-0">
              {data?.name || <span className="text-gray-400">{t('unset')}</span>}
            </div>
          </div>
        </div>
        <div className="py-2 block lg:flex items-center">
          <span className="whitespace-nowrap w-full lg:w-1/4 lg:max-w-[180px]">{t('phone-number')}:</span>
          <div className="w-full lg:w-3/4 py-2 lg:py-0 lg:max-w-[280px]">
            {!editMode ? (
              <div className="whitespace-nowrap">
                {data?.phone || (
                  <button
                    className="text-blue-700 cursor-pointer"
                    onClick={() => {
                      openEditMode();
                    }}
                  >
                    + <span className=" underline">{t('add-phone-number')}</span>
                  </button>
                )}
              </div>
            ) : (
              <>
                <Input
                  type={InputTypes.Phone}
                  wrapperClass="px-0"
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                  hasError={phoneError ? '_' : ''}
                />
              </>
            )}
          </div>
        </div>
        <div className="py-2 block lg:flex items-center">
          <span className="whitespace-nowrap w-full lg:w-1/4 lg:max-w-[180px]">{t('email')}:</span>
          <div className="w-full py-2 lg:py-0 lg:w-3/4 lg:max-w-[500px]">
            {!editMode ? (
              <div className="whitespace-nowrap max-w-full text-ellipsis overflow-hidden block">
                {data?.mail ? data.mail : <span className="text-gray-400">{t('unset')}</span>}
              </div>
            ) : (
              <>
                <Input
                  type={InputTypes.Email}
                  wrapperClass="px-0"
                  value={mail}
                  onChange={(e) => {
                    setMail(e.target.value);
                  }}
                  hasError={mailError ? '_' : ''}
                />
              </>
            )}
          </div>
        </div>
      </div>
      <div className={tailwindClassNames('grow max-w-1/2', editMode ? 'block lg:flex' : 'flex')}>
        <div className={tailwindClassNames('pr-6', editMode ? 'hidden lg:block' : 'block')}></div>
        <div className=" grow"></div>
      </div>
      <div className="w-full">
        {phoneError && editMode && <Message type={MessageStyle.Error}>{phoneError}</Message>}
        {mailError && editMode && <Message type={MessageStyle.Error}>{mailError}</Message>}
      </div>
      <div className="block lg:hidden"></div>
      <div className="w-full">
        {updateFormData.errors && editMode && (
          <Message type={MessageStyle.Error} onCloseFunction={updateFormData.closeMessages}>
            {updateFormData.errors}
          </Message>
        )}
        {updateFormData.successMessage && editMode && (
          <Message type={MessageStyle.Success} onCloseFunction={updateFormData.closeMessages}>
            {updateFormData.successMessage}
          </Message>
        )}
        {updateFormData.successMessage && editMode && emailChanged.current && (
          <Message type={MessageStyle.Success} onCloseFunction={updateFormData.closeMessages}>
            {t('email-changed-sent')}
          </Message>
        )}
        
      </div>
      {editMode && (
        <div className="ml-auto w-full flex grow justify-start pt-2 ">
          <Button
            size={ButtonSize.Default}
            variant={ButtonStyle.SafePrimary}
            title={t('save')}
            onClick={saveAccount}
            className="hidden lg:block"
          >
            {t('save')}
          </Button>

          <Button
            size={ButtonSize.DefaultFull}
            variant={ButtonStyle.SafePrimary}
            title={t('save')}
            onClick={saveAccount}
            className="lg:hidden flex"
          >
            {t('save')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default AccountData;
