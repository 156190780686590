import { PaginationNavigationButtonActions, PaginationProps } from '../../../utils/props';
import React from 'react';
import tailwindClassNames from '../../../utils/tailwindClassNames';

export default function PaginationNavigationButton({
  action,
  setPage,
  meta,
  children,
  className,
}: {
  action: PaginationNavigationButtonActions;
  setPage: (page: number) => void;
  meta: PaginationProps;
  children?: React.ReactNode;
  className?: string;
}) {
  const changePage = () => {
    switch (action) {
      case PaginationNavigationButtonActions.PREVIOUS:
        setPage(meta.current_page - 1);
        break;
      case PaginationNavigationButtonActions.NEXT:
        setPage(meta.current_page + 1);
        break;
      default:
        break;
    }
  };

  if (
    !meta.total_pages ||
    (action === PaginationNavigationButtonActions.PREVIOUS && meta.current_page === 1) ||
    (action === PaginationNavigationButtonActions.NEXT && meta.current_page === meta.total_pages)
  ) {
    return <div className="flex-1" />;
  }

  return (
    <div className={tailwindClassNames('flex w-0 flex-1', className ?? '')}>
      <button
        onClick={changePage}
        type="button"
        className="flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
      >
        {children}
      </button>
    </div>
  );
}
