import React, { useEffect, useState } from 'react';
import CabIcon from '../../assets/images/cab-full-Icon.png';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import { AppRoutes, ButtonSize, ButtonStyle, IconsTypes, MenuProps } from '../../utils/props';
import MenuItem from './MenuItem';
import tailwindClassNames from '../../utils/tailwindClassNames';
import { useNavigate } from 'react-router-dom';
import { handleLogout, isAdmin } from '../../utils/utils';
import { useTranslation } from 'react-i18next';

const MobileMenu = ({ userType, settings }: MenuProps) => {
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    if (opened) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [opened]);

  const handleMenuClose = () => {
    setOpened(false);
  };
  const navigate = useNavigate();
  const { t } = useTranslation('ns');

  return (
    <div>
      <div className={tailwindClassNames('w-full lg:hidden relative')}>
        <Button
          size={ButtonSize.LargeFull}
          variant={ButtonStyle.Primary}
          onClick={() => {
            setOpened(!opened);
          }}
          labelClass={tailwindClassNames('w-full flex items-center')}
          className="min-w-[40px] max-w-[40px] h-10 p-0 ring-0 outline-0 border-0"
        >
          <Icon icon={IconsTypes.Home} size="18" className="fill-white h-4 w-4" />
        </Button>
        {opened && <div className="absolute top-[29px] bg-blue-900 h-10 w-[40px]" />}
      </div>
      <div
        className={tailwindClassNames(
          'w-screen absolute top-[52px] left-0 flex flex-col justify-betwee left h-screen',
          !opened ? 'hidden' : ''
        )}
      >
        <div className="absolute inset-0 bg-gray-700 opacity-70 z-10" />
        <div
          className={tailwindClassNames(
            'fixed flex flex-col justify-between bg-blue-900 left-0 h-calc-160 ms-auto z-10 pb-10',
            !opened ? 'hidden' : ''
          )}
        >
          <div className="w-[260px] p-5 flex flex-col gap-2">
            {!isAdmin(settings, userType) && (
              <>
                <MenuItem
                  label={t('invoice-list')}
                  icon={IconsTypes.Facturi}
                  to={AppRoutes.Invoices}
                  opened={opened}
                  onCloseMenu={handleMenuClose}
                />
                <MenuItem
                  label={t('send-index')}
                  icon={IconsTypes.Pen}
                  to={AppRoutes.SendIndex}
                  opened={opened}
                  onCloseMenu={handleMenuClose}
                />
                <MenuItem
                  label={t('manage-contracts')}
                  icon={IconsTypes.Folder}
                  to={AppRoutes.ManageContracts}
                  opened={opened}
                  onCloseMenu={handleMenuClose}
                />
                <MenuItem
                  label={t('account-settings')}
                  icon={IconsTypes.Settings}
                  to={AppRoutes.Settings}
                  opened={opened}
                  onCloseMenu={handleMenuClose}
                />
              </>
            )}
            {isAdmin(settings, userType) && (
              <>
                <MenuItem
                  label={t('subscriber-list')}
                  icon={IconsTypes.Clipboard}
                  to={AppRoutes.AdministrationUsers}
                  opened={opened}
                  iconSize={20}
                />
                <MenuItem
                  label={t('index-report')}
                  icon={IconsTypes.Presentation}
                  to={AppRoutes.AdministrationIndexes}
                  opened={opened}
                  iconSize={20}
                />
                <MenuItem
                  label={t('transaction-history')}
                  icon={IconsTypes.Tranzaction}
                  to={AppRoutes.AdministrationTransactions}
                  opened={opened}
                  iconSize={18}
                />
                <MenuItem
                  label={t('settings')}
                  icon={IconsTypes.Settings}
                  to={AppRoutes.Administration}
                  opened={opened}
                  iconSize={18}
                />
              </>
            )}
          </div>
          <div className="text-sm">
            <div className="pt-8 border-b border-b-blue-500" />
            <MenuItem
              label={t('logout')}
              icon={IconsTypes.Logout}
              onClick={() => {
                handleLogout(navigate);
              }}
              opened={opened}
            />
            <div className="pb-8 border-t border-t-blue-500" />
            <img src={CabIcon} className={tailwindClassNames('ms-6 mb-10', !opened ? 'ms-5 mb-16' : '')} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
