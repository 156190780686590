import React, { useEffect, useState } from 'react';
import useApi from '../../hooks/use-api';
import Spinner from '../../components/Spinner/Spinner';
import LoadingOverlay from '../../components/Spinner/LoadingOverlay';
import ConsumersTable from './ConsumersTable';
import ConsumersTableMobile from './ConsumersTableMobile';
import Popup from '../../components/Popup/Popup';
import Button from '../../components/Button/Button';
import { ButtonSize, ButtonStyle, ConsumerType, DefaultPageProps, MessageStyle } from '../../utils/props';
import { isLogged } from '../../utils/utils';
import AddContract from './AddContract';
import Message from '../../components/Message/Message';
import PageTitle from '../../components/PageTitle';
import PageContainer from '../../components/PageContainer';
import { useTranslation } from 'react-i18next';

const ManageContracts: React.FC<DefaultPageProps> = ({ settings }) => {
  const [deleteContract, setDeleteContract] = useState(false);
  const [contractToDelete, setContractToDelete] = useState<number | null>(null);
  const [addContract, setAddContract] = useState(false);
  const [successAddMessage, setSuccessMessage] = useState<string>('');
  const { t } = useTranslation('ns');
  const consumers = useApi({
    path: `/consumers`,
  });

  const deleteConsumers = useApi({
    path: `/consumer`,
    callback: () => {
      setDeleteContract(false);
      setContractToDelete(null);
      consumers.get();
    },
    defaultSuccessMessage: t('consumer-delete-success'),
    defaultErrorMessage: t('consumer-delete-failure'),
  });

  useEffect(() => {
    consumers.get();
  }, []);

  const deleteConsumer = () => {
    if (contractToDelete !== null) {
      deleteConsumers.deleteApi(contractToDelete);
    }
  };

  const DeleteContract = (contract: number) => {
    setDeleteContract(true);
    setContractToDelete(contract);
  };

  return (
    <>
    { isLogged() ? (
      <PageContainer className="overflow-auto">
        <PageTitle
          title={t('manage-contracts')}
          description={settings?.app_settings?.manage_contracts_paragraph?.value}
          className="lg:mb-3"
        />
        <h3 className=" text-lg mb-6 ">{t('contracts-account')}</h3>
        {consumers.loading && (
          <div className="w-full py-20 flex items-center justify-center">
            <Spinner size="normal" color="dark" />
          </div>
        )}
        {!consumers.loading && consumers?.data?.consumers && (
          <>
            {consumers?.data?.consumers.map((consumer: ConsumerType) => {
              return (
                <div key={consumer.id}>
                  <ConsumersTable consumer={consumer} deleteConsumer={DeleteContract} />
                  <ConsumersTableMobile consumer={consumer} deleteConsumer={DeleteContract} />
                </div>
              );
            })}
          </>
        )}
        {deleteConsumers.errors && (
          <Message type={MessageStyle.Error} className="my-6" onCloseFunction={deleteConsumers.closeMessages}>
            {deleteConsumers.errors}
          </Message>
        )}
        {deleteConsumers.successMessage && (
          <Message type={MessageStyle.Success} className="my-6" onCloseFunction={deleteConsumers.closeMessages}>
            {deleteConsumers.successMessage}
          </Message>
        )}
        {successAddMessage && (
          <Message
            type={MessageStyle.Success}
            className="my-6"
            onCloseFunction={() => {
              setSuccessMessage('');
            }}
          >
            {successAddMessage}
          </Message>
        )}
        <div className="py-8">
          {!addContract && (
            <Button
              size={ButtonSize.Large}
              variant={ButtonStyle.SafePrimary}
              title="Adaugă punct de consum"
              className="my-0 grow bg-emerald-600"
              onClick={() => {
                setAddContract(true);
              }}
            >
              + {t('add-consumption-point')}
            </Button>
          )}
          {addContract && (
            <AddContract
              settings={settings}
              close={() => {
                setAddContract(false);
                consumers.get();
              }}
              updateSuccessAddMesage={setSuccessMessage}
            />
          )}
        </div>
        {deleteContract && (
          <Popup
            onOk={deleteConsumer}
            onClose={() => {
              setContractToDelete(null);
              setDeleteContract(false);
            }}
            cancelText={t('no')}
            okText={t('yes')}
            title={t('confirm')}
          >
            {t('contract-removal-verification')}
          </Popup>
        )}
      </PageContainer> ) :
      <LoadingOverlay/> }
    </>
  );
};

export default ManageContracts;
