import React, { useMemo, useState } from 'react';
import tailwindClassNames from '../../utils/tailwindClassNames';
import Icon from '../Icon/Icon';
import { ErorFlag, IconsTypes, InputProps, InputTypes } from '../../utils/props';

export default function Input(props: InputProps) {
  const {
    type = InputTypes.Text,
    className,
    value,
    placeholder,
    onChange,
    title,
    label,
    readOnly,
    disabled,
    hasError,
    wrapperClass,
    unit,
    labelClass,
    right,
    onBlur,
    unitPosition = '',
    renderLeftIcon,
    isMandatory,
  } = props;
  const [showPassword, setShowPassword] = useState(false);

  const classNames = useMemo(() => {
    const defaultInputStyle =
      'border rounded py-2 px-3  min-w-full form-input focus:ring-2 focus:ring-blue-900 placeholder-gray-400 text-gray-900 appearance-none rounded-lg';

    let rightText = '';

    if (right) {
      rightText = `text-right pr-10`;
    }

    let error = '';

    if (hasError) {
      error = 'border-red-500 focus:ring-red-600';
    }

    let isNumber = '';

    if (type === InputTypes.Number) {
      isNumber = 'NumberDisableArrows';
    }

    let leftIconStyles = '';
    if (renderLeftIcon) {
      leftIconStyles = 'pl-10';
    }

    return tailwindClassNames(defaultInputStyle, error, rightText, isNumber, leftIconStyles, className || '');
  }, [className, hasError, right, unit, type]);

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  return (
    <div className={wrapperClass}>
      {label && (
        <label className={tailwindClassNames('pb-1 pt-1 text-base font-medium flex', labelClass || '')}>
          {label}
          {isMandatory && (
            <div className={tailwindClassNames('ml-1', unitPosition)}>
              <Icon icon={IconsTypes.Mandatory} size="6" className="fill-red-800 w-1.5 h-1.5" />
            </div>
          )}
        </label>
      )}
      <div className="relative">
        {renderLeftIcon && (
          <div className="absolute pl-3 inset-y-0 left-0 flex items-center text-sm leading-5">{renderLeftIcon()}</div>
        )}
        <input
          type={type === InputTypes.Password ? (!showPassword ? InputTypes.Password : InputTypes.Text) : type}
          className={classNames}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          title={title}
          aria-label={title || ''}
          readOnly={readOnly}
          disabled={disabled}
          onBlur={onBlur}
        />
        {type === InputTypes.Password && (
          <span
            onClick={togglePasswordVisibility}
            className={`absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 cursor-pointer `}
          >
            {showPassword ? (
              <div>
                <Icon icon={IconsTypes.DashedEyeFull} size="16" className="fill-gray-500 w-4 h-4" />
              </div>
            ) : (
              <div>
                <Icon icon={IconsTypes.EyeFull} size="16" className="fill-gray-500 w-4 h-4" />
              </div>
            )}
          </span>
        )}

        {hasError && (
          <span
            onClick={togglePasswordVisibility}
            className={tailwindClassNames(
              'absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 cursor-pointer',
              InputTypes.Password ? 'right-5' : ''
            )}
          >
            <Icon icon={IconsTypes.Danger} size="16" className="fill-red-600 w-4 h-4" />
          </span>
        )}
        {unit && !hasError && (
          <div className={tailwindClassNames('absolute top-[11px] right-4 font-medium text-gray-500', unitPosition)}>
            {unit}
          </div>
        )}
      </div>
      {hasError && hasError !== ErorFlag.DontDisaplyErrorText && <div className="text-red-600">{hasError}</div>}
    </div>
  );
}
