import React from 'react';
import { ConsumersTableDataProps, ClientTypes } from '../../utils/props';
import Icon from '../../components/Icon/Icon';
import { IconsTypes } from '../../utils/props';
import { useTranslation } from 'react-i18next';

const ConsumersTableMobile: React.FC<ConsumersTableDataProps> = ({ consumer, deleteConsumer }) => {
  const { t } = useTranslation('ns');
  const tagRowClass =
    'py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3 border-t border-t-gray-300';
  const valueRowClass = 'whitespace-nowrap px-3 py-4 text-sm text-gray-500 border-t border-t-gray-300 bg-gray-50';
  return (
    <div className="py-4 block lg:hidden">
      <h2 className="font-medium pb-4">{consumer.alias}</h2>
      <div>
        <div className={tagRowClass}>{t('subscriber-type')}</div>
        <div className={valueRowClass}>{ClientTypes[consumer.ace_client_type]}</div>
        <div className={tagRowClass}>{t('subscriber-code')}</div>
        <div className={valueRowClass}>{consumer.invoicing_code}</div>
        <div className={tagRowClass}>{t('subscriber-address')}</div>
        <div className={valueRowClass}>{consumer.address}</div>
        <div
          className="w-full px-3 py-1 text-sm text-center justify-center text-red-600 cursor-pointer flex items-center font-medium bg-red-100 mt-3 rounded-md"
          onClick={() => {
            deleteConsumer(consumer.id);
          }}
        >
          <Icon icon={IconsTypes.Exit} size={16} className="fill-red-600 w-4 h-4 mr-1" />
          {t('delete-contract')}
        </div>
      </div>
    </div>
  );
};

export default ConsumersTableMobile;
