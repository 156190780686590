import {
  SettingsType,
  TransactionsTableFilters,
  TransactionStatuses,
  TransactionStatusLabels,
} from '../../../utils/props';
import CustomSelect from '../../../components/CustomSelect';
import React, { SetStateAction, useMemo } from 'react';
import DateSelection from '../../../components/Table/DateSelection';
import { useTranslation } from 'react-i18next';

export default function Filters({
  filters,
  setFilters,
  settings,
}: {
  filters?: TransactionsTableFilters;
  setFilters: React.Dispatch<SetStateAction<TransactionsTableFilters>>;
  settings: SettingsType;
}) {
  const updateFilters = (name: string, value: unknown) => {
    setFilters((filters: TransactionsTableFilters) => ({
      ...filters,
      [name]: value,
    }));
  };

  const { t } = useTranslation('ns');

  const transactionStatusOptions = useMemo(
    () => [
      {
        label: TransactionStatusLabels.CREATED,
        value: settings?.payment_statuses?.[TransactionStatuses.CREATED],
      },
      {
        label: TransactionStatusLabels.SENT_TO_BTPAY,
        value: settings?.payment_statuses?.[TransactionStatuses.SENT_TO_BTPAY],
      },
      {
        label: TransactionStatusLabels.FINALIZED,
        value: settings?.payment_statuses?.[TransactionStatuses.FINALIZED],
      },
    ],
    [settings]
  );

  return (
    <div className="flex gap-5">
      <DateSelection name="created_dt" label={t('payment-date-2')} filters={filters} setFilters={setFilters} />
      <CustomSelect
        placeholder={t('status')}
        className="min-w-[150px]"
        hasBorder
        name="status"
        onSelect={updateFilters}
        options={transactionStatusOptions}
        value={filters?.['status']}
        isClearable
      />
    </div>
  );
}
