import { useEffect } from 'react';
import { AppRoutes, ButtonSize, ButtonStyle, DefaultPageProps, MessageStyle } from '../../utils/props';
import useApi from '../../hooks/use-api';
import Message from '../../components/Message/Message';
import CustomImg from '../../components/CustomImg/CustomImg';
import Spinner from '../../components/Spinner/Spinner';
import Button from '../../components/Button/Button';
import PageTitle from '../../components/PageTitle';
import { useTranslation } from 'react-i18next';

const RecoverAccount: React.FC<DefaultPageProps> = ({ settings }) => {
  const currentURL = window.location.href;
  const params = currentURL.split('/');
  const { t } = useTranslation('ns');
  const lastParam = params[params.length - 1];

  const RecoverAccountApi = useApi({
    path: `user/recover`,
    defaultSuccessMessage: t('recover-account-success'),
  });

  useEffect(() => {
    RecoverAccountApi.put({ token: lastParam });
  }, []);

  return (
    <div className="md:flex block">
      <CustomImg
        large={settings?.app_settings?.authentication_image?.large}
        mobile={settings?.app_settings?.authentication_image?.mobile}
        className="md:w-3/5 md:h-auto min-h-60 h-auto"
        alt="This is the main image"
      />
      <div className="flex flex-col content-center justify-center md:w-2/5 w-full p-10 lg:p-12 xl:p-16">
        <PageTitle
          title={t('account-recovery')}
          description={settings?.app_settings?.recover_account_paragraph?.value}
        />

        {RecoverAccountApi.loading && (
          <div className="w-full py-20 flex items-center justify-center">
            <Spinner size="normal" color="dark" />
          </div>
        )}
        {RecoverAccountApi.errors && (
          <Message type={MessageStyle.Error} className="mb-6">
            {RecoverAccountApi.errors}
          </Message>
        )}
        {RecoverAccountApi.successMessage && (
          <Message type={MessageStyle.Success} className="mb-6">
            {RecoverAccountApi.successMessage}
          </Message>
        )}
        {!RecoverAccountApi.loading && (
          <Button
            size={ButtonSize.LargeFull}
            variant={ButtonStyle.Primary}
            title={t('account-enter')}
            className="mb-6"
            href={AppRoutes.Login}
          >
            {t('go-to-auth')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default RecoverAccount;
