import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import Checkbox from '../../components/Checkbox/Checkbox';
import {
  AppRoutes,
  ButtonSize,
  ButtonStyle,
  ButtonTypes,
  ErorFlag,
  InputTypes,
  LoginPageProps,
  MessageStyle,
} from '../../utils/props';
import CustomImg from '../../components/CustomImg/CustomImg';
import useApi from '../../hooks/use-api';
import Message from '../../components/Message/Message';
import { isAdmin, validateEmail, validatePass } from '../../utils/utils';
import { useTranslation } from 'react-i18next';
import Spinner from '../../components/Spinner/Spinner';

const LoginPage: React.FC<LoginPageProps> = ({ settings, getSettingsAfterLogin, updateUserType }) => {
  const [userName, setUserName] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [rememberMe, setRememberMe] = useState<boolean>(localStorage.getItem('remember_me') === 'true');
  const [emailError, setEmailError] = useState<string>('');
  const [passwordEmail, setPasswordEmail] = useState<string>('');
  const { t } = useTranslation('ns');
  const navigate = useNavigate();

  const login = useApi({
    path: '/login',
    defaultErrorMessage: t('login-incorrect-data'),
    callback: (data) => {
      localStorage.setItem('token', JSON.stringify(data.auth_token));
      localStorage.setItem('refresh_token', JSON.stringify(data.refresh_token));
      localStorage.setItem('remember_me', JSON.stringify(rememberMe));
      localStorage.setItem('user_id', JSON.stringify(data.user_id));
      localStorage.setItem('user_type', JSON.stringify(data.user_type));
      updateUserType(`${data.user_type}`);
      getSettingsAfterLogin(`${data.user_type}`);
      if (isAdmin(settings, `${data.user_type}`)) {
        navigate(AppRoutes.AdministrationUsers);
      } else {
        navigate(AppRoutes.Invoices);
      }
    },
  });

  const onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    login.post({
      mail: userName,
      key: password,
      auth_method: 0,
    });
  };

  const onRedirectToRecoverPassword = () => {
    navigate(AppRoutes.RecoverPassword);
  };

  if(localStorage.getItem('user_type') !== null){
    return (
      <div className="w-full h-full py-20 flex items-center justify-center">
        <Spinner color='dark' size='normal'></Spinner>
      </div>
    )
  }
  return (
    <div className="lg:flex block">
      <CustomImg
        large={settings?.app_settings?.authentication_image?.large}
        mobile={settings?.app_settings?.authentication_image?.mobile}
        className="md:w-3/5 md:h-auto min-h-60 h-auto"
        alt="this is the main img "
        loading="eager"
      />
      <div className="flex flex-col content-center justify-center lg:w-2/5 w-full p-4 lg:p-12 xl:p-16">
        <h1 className="mb-2 text-2xl font-bold text-black-900">{t('my-account')}</h1>
        <p className="mb-6 text-gray-600 text-sm">{settings?.app_settings?.login_paragraph?.value}</p>
        <form onSubmit={onSubmit} className="flex flex-col gap-6">
          <Input
            type={InputTypes.Text}
            onChange={(e) => {
              setUserName(e.target.value);
            }}
            value={userName}
            title={t('email')}
            label={t('email')}
            placeholder={t('email-placeholder')}
            hasError={login.errors ? ErorFlag.DontDisaplyErrorText : emailError ? emailError : ''}
            onBlur={() => {
              setEmailError(validateEmail(userName));
            }}
          />
          <Input
            type={InputTypes.Password}
            value={password}
            placeholder={t('the-password')}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            title={t('the-password')}
            label={t('the-password')}
            hasError={login.errors ? ErorFlag.DontDisaplyErrorText : passwordEmail ? passwordEmail : ''}
            onBlur={() => {
              setPasswordEmail(validatePass(userName));
            }}
          />
          <div className="flex w-100 justify-between">
            <Checkbox
              value={rememberMe}
              onChange={(newValue: boolean) => setRememberMe(newValue)}
              label={t('remember-me')}
            />

            <div className="text-blue-900 font-medium cursor-pointer" onClick={onRedirectToRecoverPassword}>
              {t('forgot-password')}
            </div>
          </div>
          {login.errors && (
            <Message type={MessageStyle.Error} className="mb-6" onCloseFunction={login.closeMessages}>
              {login.errors}
            </Message>
          )}
          <Button
            size={ButtonSize.LargeFull}
            variant={ButtonStyle.Primary}
            loading={login.loading}
            title={t('account-enter')}
            type={ButtonTypes.Submit}
            className="text-base mb-6"
          >
            {t('account-enter')}
          </Button>
        </form>
        <div className="flex items-center mb-6">
          <div className="h-0 grow border-b border-gray-300" />
          <div className="px-2 text-gray-500 text-base">{t('or')}</div>
          <div className="h-0 grow border-b border-gray-300" />
        </div>
        <Button
          size={ButtonSize.LargeFull}
          variant={ButtonStyle.Secondary}
          onClick={() => {
            navigate(AppRoutes.Register);
          }}
          title={t('account-create-new')}
          className="text-base"
        >
          {t('account-create-new')}
        </Button>
      </div>
    </div>
  );
};

export default LoginPage;
