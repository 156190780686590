import React, { ChangeEvent, useState, useRef, useEffect } from 'react';
import tailwindClassNames from '../../utils/tailwindClassNames';
import { CheckboxProps } from '../../utils/props';

export default function Checkbox({
  value = false,
  onChange,
  label,
  className = '',
  disabled = false,
  hasError,
  classNameWrapper = '',
}: CheckboxProps) {
  const [localValue, setLocalValue] = useState(value);
  // checkboxId will be generated only once at the initial mounting and will not change on updates
  const checkboxId = useRef(`checkbox-${Math.random().toString(36).substring(7)}`).current;

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!disabled) {
      const newValue = e.target.checked;
      setLocalValue(newValue);
      onChange(newValue);
    }
  };

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  return (
    <div className={tailwindClassNames('flex items-center', classNameWrapper)}>
      <input
        id={checkboxId}
        name={checkboxId}
        type="checkbox"
        className={tailwindClassNames(
          'form-checkbox h-4 w-4 rounded border-gray-300 text-blue-900 focus:ring-blue-600 cursor-pointer',
          disabled ? 'opacity-50 pointer-events-none' : '',
          hasError ? ' text-red-500 border-red-500' : '',
          className
        )}
        checked={localValue}
        onChange={handleCheckboxChange}
      />
      {label && (
        <label htmlFor={checkboxId} className="ml-2 cursor-pointer select-none">
          {label}
        </label>
      )}
    </div>
  );
}
