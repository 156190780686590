import { useState, FormEvent } from 'react';
import { apiCallbackFunction, clearInputsFunction, inputValidationFunction } from '../utils/props';

interface configInterface {
  [key: string]: inputValidationFunction;
}

interface fieldsInterface {
  [key: string]: string;
}

export default function useValidation(config: configInterface, callback: apiCallbackFunction) {
  const fields: fieldsInterface = {};

  for (const key in config) {
    fields[key] = '';
  }

  const [inputs, setInputs] = useState({ ...fields });
  const [errors, setErrors] = useState({ ...fields });

  const updateInputs = (input: string, value: string) => {
    setInputs({
      ...inputs,
      [input]: value,
    });
  };

  const clearInputs: clearInputsFunction = () => {
    setInputs({
      ...fields,
    });
  };

  const validateInputs = () => {
    const err: fieldsInterface = {};

    for (const key in config) {
      if (key === 'passConfirm') {
        err[key] = config[key](inputs[key], inputs['pass']);
      } else {
        err[key] = config[key](inputs[key]);
      }
    }

    setErrors({ ...err });

    let isFormValid = false;

    for (const key in err) {
      if (err[key].length === 0) {
        isFormValid = true;
      } else {
        isFormValid = false;
        break;
      }
    }

    return {
      err,
      isFormValid,
    };
  };

  const handleFormSubmit = (e: FormEvent) => {
    e.preventDefault();

    const result = validateInputs();

    if (!result.isFormValid) {
      return;
    }

    callback(clearInputs);
  };

  return {
    inputs,
    errors,
    updateInputs,
    handleFormSubmit,
    clearInputs,
  };
}
