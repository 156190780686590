import React, { useMemo, useState, useEffect } from 'react';
import { MessageProps, MessageStyle } from '../../utils/props';
import Icon from '../Icon/Icon';
import tailwindClassNames from '../../utils/tailwindClassNames';

export default function Message(props: MessageProps) {
  const { type, children, className, autoclose = false, onCloseFunction } = props;
  const [showMessage, setShowMessage] = useState(true);

  const messageClassNames = useMemo(() => {
    const defaultMessageStyle = 'flex items-center  px-4 py-3 text-sm rounded-md min-w-full';

    const messageStyle = {
      [MessageStyle.Success]: 'text-emerald-800 bg-emerald-50',
      [MessageStyle.Warning]: 'text-amber-800 bg-amber-100',
      [MessageStyle.Error]: 'text-red-800 bg-red-50',
      [MessageStyle.Info]: 'text-blue-800 bg-blue-50',
    };

    return tailwindClassNames(defaultMessageStyle, messageStyle[type], className || '');
  }, [type, className]);

  const iconClassNames = useMemo(() => {
    const defaultIconStyle = 'shrink-0 w-4 h-4';

    const iconStyle = {
      [MessageStyle.Success]: 'fill-emerald-400',
      [MessageStyle.Warning]: 'fill-amber-400',
      [MessageStyle.Error]: 'fill-red-400',
      [MessageStyle.Info]: 'fill-blue-400',
    };

    return tailwindClassNames(defaultIconStyle, iconStyle[type]);
  }, [type]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (autoclose) {
      timeout = setTimeout(() => {
        setShowMessage(false);
        if (onCloseFunction) {
          onCloseFunction();
        }
      }, 3000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [autoclose, onCloseFunction]);

  const handleClose = () => {
    setShowMessage(false);
    if (onCloseFunction) {
      onCloseFunction();
    }
  };

  return (
    <>
      {showMessage && (
        <p className={messageClassNames}>
          <span className="flex-grow flex gap-x-3.5 items-center ">
            <Icon icon={type} size={16} className={iconClassNames} />
            {children}
          </span>
          {onCloseFunction && (
            <button onClick={handleClose} type="button" className="flex pt-[3px] h-full">
              <Icon icon={'exit'} size={16} className={iconClassNames} />
            </button>
          )}
        </p>
      )}
    </>
  );
}
