import { useEffect } from 'react';
import { DefaultPageProps, MessageStyle } from '../../utils/props';
import useApi from '../../hooks/use-api';
import Message from '../../components/Message/Message';
import CustomImg from '../../components/CustomImg/CustomImg';
import Spinner from '../../components/Spinner/Spinner';
import { useTranslation } from 'react-i18next';

const DeleteConfirmation: React.FC<DefaultPageProps> = ({ settings }) => {
  const currentURL = window.location.href;
  const params = currentURL.split('/');

  const lastParam = params[params.length - 1];

  const { t } = useTranslation('ns');

  const DeleteConfirmApi = useApi({
    path: `user/confirm/${lastParam}`,
    defaultSuccessMessage: t('account-deletion-confirmation'),
  });

  useEffect(() => {
    DeleteConfirmApi.deleteApi(undefined, false);
  }, []);

  return (
    <div className="md:flex block">
      <CustomImg
        large={settings?.app_settings?.authentication_image?.large}
        mobile={settings?.app_settings?.authentication_image?.mobile}
        className="md:w-3/5 md:h-auto min-h-60 h-auto"
        alt="This is the main image"
      />
      <div className="flex flex-col content-center justify-center md:w-2/5 w-full p-10 lg:p-12 xl:p-16">
        <h1 className="mb-2 text-2xl font-bold text-blue-900 ">{t('confirm-account-removal-user')} </h1>
        {DeleteConfirmApi.loading && (
          <div className="w-full py-20 flex items-center justify-center">
            <Spinner size="normal" color="dark" />
          </div>
        )}
        {DeleteConfirmApi.errors && (
          <Message type={MessageStyle.Error} className="mb-6">
            {DeleteConfirmApi.errors}
          </Message>
        )}
        {DeleteConfirmApi.successMessage && (
          <Message type={MessageStyle.Success} className="mb-6">
            {DeleteConfirmApi.successMessage}
          </Message>
        )}
      </div>
    </div>
  );
};

export default DeleteConfirmation;
