import React from 'react';
import backround from '../../assets/images/404.svg';
import tailwindClassNames from '../../utils/tailwindClassNames';
import Button from '../../components/Button/Button';
import { ButtonSize, ButtonStyle } from '../../utils/props';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ErrorPage = () => {
  const { t } = useTranslation('ns');
  const navigate = useNavigate();
  return (
    <div className="relative h-full">
      <img
        src={backround}
        className={tailwindClassNames('absolute top-0 left-0 w-full h-full fill z-0 object-cover')}
      />
      <div className="absolute top-0 left-0 z-10 w-full h-full flex flex-col items-center justify-around">
        <div className="flex flex-col items-center">
          <div className="text-blue-900 text-5xl font-bold">{t('we-apologize')}</div>
          <div className="text-blue-900 text-2xl font-bold">{t('page-not-found')}</div>
        </div>
        <div className="">
          <Button
            size={ButtonSize.Large}
            variant={ButtonStyle.Primary}
            onClick={() => {
              navigate(-1);
            }}
            title={t('go-back')}
          >
            <span>{t('go-back')}</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
