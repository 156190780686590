import Popup from '../../../components/Popup/Popup';
import React, { useEffect, useState } from 'react';
import { Consumer, ErorFlag, MessageStyle, UseAPIReturnType } from '../../../utils/props';
import Input from '../../../components/Input/Input';
import useApi from '../../../hooks/use-api';
import { useTranslation } from 'react-i18next';
import Message from '../../../components/Message/Message';

export default function ResetPasswordPopup({ onClose, consumer }: { onClose: () => void; consumer?: Consumer }) {
  const [changingPassword, setChangingPassword] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [passwordRepeat, setPasswordRepeat] = useState<string>('');
  const [touchedPassword, setTouchedPassword] = useState<boolean>(false);
  const [touchedConfirmPassword, setTouchedConfirmPassword] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const { t } = useTranslation('ns');

  const adminAuthMethodApi: UseAPIReturnType<null | undefined> = useApi({
    path: `/admin/auth_method`,
  });

  useEffect(() => {
    if (!consumer) {
      setChangingPassword(false);
      setSuccess(false);
      setTouchedPassword(false);
      setTouchedConfirmPassword(false);
      setError('');
      setPassword('');
      setPasswordRepeat('');
    }
  }, [consumer]);

  useEffect(() => {
    if (password.length < 6 && touchedPassword) {
      setError(t('password-length-error'));
    } else if (password !== passwordRepeat && touchedConfirmPassword) {
      setError(t('password-match-error'));
    } else {
      setError('');
    }
  }, [password, passwordRepeat, touchedPassword, touchedConfirmPassword]);

  const changePassword = async () => {
    // Not tested
    try {
      setError('');
      setSuccess(false);
      setChangingPassword(true);

      await adminAuthMethodApi.put({
        new_key: password,
        user_id: consumer?.user_id,
      });

      setSuccess(true);
    } catch (e: any) {
      setError(e?.response?.data?.message);
    }
  };

  if (!consumer) {
    return null;
  }

  return (
    <Popup
      onOk={success ? undefined : changePassword}
      onClose={onClose}
      disableOk={!!error || !password || !passwordRepeat}
      loading={changingPassword}
      cancelText={success ? t('close') : t('cancel')}
      okText={t('modify')}
      title={t('edit-subscriber')}
    >
      <form onSubmit={changePassword} className="flex w-full flex-col justify-start gap-5 pt-3">
        <p className="text-left">
          <span className="font-semibold">{t('subscriber-name')}: </span>
          {consumer.name}
        </p>
        <div className="flex flex-col gap-5">
          {success ? (
            <Message type={MessageStyle.Success} className="text-left">
              {t('password-modified-successfully')}
            </Message>
          ) : (
            <>
              <Input
                labelClass="text-left"
                type="password"
                value={password}
                placeholder={t('password')}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                onBlur={() => setTouchedPassword(true)}
                title={t('new-password')}
                label={t('new-password')}
                hasError={error ? ErorFlag.DontDisaplyErrorText : ''}
              />
              <Input
                labelClass="text-left"
                type="password"
                value={passwordRepeat}
                placeholder={t('password')}
                onChange={(e) => {
                  setPasswordRepeat(e.target.value);
                }}
                onBlur={() => setTouchedConfirmPassword(true)}
                title={t('confirm-password')}
                label={t('confirm-password')}
                hasError={error ? ErorFlag.DontDisaplyErrorText : ''}
              />
            </>
          )}
          {!!error && (
            <Message type={MessageStyle.Error} className="text-left">
              {error}
            </Message>
          )}
        </div>
      </form>
    </Popup>
  );
}
