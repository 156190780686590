import React from 'react';
import { ButtonStyle, ButtonSize, IconsTypes, SectionHeadersProps } from '../../utils/props';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import tailwindClassNames from '../../utils/tailwindClassNames';
import { useTranslation } from 'react-i18next';

const SectionHeaders: React.FC<SectionHeadersProps> = ({
  action,
  isInEdit,
  title,
  buttonLabel,
  activTitle,
  children,
  className = '',
  danger,
  success,
  buttonIcon = IconsTypes.Edit,
  updateSettings
}) => {
  const { t } = useTranslation('ns');
  return (
    <div className={tailwindClassNames('flex justify-between items-center', className)}>
      {title && <div className="font-bold max-w-1/2">{activTitle ? (isInEdit ? activTitle : title) : title}</div>}
      {!title && children}

      {!isInEdit && (
        <Button
          size={ButtonSize.SmallFixed}
          variant={danger ? ButtonStyle.DangerPrimary : success ? ButtonStyle.SafePrimary : ButtonStyle.Primary}
          title={t('edit-info')}
          labelClass="flex items-center"
          onClick={() => {
            action(true);
          }}
        >
          <Icon icon={buttonIcon} size={buttonIcon === IconsTypes.Edit ? 14 : 16} className="fill-white w-4 h-4 mr-1" />
          {buttonLabel}
        </Button>
      )}
      {isInEdit && (
        <Button
          size={ButtonSize.SmallFixed}
          variant={ButtonStyle.DangerSecundary}
          title={t('edit-info')}
          labelClass="flex items-ceter"
          onClick={() => {
            updateSettings();
            action(false);
          }}
        >
          <Icon icon={IconsTypes.Exit} size={16} className="fill-white w-4 h-4 mr-1 mt-0.5" />
          Anulează
        </Button>
      )}
    </div>
  );
};

export default SectionHeaders;
