import React, { useState } from 'react';
import {
  SettingsAccountPasswordProps,
  InputTypes,
  ButtonStyle,
  ButtonSize,
  MessageStyle,
  IconsTypes,
} from '../../utils/props';
import tailwindClassNames from '../../utils/tailwindClassNames';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import useApi from '../../hooks/use-api';
import Message from '../../components/Message/Message';
import Icon from '../../components/Icon/Icon';
import { useTranslation } from 'react-i18next';
const PasswordChange: React.FC<SettingsAccountPasswordProps> = ({ editMode }) => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [validationError, SetValidationError] = useState('');
  const { t } = useTranslation('ns');
  const updatePassword = useApi({
    path: `/auth_method`,
    callback: () => {
      setOldPassword('');
      setNewPassword('');
      setConfirmPassword('');
    },
    defaultSuccessMessage: 'Parola a fost modificată cu succes',
  });

  const ChangePassword = () => {
    let validationError = '';
    if (oldPassword === '' || newPassword === '' || confirmPassword === '') {
      validationError = t('all-fields-mandatory');
    } else if (newPassword === oldPassword) {
      validationError = t('password-new-password-different');
    } else if (newPassword !== confirmPassword) {
      validationError = t('password-not-identical');
    } else if (newPassword.length < 6) {
      validationError = t('password-new-minimum-char');
    }
    SetValidationError(validationError);
    const dataToUpload = {
      auth_method: 0,
      old_key: oldPassword,
      new_key: newPassword,
    };
    if (!validationError) {
      updatePassword.put(dataToUpload);
    }
  };

  return (
    <div
      className={tailwindClassNames(
        'w-full p-6 lg:p-6 block lg:flex bg-gray-100 my-2 rounded-xl flex-wrap',
        editMode ? 'border-2 border-blue-900' : ''
      )}
    >
      {validationError && editMode && <Message type={MessageStyle.Error}>{validationError}</Message>}
      {updatePassword.errors && editMode && <Message type={MessageStyle.Error}>{updatePassword.errors}</Message>}
      {updatePassword.successMessage && editMode && (
        <Message type={MessageStyle.Success} onCloseFunction={updatePassword.closeMessages}>
          {updatePassword.successMessage}
        </Message>
      )}
      {!validationError && !updatePassword.successMessage && updatePassword.errors && editMode && (
        <Message className="mb-2" type={MessageStyle.Info}>
          {t('password-length-error')}
        </Message>
      )}
      {!editMode && (
        <div className="flex w-full">
          <div className="grow font-normal">{t('password-length-error')}</div>
        </div>
      )}

      {editMode && (
        <div className="w-full">
          <div className="w-full hidden lg:flex">
            <div className="full lg:w-2/4 lg:max-w-[180px]">
              <div className="py-4 flex">
                {t('password-current')}
                <span className="ml-1">
                  <Icon icon={IconsTypes.Mandatory} size="6" className="fill-red-800 w-1.5 h-1.5" />
                </span>
              </div>
              <div className="py-4 flex">
                {t('password-new')}
                <span className="ml-1">
                  <Icon icon={IconsTypes.Mandatory} size="6" className="fill-red-800 w-1.5 h-1.5" />
                </span>
              </div>
              <div className="py-4 flex">
                {t('password-new-confirm')}
                <span className="ml-1">
                  <Icon icon={IconsTypes.Mandatory} size="6" className="fill-red-800 w-1.5 h-1.5" />
                </span>
              </div>
            </div>
            <div className="w-full lg:w-2/4 lg:max-w-[280px]">
              <Input
                type={InputTypes.Password}
                wrapperClass="px-1 py-2"
                value={oldPassword}
                onChange={(e) => {
                  setOldPassword(e.target.value);
                }}
                hasError={updatePassword.errors || validationError ? '_' : ''}
                isMandatory
              />
              <Input
                type={InputTypes.Password}
                wrapperClass="px-1 py-2"
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                }}
                hasError={updatePassword.errors || validationError ? '_' : ''}
                isMandatory
              />
              <Input
                type={InputTypes.Password}
                wrapperClass="px-1 py-2"
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
                hasError={updatePassword.errors || validationError ? '_' : ''}
                isMandatory
              />
            </div>
          </div>
          <div className="block lg:hidden ">
            <Input
              type={InputTypes.Password}
              wrapperClass="py-2"
              value={oldPassword}
              label={t('password-current')}
              labelClass="font-bold"
              onChange={(e) => {
                setOldPassword(e.target.value);
              }}
              isMandatory
            />
            <Input
              type={InputTypes.Password}
              wrapperClass="py-2"
              value={newPassword}
              label={t('password-new')}
              labelClass="font-bold"
              onChange={(e) => {
                setNewPassword(e.target.value);
              }}
              isMandatory
            />
            <Input
              type={InputTypes.Password}
              wrapperClass="py-2"
              value={confirmPassword}
              label={t('password-new-confirm')}
              labelClass="font-bold"
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
              isMandatory
            />
          </div>
        </div>
      )}

      {editMode && (
        <div className="w-full pt-2">
          <Button
            size={ButtonSize.Default}
            variant={ButtonStyle.SafePrimary}
            title={t('save')}
            onClick={ChangePassword}
            className="hidden lg:block"
          >
            {t('save')}
          </Button>

          <Button
            size={ButtonSize.DefaultFull}
            variant={ButtonStyle.SafePrimary}
            title="Salvează"
            onClick={ChangePassword}
            className="lg:hidden flex"
          >
            {t('save')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default PasswordChange;
