import React, { useState } from 'react';
import { SettingsAccountPasswordProps, ButtonStyle, ButtonSize, MessageStyle, InputTypes } from '../../utils/props';
import useApi from '../../hooks/use-api';
import tailwindClassNames from '../../utils/tailwindClassNames';
import Button from '../../components/Button/Button';
import Message from '../../components/Message/Message';
import Popup from '../../components/Popup/Popup';
import Input from '../../components/Input/Input';
import { validatePass } from '../../utils/utils';
import { useTranslation } from 'react-i18next';

const DeleteAccount: React.FC<SettingsAccountPasswordProps> = ({ editMode }) => {
  const { t } = useTranslation('ns');
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [password, setPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');

  const userApi = useApi({
    path: `/user`,
    callback: () => {
      setOpenConfirmation(false);
    },
    defaultSuccessMessage: t('account-deletion-email'),
  });

  const DeleteUser = () => {
    if (!passwordError) {
      userApi.deleteApi('', { key: password });
    }
  };

  return (
    <div
      className={tailwindClassNames(
        'w-full p-6 lg:p-6 block lg:flex bg-gray-100 my-2 rounded-xl flex-wrap',
        editMode ? 'border-2 border-blue-900' : ''
      )}
    >
      <div className="flex w-full">
        <div className="grow font-normal ">
          <div>{t('account-deletion-prompt')}</div>
          <div>{t('account-deletion-cancel')}</div>
        </div>
      </div>
      {userApi.errors && editMode && <Message type={MessageStyle.Error}>{userApi.errors}</Message>}
      {userApi.successMessage && editMode && <Message type={MessageStyle.Success}>{userApi.successMessage}</Message>}
      <div className="flex w-full justify-end">
        {editMode && (
          <Button
            size={ButtonSize.Default}
            variant={ButtonStyle.DangerPrimary}
            title={t('remove-2')}
            labelClass="flex"
            onClick={() => {
              userApi.closeMessages();
              setOpenConfirmation(true);
            }}
          >
            {t('remove-2')}
          </Button>
        )}
      </div>
      {openConfirmation && !userApi.errors && (
        <Popup
          onOk={DeleteUser}
          onClose={() => {
            setOpenConfirmation(false);
          }}
          cancelText={t('cancel')}
          okText={t('account-remove')}
          title={t('account-remove-question')}
        >
          <div>
            <div>{t('account-deletion-prompt')}</div>
            <div>{t('account-deletion-cancel')}</div>
            <div className="text-left pt-10">
              <Input
                type={InputTypes.Password}
                value={password}
                placeholder={t('the-password')}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                onBlur={(e: any) => { // eslint-disable-line
                  setPasswordError(validatePass(e.target.value));
                }}
                title={t('the-password')}
                label={t('the-password')}
                hasError={passwordError ? passwordError : ''}
              />
            </div>
          </div>
        </Popup>
      )}
    </div>
  );
};

export default DeleteAccount;
