import React, { useState } from 'react';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import {
  AppRoutes,
  ButtonSize,
  ButtonStyle,
  ButtonTypes,
  DefaultPageProps,
  ErorFlag,
  MessageStyle,
} from '../../utils/props';
import { validateEmail } from '../../utils/utils';
import useApi from '../../hooks/use-api';
import Message from '../../components/Message/Message';
import { useNavigate } from 'react-router-dom';
import CustomImg from '../../components/CustomImg/CustomImg';
import PageTitle from '../../components/PageTitle';
import { useTranslation } from 'react-i18next';

const RecoverPassword: React.FC<DefaultPageProps> = ({ settings }) => {
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string>('');
  const { t } = useTranslation('ns');
  const recoverPasswordApi = useApi({
    path: `/password_token`,
    defaultSuccessMessage: t('email-sent-confirmation'),
    defaultErrorMessage: t('error-problem'),
  });

  const onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    setError(validateEmail(email));
    if (!validateEmail(email)) {
      recoverPasswordApi.post({
        mail: email,
      });
    }
  };

  const navigate = useNavigate();

  return (
    <div className="lg:flex block">
      <CustomImg
        large={settings?.app_settings?.authentication_image?.large}
        mobile={settings?.app_settings?.authentication_image?.mobile}
        className="lg:w-3/5 lg:h-auto min-h-60 h-auto"
        alt="This is the main image"
      />
      <div className="flex justify-center w-full lg:w-2/5 p-4 lg:p-12 xl:p-16">
        <div className="w-full">
          <PageTitle
            title={t('password-recover')}
            description={settings?.app_settings?.recover_password_paragraph?.value}
          />

          <form onSubmit={onSubmit} className="flex flex-col gap-6">
            <Input
              type="text"
              value={email}
              placeholder={t('email')}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              title={t('email')}
              label={t('email')}
              hasError={recoverPasswordApi.errors ? ErorFlag.DontDisaplyErrorText : error}
            />
            {recoverPasswordApi.errors && (
              <Message type={MessageStyle.Error} onCloseFunction={recoverPasswordApi.closeMessages}>
                {recoverPasswordApi.errors}
              </Message>
            )}
            {recoverPasswordApi.successMessage && (
              <Message type={MessageStyle.Info} onCloseFunction={recoverPasswordApi.closeMessages}>
                {recoverPasswordApi.successMessage}
              </Message>
            )}
            <Button
              size={ButtonSize.LargeFull}
              variant={ButtonStyle.Primary}
              loading={recoverPasswordApi.loading}
              title={t('password-recover')}
              type={ButtonTypes.Submit}
              className="text-base"
            >
              {t('password-recover')}
            </Button>
            <div className="flex items-center">
              <div className="h-0 grow border-b border-gray-300" />
              <div className="px-2 text-gray-500 text-base">{t('or')}</div>
              <div className="h-0 grow border-b border-gray-300" />
            </div>
            <Button
              size={ButtonSize.LargeFull}
              variant={ButtonStyle.Secondary}
              title={t('account-enter')}
              className="mb-6 text-base"
              onClick={() => {
                navigate(AppRoutes.Login);
              }}
            >
              {t('go-back-auth')}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RecoverPassword;
