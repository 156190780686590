import React, { useState, ChangeEvent, useMemo, useEffect, useRef } from 'react';
import tailwindClassNames from '../../utils/tailwindClassNames';
import { DropdownProps, IconsTypes } from '../../utils/props';
import Icon from '../Icon/Icon';

export default function Dropdown({
  value,
  options,
  onChange,
  className = '',
  label,
  hasError,
  wrapperClassName = 'flex content-center',
  labelClassName = '',
  large,
  noInitialValue,
  placeholder = '',
  onBlur,
  isMandatory,
}: DropdownProps) {
  const [selectedValue, setSelectedValue] = useState(!noInitialValue ? value : '');
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownId = useRef(`dropdown-${Math.random().toString(36).substring(7)}`);

  const classNames = useMemo(() => {
    const defaultInputStyle =
      'bg-white border rounded py-1 px-2 min-w-full form-input focus:ring-2 focus:ring-blue-900 placeholder-black-400 text-black-500 appearance-none rounded-lg cursor-pointer pr-10';
    const defaultHoverStyle = '';
    let noSelectedValueClass = '';
    let error = '';
    if (hasError) {
      error = 'border-red-500 focus:ring-red-600';
    }
    if (selectedValue === '') {
      noSelectedValueClass = 'text-black-400';
    }
    if (isOpen) {
      noSelectedValueClass = 'text-black-500';
    }
    return tailwindClassNames(
      defaultInputStyle,
      defaultHoverStyle,
      className,
      large ? 'mt-1 py-2 px-3.5' : '',
      error,
      noSelectedValueClass
    );
  }, [className, hasError, selectedValue, isOpen]);

  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const newValue = e.target.value;
    setSelectedValue(newValue);
    onChange(newValue);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const DropDownArrowClasses = useMemo(() => {
    const defaultClassNames =
      'absolute inset-y-0 right-3 top-3  flex items-center pointer-events-none  w-4 h-4 cursor-pointer';
    let conditionalyRenderd = '';
    if (isOpen) {
      conditionalyRenderd = '';
      if (large) {
        conditionalyRenderd = 'top-5 ';
      }
    } else {
      conditionalyRenderd = 'rotate-180 top-2 right-5';
      if (large) {
        conditionalyRenderd = 'rotate-180 top-4 right-5';
      }
    }
    return tailwindClassNames(defaultClassNames, conditionalyRenderd);
  }, [isOpen, large]);

  return (
    <div className={tailwindClassNames('flex items-center', wrapperClassName)}>
      {label && (
        <label
          htmlFor={dropdownId.current}
          className={tailwindClassNames('flex text-base text-center font-medium pr-2', labelClassName)}
        >
          {label}
          {isMandatory && !hasError && (
            <div className={tailwindClassNames('ml-1')}>
              <Icon icon={IconsTypes.Mandatory} size="6" className="fill-red-800 w-1.5 h-1.5" />
            </div>
          )}
        </label>
      )}
      <div className={tailwindClassNames('relative grow')} ref={dropdownRef}>
        <select
          id={dropdownId.current}
          name={dropdownId.current}
          className={classNames}
          value={selectedValue}
          onChange={handleSelectChange}
          onClick={toggleDropdown}
          placeholder={placeholder}
          onBlur={onBlur}
        >
          {selectedValue === '' && (
            <option className=" text-gray-400 italic pointer-events-none" value="">
              {placeholder}
            </option>
          )}
          {options?.map((option) => (
            <option key={option.value} value={option.value} className="option">
              {option.label}
            </option>
          ))}
        </select>
        <div className={DropDownArrowClasses}>
          <div className="w-2 h-2 border-t border-r border-gray-500 text-gray-500 transform  -rotate-45"></div>
        </div>
      </div>
      <div className={`text-red-600 ${hasError === '_' || !hasError ? 'hidden' : ''}`}>{hasError ? hasError : ''}</div>
    </div>
  );
}
