import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api/api';
import { ApiStatus, AppRoutes, ConsentErrors, predifenedErrorMessageForGdpr, predifenedErrorMessageForTos, UseAPIReturnType } from '../utils/props';
import { isAuthPage, handleLogout } from '../utils/utils';
import { useTranslation } from 'react-i18next';

type configInterface = {
  path: string;
  callback?: (data: any) => void;// eslint-disable-line
  defaultSuccessMessage?: string;
  errorCallback?: (error: any) => void;// eslint-disable-line
  defaultErrorMessage?: string;
};

export default function useApi(config: configInterface): UseAPIReturnType<any> {
  const { path, callback, defaultSuccessMessage, errorCallback, defaultErrorMessage } = config;
  const [data, setData] = useState<any>(null)// eslint-disable-line
  const [errors, setErrors] = useState<string | undefined>(undefined);
  const [fildsAffected, setFildsAffected] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
  const navigate = useNavigate();
  const { t } = useTranslation('ns');
  let numberOfTrys = 0;

  const handleError = (error: any, action: string, data: any | null) => {// eslint-disable-line
    setSuccessMessage('');
    if (error.response) {
      const statusCode = error.response.status;

      if (error?.response?.data?.error) {
        setFildsAffected(error.response.data.error);
      }

      let errorMessage = undefined;
      const rememberMeString: string = localStorage.getItem('remember_me') || 'false';
      const remember: boolean = JSON.parse(rememberMeString);
          
      switch (statusCode) {
        case 400:
          errorMessage = defaultErrorMessage || error?.response?.data?.message || 'Bad Request';
          break;
        case 401:
          // Save error message
          if (error.response.data.error) {
            errorMessage = error.response.data.message || defaultErrorMessage;
          }

          if (
            // If TOS error, navigate there
            error?.response?.data?.error.includes(ConsentErrors.Tos)
            ) {
            navigate(AppRoutes.ConsentTOS);
          } else if (
            // If GDPR error, navigate there
            error?.response?.data?.error.includes(ConsentErrors.Gdpr)
          ) {
            navigate(AppRoutes.ConsentGDPR);
          } else if (remember && numberOfTrys < 5) {
            // Try to refresh token
            numberOfTrys = numberOfTrys + 1;
            refreshToken(action, data);
          } else if (!isAuthPage()) { 
            // If failed to refresh token, go to login
            handleLogout(navigate);
          }
          break;
        case 403:
          errorMessage = error.response.data.message || t('api-errors.403-forbidden');
          break;
        case 404:
          errorMessage = error.response.data.message || t('api-errors.404-not-found');
          break;
        case 500:
          errorMessage = error.response.data.message || t('api-errors.500-interal-error');
          break;
        default:
          errorMessage = defaultErrorMessage || t('error-generic');
          break;
      }
      
      setErrors(errorMessage);
    } else if (error.request) {
      setErrors(t('api-errors.500-interal-error'));
    } else {
      setErrors(t('api-errors.500-interal-error'));
    }

    setLoading(false);

    if (errorCallback) {
      errorCallback(error);
    }
  };

  const refreshToken = (action?: string, data?: any | null, other?: any) => {// eslint-disable-line
    const refresh_token: string = localStorage.getItem('refresh_token') || '';
    if (refresh_token) {
      api
        .post('/refresh_auth_token', { refresh_token: JSON.parse(refresh_token) })
        .then((response) => {
          localStorage.setItem('token', JSON.stringify(response.data.auth_token));
          localStorage.setItem('refresh_token', JSON.stringify(response.data.refresh_token));
          if (action === 'get') {
            get();
          } else if (action === 'post') {
            post(data);
          } else if (action === 'put') {
            put(data);
          } else if (action === 'delete') {
            deleteApi(data, other);
          }
        })
        .catch((error) => {
          handleError(error, 'post', null);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      navigate(AppRoutes.Login);
    }
  };

  const get = (query?: string) => {
    setLoading(true);
    const storedToken: string | null = localStorage.getItem('token');
    const headers = storedToken ? { Authorization: `Bearer ${JSON.parse(storedToken)}` } : {};

    return api
      .get(`${path}${query || ''}`, { headers })
      .then((response) => {
        if (response.data.status === ApiStatus.Ok) {
          setSuccessMessage(defaultSuccessMessage || undefined);
          setData(response.data);
          setErrors(undefined);
          if (callback) {
            callback(response.data);
          }
        }

        return response;
      })
      .catch((error) => {
        handleError(error, 'get', null);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const post = (data: any) => {// eslint-disable-line
    setLoading(true);
    const storedToken: string | null = localStorage.getItem('token');
    const headers = storedToken ? { Authorization: `Bearer ${JSON.parse(storedToken)}` } : {};

    api
      .post(path, data, { headers })
      .then((response) => {
        if (response.data.status === ApiStatus.Ok) {
          setSuccessMessage(defaultSuccessMessage || undefined);
          setData(response.data);
          setErrors(undefined);
          if (callback) {
            callback(response.data);
          }
        }
      })
      .catch((error) => {
        handleError(error, 'post', data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const put = async(data: any) => {// eslint-disable-line
    setLoading(true);
    const storedToken: string | null = localStorage.getItem('token');
    const headers = storedToken ? { Authorization: `Bearer ${JSON.parse(storedToken)}` } : {};

    await api
      .put(path, data, { headers })
      .then((response) => {
        if (response.data.status === ApiStatus.Ok) {
          setSuccessMessage(defaultSuccessMessage || undefined);
          setData(response.data);
          setErrors(undefined);
          if (callback) {
            callback(response.data);
          }
        }
      })
      .catch((error) => {
        handleError(error, 'put', data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const patch = async(id?: string | number, data?: any) => {// eslint-disable-line
    setLoading(true);
    const storedToken: string | null = localStorage.getItem('token');
    const headers = storedToken ? { Authorization: `Bearer ${JSON.parse(storedToken)}` } : {};

    await api
      .patch(`${id ? `${path}/${id}` : path}`, data, { headers })
      .then((response) => {
        if (response.data.status === ApiStatus.Ok) {
          setSuccessMessage(defaultSuccessMessage || undefined);
          setData(response.data);
          setErrors(undefined);
          if (callback) {
            callback(response.data);
          }
        }
      })
      .catch((error) => {
        handleError(error, 'put', data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteApi = async(id?: string | number, params?: any) => {// eslint-disable-line
    setLoading(true);
    const storedToken: string | null = localStorage.getItem('token');
    const headers = storedToken ? { Authorization: `Bearer ${JSON.parse(storedToken)}` } : {};
    const content = { ...params };

    await api
      .delete(`${id ? `${path}/${id}` : path}`, { headers, data: content })
      .then((response) => {
        if (response.data.status === ApiStatus.Ok) {
          setSuccessMessage(defaultSuccessMessage || undefined);
          setData(response.data);
          setErrors(undefined);
          if (callback) {
            callback(response.data);
          }
        }
      })
      .catch((error) => {
        handleError(error, 'delete', id);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const closeMessages = () => {
    setSuccessMessage(undefined);
    setErrors(undefined);
  };

  return {
    data,
    errors,
    loading,
    successMessage,
    fildsAffected,
    get,
    post,
    put,
    patch,
    deleteApi,
    closeMessages,
  };
}
