import React, { SetStateAction, useMemo, useState } from 'react';
import { ButtonSize, ButtonStyle, IconsTypes, PaginationProps, UseAPIReturnType, User } from '../../../utils/props';
import Icon from '../../../components/Icon/Icon';
import Button from '../../../components/Button/Button';
import Popup from '../../../components/Popup/Popup';
import { useTranslation } from 'react-i18next';

export default function SelectedRowsActions({
  usersApi,
  selected,
  hasSelectedAllRowsOnPage,
  hasSelectedAllRowsOnAllPages,
  setHasSelectedAllRowsOnAllPages,
  resetSelected,
  meta,
  resetPage,
}: {
  usersApi: UseAPIReturnType<User>;
  selected: number[];
  hasSelectedAllRowsOnPage: boolean;
  hasSelectedAllRowsOnAllPages: boolean;
  setHasSelectedAllRowsOnAllPages: React.Dispatch<SetStateAction<boolean>>;
  resetSelected: () => void;
  meta: PaginationProps;
  resetPage: () => void;
}) {
  const [confirmDeactivateAccounts, setConfirmDeactivateAccounts] = useState(false);
  const [confirmDeleteAccounts, setConfirmDeleteAccounts] = useState(false);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation('ns');

  const AllRowsSelectionActions = useMemo(() => {
    if (hasSelectedAllRowsOnAllPages) {
      return (
        <div className="flex text-gray-500 gap-1 font-bold">
          {t('selected-results-warning', { items_no: meta.items_no })}
          <button className="text-blue-900" type="button" onClick={resetSelected}>
            {t('deselect-all-results', { items_no: meta.items_no })}
          </button>
        </div>
      );
    }

    if (hasSelectedAllRowsOnPage) {
      return (
        <div className="flex text-gray-500 gap-1">
          {t('selected-results-prompt', { items_no: selected?.length })}
          <button className="text-blue-900" type="button" onClick={() => setHasSelectedAllRowsOnAllPages(true)}>
            {t('select-all-results', { items_no: meta.items_no })}
          </button>
        </div>
      );
    }

    return null;
  }, [selected, hasSelectedAllRowsOnPage, hasSelectedAllRowsOnAllPages]);

  const deactivateAccounts = async () => {
    setLoading(true);
    // Not tested
    await Promise.all(
      selected.map((id) =>
        usersApi.patch(id, {
          deleted: 1,
        })
      )
    );
    setConfirmDeactivateAccounts(false);
    resetSelected();
    resetPage();
    setLoading(false);
  };

  const deleteAccounts = async () => {
    setLoading(true);
    // Not tested
    await Promise.all(selected.map((id) => usersApi.deleteApi(id)));
    setConfirmDeleteAccounts(false);
    resetSelected();
    resetPage();
    setLoading(false);
  };

  if (!selected?.length) {
    return null;
  }

  return (
    <div className="flex justify-between items-center gap-4">
      <div className="flex gap-2.5 items-center">
        <Button
          size={ButtonSize.Small}
          variant={ButtonStyle.DangerPrimary}
          title={t('deactivate')}
          onClick={() => setConfirmDeactivateAccounts(true)}
        >
          <Icon
            icon={IconsTypes.MinusCircle}
            size="16"
            className="h-5 w-5 text-white [&_path]:fill-white"
            aria-hidden="true"
          />
          {t('deactivate')}
        </Button>
        <Button
          size={ButtonSize.Small}
          variant={ButtonStyle.DangerPrimary}
          title={t('remove')}
          onClick={() => setConfirmDeleteAccounts(true)}
        >
          <Icon icon={IconsTypes.Trash} size="16" className="h-5 w-5 text-white fill-white" aria-hidden="true" />
          {t('remove')}
        </Button>
      </div>
      {AllRowsSelectionActions}
      {confirmDeactivateAccounts && (
        <Popup
          onOk={deactivateAccounts}
          onClose={() => {
            setConfirmDeactivateAccounts(false);
          }}
          loading={loading}
          cancelText={t('no')}
          okText={t('yes')}
          title={t('confirm')}
        >
          <p>{t('deactivate-account-prompt')}</p>
          {hasSelectedAllRowsOnAllPages && (
            <p className="font-bold">{t('deactivate-all-accounts-warning', { items_no: meta.items_no })}</p>
          )}
        </Popup>
      )}
      {confirmDeleteAccounts && (
        <Popup
          onOk={deleteAccounts}
          onClose={() => {
            setConfirmDeleteAccounts(false);
          }}
          loading={loading}
          cancelText={t('no')}
          okText={t('yes')}
          title={t('confirm')}
        >
          <p>{t('remove-all-accounts-confirmation')}</p>
          {hasSelectedAllRowsOnAllPages && (
            <p className="font-bold">{t('remove-all-accounts-warning', { items_no: meta.items_no })}</p>
          )}
        </Popup>
      )}
    </div>
  );
}
