import { useEffect } from 'react';
import Button from '../../components/Button/Button';
import { AppRoutes, ButtonSize, ButtonStyle, DefaultPageProps, MessageStyle } from '../../utils/props';
import useApi from '../../hooks/use-api';
import Message from '../../components/Message/Message';
import CustomImg from '../../components/CustomImg/CustomImg';
import Spinner from '../../components/Spinner/Spinner';
import { useTranslation } from 'react-i18next';

const Verify: React.FC<DefaultPageProps> = ({ settings }) => {
  const params = new URLSearchParams(window.location.search);
  const { t } = useTranslation('ns');
  const verify = useApi({
    path: `verify/${params.get('mail_token')}`,
    defaultSuccessMessage: t('email-verification-success'),
  });

  useEffect(() => {
    verify.put({});
  }, []);

  return (
    <div className="md:flex block">
      <CustomImg
        large={settings?.app_settings?.authentication_image?.large}
        mobile={settings?.app_settings?.authentication_image?.mobile}
        className="md:w-3/5 md:h-auto min-h-60 h-auto"
        alt="This is the main image"
      />
      <div className="flex flex-col content-center justify-center md:w-2/5 w-full p-10 lg:p-12 xl:p-16">
        <h1 className="mb-2 text-2xl font-bold text-blue-900 ">{t('email-verification')}</h1>
        {verify.loading && (
          <div className="w-full py-20 flex items-center justify-center">
            <Spinner size="normal" color="dark" />
          </div>
        )}
        {verify.errors && (
          <Message type={MessageStyle.Error} className="mb-6">
            {verify.errors}
          </Message>
        )}
        {verify.successMessage && (
          <Message type={MessageStyle.Success} className="mb-6">
            {verify.successMessage}
          </Message>
        )}
        {!verify.loading && (
          <Button
            size={ButtonSize.LargeFull}
            variant={ButtonStyle.Primary}
            title={t('account-enter')}
            className="mb-6"
            href={AppRoutes.Login}
          >
            {t('go-to-auth')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default Verify;
