import { IconsTypes, PaginationNavigationButtonActions, PaginationPagesBarProps } from '../../../utils/props';
import Icon from '../../Icon/Icon';
import React, { useMemo } from 'react';
import PaginationPageButton from './PaginationPageButton';
import PaginationPageEllipsis from './PaginationPageEllipsis';
import PaginationNavigationButton from './PaginationNavigationButton';

export default function PaginationPagesBar<T, U>({ setPage, meta }: PaginationPagesBarProps<T, U>) {
  const { current_page, total_pages } = meta;

  const pages = useMemo(() => Array.from({ length: total_pages }, (_, i) => i + 1), [total_pages]);

  if (total_pages === 1) {
    return null;
  }

  return (
    <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
      <PaginationNavigationButton action={PaginationNavigationButtonActions.PREVIOUS} meta={meta} setPage={setPage}>
        <Icon
          icon={IconsTypes.ArrowNarrowLeft}
          size="16"
          className="mr-3 h-5 w-5 text-gray-400 fill-gray-400"
          aria-hidden="true"
        />
        Pagina anterioară
      </PaginationNavigationButton>
      <div className="hidden md:-mt-px md:flex">
        {pages?.map((page) => {
          // 1 & 10 are always visible
          // current page +1 & -1 are always visible
          // if current page is 1, then display 2 & 3
          // if current page is total_pages, then display total_pages - 1 & total_pages - 2
          // current page is highlighted

          if (!total_pages) {
            return null;
          }

          if (page === 1 || page === total_pages) {
            return <PaginationPageButton key={page} page={page} active={page === current_page} setPage={setPage} />;
          }

          if (current_page === 1 || current_page === total_pages) {
            if (page === 2 || page === total_pages - 1 || page === 3 || page === total_pages - 2) {
              return <PaginationPageButton key={page} page={page} active={page === current_page} setPage={setPage} />;
            }

            if (page === 4) {
              return <PaginationPageEllipsis key={page} />;
            }

            return null;
          }

          if (page === current_page || page === current_page - 1 || page === current_page + 1) {
            return <PaginationPageButton key={page} page={page} active={page === current_page} setPage={setPage} />;
          }

          if (page === current_page - 2 || page === current_page + 2) {
            return <PaginationPageEllipsis key={page} />;
          }

          return null;
        })}
      </div>
      <PaginationNavigationButton
        action={PaginationNavigationButtonActions.NEXT}
        meta={meta}
        setPage={setPage}
        className="justify-end"
      >
        Pagina următoare
        <Icon
          icon={IconsTypes.ArrowNarrowRight}
          size="16"
          className="ml-3 h-5 w-5 text-gray-400 fill-gray-400"
          aria-hidden="true"
        />
      </PaginationNavigationButton>
    </nav>
  );
}
