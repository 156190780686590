import './i18n';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import RouterComponent from './components/Router/Router';

function App() {
  return (
    <div className="h-full overflow-y-scroll">
      <BrowserRouter>
        <RouterComponent />
      </BrowserRouter>
    </div>
  );
}

export default App;
