import React, { useEffect, useState } from 'react';
import { CounterTypes, MessageStyle, SendIndexProps } from '../../utils/props';
import { deepClone, getNextMonth, isLogged } from '../../utils/utils';
import useApi from '../../hooks/use-api';
import SendIndexTableWeb from './SendIndexTableWeb';
import SendIndexTableMobile from './SendIndexTableMobile';
import Spinner from '../../components/Spinner/Spinner';
import LoadingOverlay from '../../components/Spinner/LoadingOverlay';
import Message from '../../components/Message/Message';
import Popup from '../../components/Popup/Popup';
import PageTitle from '../../components/PageTitle';
import PageContainer from '../../components/PageContainer';
import { useTranslation } from 'react-i18next';

const SendIndex: React.FC<SendIndexProps> = ({ currentLocation, invoice_period, settings }) => {
  const [countersData, setCountersData] = useState<CounterTypes[] | []>([]);
  const [validationError, setValidationError] = useState('');
  const [editCounterLocation, setEditCounterLocation] = useState<number>(-1);
  const [counterIndexId, setCounterIndexId] = useState<number | undefined>();
  const [updateLocationId, setUpdateLocationId] = useState<number | undefined>();
  const [counterLocation, setCounterLocation] = useState<string>('');
  const [openConfirmation, setOpenConfirmation] = useState<number | undefined>();
  const [errorIndex, setErrorIndex] = useState<number | undefined>();
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const MaximumDif = 70;
  const { t } = useTranslation('ns');
  const counters = useApi({
    path: `/counters?consumer_id=${currentLocation}`,
    callback: (data) => {
      const counters = data.counters;
      setCountersData(deepClone(counters));
      setIsFirstLoading(false);
    },
  });

  const updateLocation = useApi({
    path: `/counter/${updateLocationId}`,
    callback: () => {
      setUpdateLocationId(undefined);
      updateCounters();
    },
    defaultSuccessMessage: t('consumer-change-success'),
  });

  const updateIndexApi = useApi({
    path: `/counter/${openConfirmation ? openConfirmation : counterIndexId}`,
    callback: () => {
      setCounterIndexId(undefined);
      updateCounters();
      setOpenConfirmation(undefined);
      setValidationError('');
      setErrorIndex(undefined);
    },
    defaultSuccessMessage: t('consumer-index-success'),
  });

  useEffect(() => {
    if (!openConfirmation) {
      sendIndexValue();
    }
  }, [counterIndexId]);

  useEffect(() => {
    if (updateLocationId) {
      updateLocation.put({ label: counterLocation, consumer_id: currentLocation });
    }
  }, [updateLocationId]);

  useEffect(() => {
    updateCounters();
  }, [currentLocation]);

  const updateCounters = () => {
    if (currentLocation) {
      counters.get();
    }
  };

  const updateIndexValue = (id: number, value: number) => {
    const countersCopy: CounterTypes[] | [] = deepClone(countersData);
    const counterToChange: CounterTypes | undefined = countersCopy.find((val) => val.id === id);
    if (counterToChange) {
      counterToChange.new_index = value;
    }
    setCountersData(countersCopy);
  };

  const sendIndex = (id: number) => {
    const counterToSend: CounterTypes | undefined = countersData.find((val) => val.id === id);
    let totalConsumtion = 0;

    if (!counterToSend) {
      return;
    }
    totalConsumtion = counterToSend.new_index - Number(counterToSend.old_index);

    if (totalConsumtion < 0) {
      setValidationError(t('consumer-value-lower-than-before'));
      setErrorIndex(id);
      return;
    }
    if (totalConsumtion > MaximumDif) {
      setOpenConfirmation(id);
      return;
    }
    setCounterIndexId(id);
  };

  const saveCounterLocation = (id: number) => {
    setUpdateLocationId(id);
    setEditCounterLocation(-1);
  };

  const sendIndexValue = () => {
    const counterToSend: CounterTypes | undefined = countersData.find(
      (val) => val.id === counterIndexId || val.id === openConfirmation
    );
    if (counterIndexId || openConfirmation) {
      updateIndexApi.put({ new_index: counterToSend?.new_index, consumer_id: currentLocation });
    }
  };

  return (
    <>
    { isLogged() ? (
      <PageContainer>
        <PageTitle title={t('send-index')} description={settings?.app_settings?.update_counter_paragraph?.value} />
        <Message type={!invoice_period ? MessageStyle.Warning : MessageStyle.Success}>
          <span className="font-medium">
            {!invoice_period
              ? t('consumer-in-reading-period.section-1-no')
              : t('consumer-in-reading-period.section-1-yes')}
            {t('consumer-in-reading-period.section-2')}{' '}
            <a href={settings?.counter_period_url} target="_black" className="underline">
              {t('consumer-in-reading-period.section-3')}
            </a>
          </span>
        </Message>
        <h2 className="py-2 font-bold">{t('self-read')}</h2>
        {counters.loading && isFirstLoading && (
          <div className="w-full py-20 flex items-center justify-center">
            <Spinner size="normal" color="dark" />
          </div>
        )}

        {(!counters.loading || !isFirstLoading) && (
          <>
            <SendIndexTableWeb
              countersData={countersData}
              validationError={validationError}
              editCounterLocation={editCounterLocation}
              setEditCounterLocation={setEditCounterLocation}
              counterLocation={counterLocation}
              setCounterLocation={setCounterLocation}
              sendIndex={sendIndex}
              loading={counters.loading}
              saveCounterLocation={saveCounterLocation}
              loadingLocation={updateLocation.loading}
              invoice_period={invoice_period}
              updateIndexValue={updateIndexValue}
              errorIndex={errorIndex}
            />
            <SendIndexTableMobile
              countersData={countersData}
              validationError={validationError}
              editCounterLocation={editCounterLocation}
              setEditCounterLocation={setEditCounterLocation}
              counterLocation={counterLocation}
              setCounterLocation={setCounterLocation}
              sendIndex={sendIndex}
              saveCounterLocation={saveCounterLocation}
              loading={counters.loading}
              loadingLocation={updateLocation.loading}
              invoice_period={invoice_period}
              updateIndexValue={updateIndexValue}
              errorIndex={errorIndex}
            />
          </>
        )}
        {updateLocation.errors && (
          <Message type={MessageStyle.Error} onCloseFunction={updateLocation.closeMessages}>
            {updateLocation.errors}
          </Message>
        )}
        {updateLocation.successMessage && !counters.loading && (
          <Message type={MessageStyle.Success} onCloseFunction={updateLocation.closeMessages} className="my-3">
            {updateLocation.successMessage}
          </Message>
        )}
        {updateIndexApi.errors && (
          <Message type={MessageStyle.Error} onCloseFunction={updateIndexApi.closeMessages}>
            {updateIndexApi.errors}
          </Message>
        )}
        {updateIndexApi.successMessage && !counters.loading && (
          <Message type={MessageStyle.Success} onCloseFunction={updateIndexApi.closeMessages}>
            {updateIndexApi.successMessage}
          </Message>
        )}
        {openConfirmation && (
          <Popup
            onOk={sendIndexValue}
            onClose={() => {
              setOpenConfirmation(undefined);
            }}
            cancelText={t('no')}
            okText={t('yes')}
            title={t('confirm')}
          >
            {t('consumer-value-higher-than-before')}
          </Popup>
        )}
      </PageContainer> ) :
      <LoadingOverlay/> }
    </>
  );
};

export default SendIndex;
