import React from 'react';
import { AdminImgProps } from '../../utils/props';
// import tailwindClassNames from '../../utils/tailwindClassNames';
import FileUploadComponent from './FileUploadComponent';
import useApi from '../../hooks/use-api';
import Spinner from '../../components/Spinner/Spinner';
import { useTranslation } from 'react-i18next';

const AdminImg: React.FC<AdminImgProps> = ({ large = '', largeId, updateSettings }) => {
  const { t } = useTranslation('ns');
  const substring: string[] = large.split('/');
  const { put, loading, errors, successMessage, closeMessages } = useApi({
    path: `/setting/${largeId}`,
    callback: (data) => { // eslint-disable-line
      updateSettings();
    },
    defaultSuccessMessage: t('image-added-successfully-message'),
  });

  return (
    <div className="w-full p-6 lg:p-6 block lg:flex bg-gray-100 my-2 rounded-xl flex-wrap">
      <div className="w-full">
        <div className="gap-2 flex">
          <div className="grow pr-16">
            <p className="text-base text-gray-900 font-semibold pb-5">{t('add-new-image')}</p>
            <FileUploadComponent
              accept=".jpg, .jpeg, .png, .svg"
              warning={t('image-recommended-dimension-warning')}
              endpointPut={put}
              endpointErrors={errors}
              endpointSuccessMessage={successMessage}
              endpointCloseMessages={closeMessages}
            />
          </div>
          <div>
            <p className="text-base text-gray-900 font-semibold pb-5">{t('current-image')}</p>
            {loading && <Spinner size="normal" color="dark" />}
            {!loading && (
              <div className="overflow-hidden rounded-md max-w-[270px] ">
                <img src={large} alt={t('no-saved-images-alt')} className="object-cover w-full h-full" />
              </div>
            )}
            <p className="text-sm text-gray-400 pt-2">{substring[substring.length - 1]}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminImg;
