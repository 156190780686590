import React from 'react';
import { OnlineInvoiceProps, MessageStyle, IconsTypes } from '../../utils/props';

import tailwindClassNames from '../../utils/tailwindClassNames';
import Message from '../../components/Message/Message';
import Popup from '../../components/Popup/Popup';
import Icon from '../../components/Icon/Icon';
import { useTranslation } from 'react-i18next';

const OnlineInvoice: React.FC<OnlineInvoiceProps> = ({
  data,
  openConfirmation,
  success,
  error,
  setOpenConfirmation,
  updateOnlineInvoice,
  closeMessage,
  activate,
}) => {
  const { t } = useTranslation('ns');
  return (
    <div className={tailwindClassNames('w-full px-6 py-6 block lg:flex bg-gray-100 my-2 rounded-xl flex-wrap')}>
      <div className="flex w-full items-center">
        <div className="grow font-normal flex items-center ">
          {data?.online_invoice !== 0 && (
            <Icon icon={IconsTypes.Success} size={16} className="fill-green-400 w-4 h-4 mr-3 mt-0.5" />
          )}
          {!data?.online_invoice && (
            <Icon icon={IconsTypes.exitCircle} size={18} className="fill-red-500 w-4 h-4 mr-3 mt-0.5" />
          )}
          {data?.online_invoice !== 0 ? t('invoice-online.is-active') : t('invoice-online.is-not-active')}
          {data?.online_invoice === 0 && (
            <button type="button" onClick={activate} className="text-blue-700 cursor-pointer underline ml-6">
              {t('invoice-online.activate-here')}
            </button>
          )}
        </div>
      </div>
      {error && (
        <Message className="mt-2" type={MessageStyle.Error} onCloseFunction={closeMessage}>
          {error}
        </Message>
      )}
      {success && (
        <Message className="mt-2" type={MessageStyle.Success} onCloseFunction={closeMessage}>
          {success}
        </Message>
      )}

      {openConfirmation && (
        <Popup
          onOk={() => {
            updateOnlineInvoice(0);
          }}
          onClose={() => {
            setOpenConfirmation(false);
          }}
          cancelText={t('no-first-upper')}
          okText={t('yes-first-upper')}
          title={t('invoice-online-disable-question')}
        >
          {t('invoice-online-disable')}
        </Popup>
      )}
    </div>
  );
};

export default OnlineInvoice;
