import React, { useEffect, useState } from 'react';
import { ButtonSize, ButtonStyle, ButtonTypes, DropDownOption, MessageStyle, SettingsType } from '../../utils/props';
import Button from '../../components/Button/Button';
import Message from '../../components/Message/Message';
import useApi from '../../hooks/use-api';
import Dropdown from '../../components/Dropdown/Dropdown';
import { useTranslation } from 'react-i18next';

export type EditDescriptionProps = {
  settings?: SettingsType;
};

const EditDescription: React.FC<EditDescriptionProps> = ({ settings }) => {
  const [value, setValue] = useState('');
  const [selecetdSetting, setSelecetdSetting] = useState('');
  const [optionsPage, setOptionsPage] = useState<DropDownOption[]>([]);
  const { t } = useTranslation('ns');
  const maxCharLength = 1000;

  useEffect(() => {
    if (settings?.app_settings?.login_paragraph?.id) {
      setOptionsPage([
        {
          label: t('authentication'),
          value: settings?.app_settings.login_paragraph.id,
          data: settings?.app_settings.login_paragraph.value,
        },
        {
          label: t('account-creation'),
          value: settings?.app_settings.register_paragraph.id,
          data: settings?.app_settings.register_paragraph.value,
        },
        {
          label: t('password-recovery'),
          value: settings?.app_settings.recover_password_paragraph.id,
          data: settings?.app_settings.recover_password_paragraph.value,
        },
        {
          label: t('account-recovery'),
          value: settings?.app_settings.recover_account_paragraph.id,
          data: settings?.app_settings.recover_account_paragraph.value,
        },
        {
          label: t('terms-and-conditions'),
          value: settings?.app_settings.terms_and_conditions_paragraph.id,
          data: settings?.app_settings.terms_and_conditions_paragraph.value,
        },
        {
          label: t('gdpr-policy'),
          value: settings?.app_settings.gdpr_update_paragraph.id,
          data: settings?.app_settings.gdpr_update_paragraph.value,
        },
        {
          label: t('invoice-list'),
          value: settings?.app_settings.invoices_paragraph.id,
          data: settings?.app_settings.invoices_paragraph.value,
        },
        {
          label: t('send-index'),
          value: settings?.app_settings.sent_invoices_paragraph.id,
          data: settings?.app_settings.sent_invoices_paragraph.value,
        },
        {
          label: t('manage-contracts'),
          value: settings?.app_settings.manage_contracts_paragraph.id,
          data: settings?.app_settings.manage_contracts_paragraph.value,
        },
        {
          label: t('account-settings'),
          value: settings?.app_settings.settings_paragraph.id,
          data: settings?.app_settings.settings_paragraph.value,
        },
      ]);
    }
  }, [settings]);

  const tosApi = useApi({
    path: `/setting/${selecetdSetting}`,
    defaultSuccessMessage: t('changes-saved-successfully'),
    defaultErrorMessage: t('error-generic'),
  });

  const update = () => {
    const formData = new FormData();
    formData.append('value', value);

    tosApi.put(formData);
  };

  return (
    <div className="w-full p-6 lg:p-6 flex bg-gray-100 my-2 rounded-xl">
      <div className="w-full pr-16">
        <p className="mb-2 text-sm text-gray-700 max-w-[720px] ">{t('page-name')}</p>

        <Dropdown
          options={optionsPage}
          value={selecetdSetting}
          onChange={(value) => {
            setSelecetdSetting(value);
            const optionselected = optionsPage.filter((obj) => {
              return obj.value == value;
            });

            if (optionselected) {
              setValue(optionselected[0]?.data || '');
            }
          }}
          wrapperClassName="block"
          labelClassName="text-left"
          noInitialValue
          placeholder={t('choose-page')}
          large
        />
        <p className="my-3 text-sm text-gray-700 max-w-[720px] ">{t('description')}</p>
        <textarea
          className="w-full rounded-md p-2"
          onChange={(e) => {
            setValue(e.target.value);
          }}
          value={value}
          rows={4}
          maxLength={maxCharLength}
          placeholder={t('description-max-length', { char_no: maxCharLength })}
        />
        <Button
          loading={tosApi.loading}
          type={ButtonTypes.Button}
          onClick={update}
          size={ButtonSize.Default}
          variant={ButtonStyle.SafePrimary}
          title={t('save')}
          className="flex mb-6 w-auto mt-[10px]"
        >
          {t('save')}
        </Button>
        {tosApi.successMessage && (
          <Message type={MessageStyle.Success} className="my-6" onCloseFunction={tosApi.closeMessages}>
            {tosApi.successMessage}
          </Message>
        )}
        {tosApi.errors && (
          <Message type={MessageStyle.Error} className="my-6" onCloseFunction={tosApi.closeMessages}>
            {tosApi.errors}
          </Message>
        )}
      </div>
      <div className="max-w-[270px] w-full h-1" />
    </div>
  );
};

export default EditDescription;
