import { IconsTypes, InputTypes, SearchComponentProps } from '../../utils/props';
import Input from '../Input/Input';
import React from 'react';
import Icon from '../Icon/Icon';
import tailwindClassNames from '../../utils/tailwindClassNames';
import { useTranslation } from 'react-i18next';

export default function Search({ searchInputWrapperClassName, title, search, setSearch }: SearchComponentProps) {
  const { t } = useTranslation('ns');
  return (
    <div className={'flex gap-4 items-center justify-between flex-1'}>
      <h1 className="text-2xl lg:text-3xl font-bold text-gray-900 flex-1">{title}</h1>
      <div className={tailwindClassNames('flex items-center', searchInputWrapperClassName || '')}>
        <Input
          type={InputTypes.Text}
          wrapperClass="px-1 py-2"
          className="focus:ring-0 outline-none w-[380px] max-w-full"
          value={search}
          placeholder={t('search-placeholder')}
          renderLeftIcon={() => (
            <Icon
              icon={IconsTypes.Search}
              size="16"
              className="h-5 w-5 text-gray-400 fill-gray-400"
              aria-hidden="true"
            />
          )}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      </div>
    </div>
  );
}
