import { CustomImgProps } from '../../utils/props';
import tailwindClassNames from '../../utils/tailwindClassNames';

export default function CustomImg({
  mobile,
  tablet,
  large,
  className,
  mobileClassName,
  tabletClassName,
  largeClassName,
  alt = '',
  loading,
}: CustomImgProps) {
  const defaultImgClass = 'object-cover w-full h-full';
  if (!large) {
    return null;
  }

  return (
    <>
      <picture className={className}>
        <source
          media="(max-width: 768px)"
          srcSet={mobile}
          sizes="768px"
          className={tailwindClassNames(defaultImgClass, mobileClassName || '')}
        />
        <source
          media="(max-width: 1024px)"
          srcSet={tablet}
          sizes="1024px"
          className={tailwindClassNames(defaultImgClass, tabletClassName || '')}
        />
        <img
          src={large}
          alt={alt}
          className={tailwindClassNames(defaultImgClass, largeClassName || '')}
          loading={loading}
        />
      </picture>
    </>
  );
}
