import { useMemo } from 'react';
import { ChipProps, ChipVariants } from '../utils/props';
import tailwindClassNames from '../utils/tailwindClassNames';

export default function Chip({ children, variant }: ChipProps) {
  const classNames = useMemo(() => {
    const defaultClassNames = 'inline-flex items-center gap-1 rounded-full px-2 py-1 text-xs font-medium';

    let variantClassNames = '';

    switch (variant) {
      case ChipVariants.SUCCESS: {
        variantClassNames = 'bg-green-100 text-green-700';
        break;
      }
      case ChipVariants.WARNING: {
        variantClassNames = 'bg-yellow-100 text-yellow-700';
        break;
      }
      case ChipVariants.ERROR: {
        variantClassNames = 'bg-red-100 text-red-700';
        break;
      }
      case ChipVariants.INFO: {
        variantClassNames = 'bg-blue-100 text-blue-700';
        break;
      }
      default: {
        variantClassNames = 'bg-gray-100 text-gray-700';
        break;
      }
    }

    return tailwindClassNames(defaultClassNames, variantClassNames);
  }, [variant]);

  return <span className={classNames}>{children}</span>;
}
