import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import Checkbox from '../components/Checkbox/Checkbox';
import tailwindClassNames from '../utils/tailwindClassNames';
import { CellComponentProps, RowRenderProps, SORT_ORDER, SortSelectValue } from '../utils/props';
import Sort from '../components/Table/Sort';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tippy';

export default function useTable({
  items,
  columns,
  canSelectAll,
  sortSettings,
  uniqueKeyField = 'id',
  fieldToSelect = 'id',
}: {
  items: {
    data: any;
    rowClassName?: string;
    render: (props: RowRenderProps) => ReactNode;
  }[];
  columns: {
    name: string;
    label: string;
    className?: string;
    sortable?: boolean;
  }[];
  canSelectAll?: boolean;
  sortSettings?: {
    sort?: SortSelectValue;
    setSort: (sort: SortSelectValue | undefined) => void;
  };
  uniqueKeyField?: string;
  fieldToSelect?: string;
}) {
  const [selected, setSelected] = useState<number[]>([]);
  const [hasSelectedAllRowsOnAllPages, setHasSelectedAllRowsOnAllPages] = useState(false);
  const scrollContainerRef = React.useRef<HTMLDivElement>(null);
  const { t } = useTranslation('ns');

  useEffect(() => {
    scrollContainerRef.current?.scrollTo(0, 0);
  }, [items]);

  const hasSelectedAllRowsOnPage = useMemo(
    () => hasSelectedAllRowsOnAllPages || (items?.length === selected.length && selected.length !== 0),
    [items, selected]
  );

  const selectAllRows = () => {
    if (hasSelectedAllRowsOnPage) {
      setSelected([]);
      return;
    }

    setSelected(items?.map((item) => item.data[fieldToSelect]));
  };

  const resetSelected = () => {
    setSelected([]);
    setHasSelectedAllRowsOnAllPages(false);
  };

  const CellComponent = ({ children, className, tooltipEnabled }: CellComponentProps) => {
    return (
      <div
        className={tailwindClassNames(
          'px-3 text-sm text-gray-900 min-w-[102px] max-w-[102px] break-words line-clamp-2',
          className ?? ''
        )}
      >
        {tooltipEnabled ? (
          // @ts-ignore
          <Tooltip html={children} arrow interactive>
            {children}
          </Tooltip>
        ) : (
          children
        )}
      </div>
    );
  };

  const TableComponent = () => (
    <div ref={scrollContainerRef}>
      <div className="min-w-full divide-y divide-gray-300">
        <div>
          <div className="flex items-center">
            {canSelectAll && (
              <div className="py-3.5 px-px min-w-[30px] max-w-[30px]">
                <Checkbox
                  value={hasSelectedAllRowsOnPage}
                  onChange={selectAllRows}
                  disabled={items?.length === 0}
                  classNameWrapper="w-min mx-auto"
                />
              </div>
            )}
            {columns?.map((column) => (
              <div
                key={column.name}
                className={tailwindClassNames(
                  'py-3.5 text-left text-sm font-bold text-gray-900 min-w-[102px] max-w-[102px] px-3',
                  column.sortable ? 'cursor-pointer relative select-none' : '',
                  column.className ?? ''
                )}
                tabIndex={column.sortable ? 1 : undefined}
                aria-label={column.sortable ? t('sort-by', { column: column.label }) : undefined}
                onClick={
                  column.sortable
                    ? () =>
                        sortSettings?.setSort({
                          sortBy: column.name,
                          sortOrder:
                            column.name === sortSettings?.sort?.sortBy
                              ? sortSettings?.sort?.sortOrder === SORT_ORDER.ASC
                                ? SORT_ORDER.DESC
                                : SORT_ORDER.ASC
                              : SORT_ORDER.ASC,
                        })
                    : undefined
                }
              >
                <span className="whitespace-break-spaces break-words line-clamp-2">
                  {/*@ts-ignore*/}
                  <Tooltip html={column.label} arrow interactive>
                    {column.label}
                  </Tooltip>
                </span>
                {column.sortable && sortSettings && <Sort name={column.name} sort={sortSettings?.sort} />}
              </div>
            ))}
          </div>
        </div>
        <div className="bg-white">
          {items?.map((item, i) => {
            const isSelected = selected.includes(item.data[fieldToSelect]);
            return (
              <div
                key={item.data[uniqueKeyField]}
                className={tailwindClassNames(
                  'even:bg-gray-50 flex items-center py-4',
                  isSelected ? 'bg-blue-50 even:bg-blue-50' : '',
                  item.rowClassName ?? ''
                )}
              >
                {item.render({
                  selected: isSelected,
                  onSelect: () => {
                    if (selected.includes(item.data[fieldToSelect])) {
                      setSelected(selected.filter((id) => id !== item.data[fieldToSelect]));
                    } else {
                      setSelected([...selected, item.data[fieldToSelect]]);
                    }
                  },
                  CellComponent,
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );

  return {
    selected,
    selectAllRows,
    TableComponent,
    hasSelectedAllRowsOnPage,
    hasSelectedAllRowsOnAllPages,
    setHasSelectedAllRowsOnAllPages,
    resetSelected,
  };
}
