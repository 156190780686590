import { Link, LinkProps, useLocation } from 'react-router-dom';
import Icon from '../Icon/Icon';
import tailwindClassNames from '../../utils/tailwindClassNames';
import { MenuItemPropertis, MenuItemProps } from '../../utils/props';

function MenuItem({ label, icon, to = '', opened, onClick, onCloseMenu, iconSize }: MenuItemProps) {
  const { pathname } = useLocation();
  const isActive = to && pathname.toLowerCase().includes(to.toLowerCase());

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    if (onCloseMenu) {
      onCloseMenu();
    }
  };
  const content = (
    <>
      <Icon icon={icon} className="fill-white" style={{ width: '1.5rem', height: '1.5rem' }} />
      {opened && <span className={tailwindClassNames('text-sm text-white ms-2', !opened ? 'ms-4' : '')}>{label}</span>}
    </>
  );
  const className = tailwindClassNames(
    'text-white w-full flex items-center flex-nowrap cursor-pointer h-full',
    !opened ? 'justify-center' : ''
  );
  const linkProps: LinkProps = {
    className,
    to,
    onClick: handleClick,
  };
  const commonProps: MenuItemPropertis = {
    className,
    onClick: handleClick,
  };

  return (
    <div
      className={tailwindClassNames(
        'hover:bg-blue-600 rounded-lg w-full h-14 whitespace-nowrap flex justify-center items-center',
        isActive ? 'bg-blue-700' : '',
        opened ? 'px-4' : ''
      )}
      title={label}
    >
      {to && <Link {...linkProps}>{content}</Link>}
      {!to && <div {...commonProps}>{content}</div>}
    </div>
  );
}

export default MenuItem;
