import { PaginationProps, ReactSelectValue } from '../../../utils/props';
import CustomSelect from '../../CustomSelect';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default function NumberOfResults({
  numberOfResultsToDisplay,
  setNumberOfResultsToDisplay,
  meta,
}: {
  numberOfResultsToDisplay: number;
  setNumberOfResultsToDisplay: (numberOfResultsToDisplay: number) => void;
  meta: PaginationProps;
}) {
  const { t } = useTranslation('ns');
  const numberOfResultsToDisplayOptions = useMemo(
    () => [
      {
        label: t('50-a-page'),
        value: 50,
      },
      {
        label: t('100-a-page'),
        value: 100,
      },
    ],
    []
  );

  const selectedOption = useMemo(() => {
    return numberOfResultsToDisplayOptions.find(({ value }) => value === numberOfResultsToDisplay);
  }, [numberOfResultsToDisplayOptions, numberOfResultsToDisplay]);

  const firstResultNumber = useMemo(() => {
    return (meta.current_page - 1) * numberOfResultsToDisplay + 1 || 0;
  }, [meta.current_page, numberOfResultsToDisplay]);

  const lastResultNumber = useMemo(() => {
    return Math.min(meta.current_page * numberOfResultsToDisplay, meta.items_no) || 0;
  }, [meta.current_page, numberOfResultsToDisplay, meta.items_no]);

  return (
    <div className="flex items-center gap-5">
      <CustomSelect
        hasBorder
        isSearchable={false}
        onSelect={(_, value) => {
          setNumberOfResultsToDisplay((value as ReactSelectValue<number>)?.value);
        }}
        options={numberOfResultsToDisplayOptions}
        value={selectedOption}
      />
      <p className="text-gray-500 text-sm">
        {firstResultNumber}-{lastResultNumber} din {meta.items_no || 0} de rezultate
      </p>
    </div>
  );
}
