import React, { useEffect } from 'react';
import Button from '../../components/Button/Button';
import {
  AppRoutes,
  ButtonSize,
  ButtonStyle,
  DefaultPageProps,
  MessageStyle } from '../../utils/props';
import useApi from '../../hooks/use-api';
import Message from '../../components/Message/Message';
import CustomImg from '../../components/CustomImg/CustomImg';
import Spinner from '../../components/Spinner/Spinner';
import { useTranslation } from 'react-i18next';
import { isLogged } from '../../utils/utils';

const ChangeEmail: React.FC<DefaultPageProps> = ({ settings }) => {
  const currentURL = window.location.href;
  const params = currentURL.split('/');

  const lastParam = params[params.length - 1];

  const { t } = useTranslation('ns');

  const changeEmailApi = useApi({
    path: `user/confirm-mail-change/${lastParam}`,
    defaultSuccessMessage: t('email-changed-successfully'),
    defaultErrorMessage: t('error-problem'),
  });

  useEffect(() => {
    changeEmailApi.put({});
  }, []);

  return (
    <div className="md:flex block">
      <CustomImg
        large={settings?.app_settings?.authentication_image?.large}
        mobile={settings?.app_settings?.authentication_image?.mobile}
        className="md:w-3/5 md:h-auto min-h-60 h-auto"
        alt="This is the main image"
      />
      <div className="flex flex-col content-center justify-center md:w-2/5 w-full p-10 lg:p-12 xl:p-16">
        <h1 className="mb-2 text-2xl font-bold text-blue-900 ">{t('confirm-email-change')} </h1>
        {changeEmailApi.loading && (
          <div className="w-full py-20 flex items-center justify-center">
            <Spinner size="normal" color="dark" />
          </div>
        )}
        {changeEmailApi.errors && (
          <Message type={MessageStyle.Error} className="mb-6">
            {changeEmailApi.errors}
          </Message>
        )}
        {changeEmailApi.successMessage && (
          <Message type={MessageStyle.Success} className="mb-6">
            {changeEmailApi.successMessage}
          </Message>
        )}
        {!changeEmailApi.loading && (
            <Button
              size={ButtonSize.LargeFull}
              variant={ButtonStyle.Primary}
              title={t('account-enter')}
              className="mb-6"
              href={AppRoutes.Login}
            >
              {!isLogged() && t('go-to-auth')}
              {isLogged() && t('go-to-platform')}
            </Button>
          )}
      </div>
    </div>
  );
};

export default ChangeEmail;
