import React from 'react';
import '../../assets/css/Spinner.css';
import { SpinnerProps } from '../../utils/props';

const Spinner = (props: SpinnerProps) => {
  const { size, color } = props;
  return (
    <div className={`lds-spinner-${size} lds-spinner-color-${color}`}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default Spinner;
