import tailwindClassNames from '../../../utils/tailwindClassNames';
import React from 'react';

export default function PaginationPageButton({
  page,
  active,
  setPage,
}: {
  page: number;
  active?: boolean;
  setPage: (page: number) => void;
}) {
  return (
    <button
      type="button"
      onClick={() => (active ? null : setPage(page))}
      className={tailwindClassNames(
        'inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700',
        active ? 'border-indigo-500 text-indigo-600' : ''
      )}
    >
      {page}
    </button>
  );
}
