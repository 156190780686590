import React from 'react';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import { ButtonStyle, ButtonSize, InputTypes, MessageStyle } from '../../utils/props';
import tailwindClassNames from '../../utils/tailwindClassNames';
import useApi from '../../hooks/use-api';
import Message from '../../components/Message/Message';
import { roundedValue } from '../../utils/utils';
import { useTranslation } from 'react-i18next';
interface PayInvoicesProps {
  payValue: number;
  invoiceIds: number[];
  setPayValue: (value: number) => void;
  toggleSelectAll: () => void;
  currentLocation?: string;
  lastUnpaidInvoice: number | null;
  totalPayment?: number;
  awaitingPayment?: number;
  payValidate: ReturnType<typeof useApi>;
}

const PayInvoices: React.FC<PayInvoicesProps> = ({
  payValue,
  invoiceIds,
  setPayValue,
  toggleSelectAll,
  lastUnpaidInvoice,
  currentLocation,
  totalPayment = 0,
  awaitingPayment,
  payValidate,
}) => {
  const payApi = useApi({
    path: `/payment/pay`,
    callback: (data) => {
      localStorage.setItem('payment_id', JSON.stringify(data.payment_id));
      window.location.href = data.form_url;
    },
  });

  const { t } = useTranslation('ns');

  const pay = () => {
    const invoiceToSend = invoiceIds.length > 0 ? invoiceIds : [lastUnpaidInvoice];
    if (currentLocation) {
      payApi.post({
        amount: payValue,
        consumer_id: currentLocation,
        invoice_ids: invoiceToSend,
      });
    }
  };

  const payAll = () => {
    toggleSelectAll();
    setPayValue(roundedValue(totalPayment));
  };

  return (
    <div className="pay">
      <>
        <div>
          <p className="font-bold my-4">{t('pay-invoice')}</p>
        </div>
        <div className="plata  border-2 rounded-lg border-blue-900 p-5">
          <div className="block lg:flex lg:justify-between items-center">
            <div className="mb-6 lg:mb-0 grow">
              <div className="font-bold flex item-center">
                {t('pay-total')}{' '}
                <span
                  className={tailwindClassNames('text-red-600 pl-1', totalPayment === 0 ? 'text-green-600 pl-1' : '')}
                >
                  {t('currency', { amount: roundedValue(totalPayment) })}
                </span>
              </div>
            </div>
            <div className="lg:flex lg:gap-4 items-center">
              <div className="flex mb-6 lg:mb-0 gap-8 w-full lg:auto overflow-hidden items-center">
                <Input
                  type={InputTypes.Text}
                  onChange={(e: any) => {
                    const numberoffDots = e.target.value.match('.') || [0];
                    let val = e.target.value;
                    if (e.target.value.endsWith('.') && numberoffDots[0] <= 2) {
                      val = `${val}0`;
                    }
                    const value = Number(val);
                    if (
                      !Number.isNaN(value) &&
                      (!e.target.value.split('.')[1]?.length || e.target.value.split('.')[1]?.length <= 2)
                    ) {
                      if (value < totalPayment) {
                        setPayValue(value);
                      } else {
                        setPayValue(totalPayment > 0 ? roundedValue(totalPayment) : 0);
                      }
                    }
                  }}
                  value={payValue.toString()}
                  wrapperClass="max-w-2/4"
                  className="w-2/4 m-0.5"
                  unit={t('currency-name')}
                  right
                />
                <div className="grow">
                  <Button
                    size={ButtonSize.LargeFull}
                    variant={ButtonStyle.Primary}
                    title={t('pay')}
                    className="my-0"
                    onClick={pay}
                  >
                    {t('make-payment')}
                  </Button>
                </div>
              </div>

              <div className=" lg:grow ">
                <Button
                  size={ButtonSize.LargeFull}
                  variant={ButtonStyle.Secondary}
                  onClick={payAll}
                  title={t('pay-all')}
                  className="my-0 whitespace-nowrap"
                >
                  {t('pay-all')}
                </Button>
              </div>
            </div>
          </div>
          {payValidate.successMessage && (
            <Message type={MessageStyle.Success} className="mt-2" onCloseFunction={payValidate.closeMessages}>
              {payValidate.successMessage}
            </Message>
          )}

          {payValidate.errors && (
            <Message type={MessageStyle.Error} className="mt-2" onCloseFunction={payValidate.closeMessages}>
              {payValidate.errors}
            </Message>
          )}

          {payApi.errors && (
            <Message type={MessageStyle.Error} className="mt-2" onCloseFunction={payApi.closeMessages}>
              {payApi.errors}
            </Message>
          )}
        </div>
      </>
      {!(totalPayment > 0 || awaitingPayment !== 0) && (
        <div>
          <p className="font-bold my-4">{t('invoice-none-unpaid')}</p>
        </div>
      )}
      <Message type={MessageStyle.Info} className="mt-3">
        {t('invoice-payment-prompt')}
      </Message>
    </div>
  );
};

export default PayInvoices;
