import React, { useState } from 'react';
import { Menu } from '@headlessui/react';
import { Float } from '@headlessui-float/react';
import { Consumer, IconsTypes, UseAPIReturnType, User } from '../../../utils/props';
import Icon from '../../../components/Icon/Icon';
import tailwindClassNames from '../../../utils/tailwindClassNames';
import Popup from '../../../components/Popup/Popup';
import { useTranslation } from 'react-i18next';

export default function RowActions({
  usersApi,
  consumer,
  resetSelected,
  deleteRecordCallback,
  setConsumerToUpdate,
}: {
  usersApi: UseAPIReturnType<User>;
  consumer: Consumer;
  resetSelected: () => void;
  deleteRecordCallback: () => void;
  setConsumerToUpdate: React.Dispatch<React.SetStateAction<Consumer | undefined>>;
}) {
  const [confirmDeactivateAccount, setConfirmDeactivateAccount] = useState(false);
  const [confirmDeleteAccount, setConfirmDeleteAccount] = useState(false);
  const [confirming, setConfirming] = useState(false);

  const deactivateAccount = async () => {
    setConfirming(true);

    // Not tested
    await usersApi.patch(consumer.user_id, {
      deleted: 1,
    });

    setConfirming(false);
    setConfirmDeactivateAccount(false);
    resetSelected();
  };

  const deleteAccount = async () => {
    setConfirming(true);

    // Not tested
    await usersApi.deleteApi(consumer.user_id);

    setConfirming(false);
    setConfirmDeleteAccount(false);
    resetSelected();
    deleteRecordCallback();
  };

  const { t } = useTranslation('ns');

  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        <Float
          portal
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Button className="flex items-center rounded-full focus:outline-none focus:ring-0">
            <span className="sr-only">Open options</span>
            <Icon icon={IconsTypes.Dots} size="16" className="h-5 w-5 text-gray-500 fill-gray-500" aria-hidden="true" />
          </Menu.Button>

          <Menu.Items className="absolute right-0 z-10 mt-2 w-36 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    type="button"
                    className={tailwindClassNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'px-4 py-2 text-sm flex gap-2 items-center w-full'
                    )}
                    onClick={() => setConsumerToUpdate(consumer)}
                  >
                    <Icon
                      icon={IconsTypes.PencilAlt}
                      size="16"
                      className="h-4 w-4 text-gray-400 fill-gray-400"
                      aria-hidden="true"
                    />
                    {t('edit')}
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    type="button"
                    className={tailwindClassNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'px-4 py-2 text-sm flex gap-2 items-center w-full'
                    )}
                    onClick={() => setConfirmDeactivateAccount(true)}
                  >
                    <Icon
                      icon={IconsTypes.MinusCircle}
                      size="16"
                      className="h-4 w-4 text-gray-400 fill-gray-400"
                      aria-hidden="true"
                    />
                    {t('deactivate')}
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    type="button"
                    className={tailwindClassNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'px-4 py-2 text-sm flex gap-2 items-center w-full'
                    )}
                    onClick={() => setConfirmDeleteAccount(true)}
                  >
                    <Icon
                      icon={IconsTypes.Trash}
                      size="16"
                      className="h-4 w-4 text-gray-400 [&_path]:fill-gray-400"
                      aria-hidden="true"
                    />
                    {t('remove')}
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Float>
      </Menu>
      {confirmDeactivateAccount && (
        <Popup
          onOk={deactivateAccount}
          onClose={() => {
            setConfirmDeactivateAccount(false);
          }}
          loading={confirming}
          cancelText={t('no')}
          okText={t('yes')}
          title={t('confirm')}
        >
          <p>{t('confirm-account-deactivation')}</p>
        </Popup>
      )}
      {confirmDeleteAccount && (
        <Popup
          onOk={deleteAccount}
          onClose={() => {
            setConfirmDeleteAccount(false);
          }}
          loading={confirming}
          cancelText={t('no')}
          okText={t('yes')}
          title={t('confirm')}
        >
          <p>{t('confirm-account-removal')}</p>
        </Popup>
      )}
    </>
  );
}
