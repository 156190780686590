import React, { useEffect, useState } from 'react';
import useApi from '../../hooks/use-api';
import SectionHeaders from '../../components/SectionHeaders/SectionHeader';
import AccountData from './AccountData';
import PasswordChange from './PasswordChange';
import DeleteAccount from './DeleteAccount';
import OnlineInvoice from './OnlineInvoice';
import { IconsTypes, MessageStyle } from '../../utils/props';
import PageTitle from '../../components/PageTitle';
import PageContainer from '../../components/PageContainer';
import Spinner from '../../components/Spinner/Spinner';
import LoadingOverlay from '../../components/Spinner/LoadingOverlay';
import { isLogged } from '../../utils/utils';
import Message from '../../components/Message/Message';
import { useTranslation } from 'react-i18next';

const Setari = () => {
  const { t } = useTranslation('ns');
  const user_id: string = localStorage.getItem('user_id') || '';
  const [isEditAccountData, setIsEditAccountData] = useState(false);
  const [isEditDeleteAccount, setIsEditDeleteAccount] = useState(false);
  const [isEditPassword, setIsEditPassword] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const settingsApi = useApi({
    path: `/user/${user_id || ''}`,
  });

  const onlineInvoiceApi = useApi({
    path: `/user/${user_id || ''}`,
    callback: () => {
      setOpenConfirmation(false);
      settingsApi.get();
    },
    defaultSuccessMessage: settingsApi?.data?.online_invoice
      ? t('invoice-online-disable-steps')
      : t('invoice-online-activated'),
  });

  const updateOnlineInvoice = (newValue: number) => {
    const dataToUpload = { ...settingsApi.data };

    dataToUpload.status = undefined;
    dataToUpload.online_invoice = newValue;

    onlineInvoiceApi.put(dataToUpload);
  };

  useEffect(() => {
    settingsApi.get();
  }, []);

  const toggleOnlineInvoice = () => {
    if (settingsApi?.data?.online_invoice) {
      setOpenConfirmation(true);
    } else {
      updateOnlineInvoice(1);
    }
  };

  return (
    <>
    { isLogged() ? (
      <PageContainer>
        <PageTitle title={t('account-settings')} description={t('account-settings-description')} />
        {!settingsApi.loading && settingsApi.data && (
          <>
            <SectionHeaders
              action={setIsEditAccountData}
              isInEdit={isEditAccountData}
              title={t('account-data')}
              buttonLabel={t('edit2')}
              updateSettings={() => {
                settingsApi.get();
              }}
            />
            <AccountData
              editMode={isEditAccountData}
              data={settingsApi.data}
              user_id={user_id}
              openEditMode={() => {
                setIsEditAccountData(true);
              }}
            />
            <SectionHeaders
              action={toggleOnlineInvoice}
              title={t('online-invoice')}
              buttonLabel={settingsApi?.data?.online_invoice ? t('deactivate2') : t('activate')}
              buttonIcon={IconsTypes.inactiveOrder}
              danger={settingsApi?.data?.online_invoice}
              success={!settingsApi?.data?.online_invoice}
              updateSettings={() => {
                settingsApi.get();
              }}
            />
            <OnlineInvoice
              data={settingsApi.data}
              user_id={user_id}
              updateSettings={() => {
                settingsApi.get();
              }}
              openConfirmation={openConfirmation}
              success={onlineInvoiceApi.successMessage}
              error={onlineInvoiceApi.errors}
              setOpenConfirmation={(val) => {
                setOpenConfirmation(val);
              }}
              updateOnlineInvoice={updateOnlineInvoice}
              closeMessage={() => {
                onlineInvoiceApi.closeMessages();
              }}
              activate={toggleOnlineInvoice}
            />
            <SectionHeaders
              action={setIsEditPassword}
              isInEdit={isEditPassword}
              title={t('password')}
              activTitle={t('password-change')}
              buttonLabel={t('modify')}
              updateSettings={() => {
                settingsApi.get();
              }}
            />
            <PasswordChange editMode={isEditPassword} />
            <SectionHeaders
              action={setIsEditDeleteAccount}
              isInEdit={isEditDeleteAccount}
              title={t('account-deletion')}
              buttonLabel={t('account-delete')}
              updateSettings={() => {
                settingsApi.get();
              }}
            />
            <DeleteAccount editMode={isEditDeleteAccount} />
          </>
        )}
        {settingsApi.loading && (
          <div className="w-full h-40 flex justify-center item-center">
            <Spinner size="normal" color="dark" />
          </div>
        )}
        {settingsApi.errors && (
          <Message type={MessageStyle.Error} onCloseFunction={settingsApi.closeMessages}>
            {settingsApi.errors}
          </Message>
        )}
      </PageContainer>) :
      <LoadingOverlay/> }
    </>
  );
};

export default Setari;
